<template>
  <ion-popover
    trigger="install-pixel"
    :alignment="isPlatform('ipad') ? 'end' : 'center'"
    :side="isPlatform('ipad') ? 'bottom' : 'top'"
    mode="ios"
    css-class="popover-ios-pwa"
  >
    <ion-item lines="none">
      <ion-label class="ion-text-wrap fs-16">
        <ul style="padding-inline-start: 16px; margin: 0">
          <li>
            Click on
            <ion-icon :icon="icons.shareOutline" class="fs-20" /> below the
            address bar <strong>(down)</strong>:
          </li>
          <li>
            In the popup, scroll down and click on
            <strong>Add to Home Screen</strong>
          </li>
          <li>Click <strong>Save</strong> and you're done!</li>
        </ul>
      </ion-label>
    </ion-item>
    <div class="content"></div>
  </ion-popover>
  <div id="install-pixel">&nbsp;</div>
</template>

<script>
import { IonPopover, isPlatform } from "@ionic/vue";
import { shareOutline } from "ionicons/icons";
import { useTracker, usePrompts } from "@/composables";
import notificationMixin from "@/mixins/notifications";

export default {
  components: { IonPopover },
  mixins: [notificationMixin],
  methods: {
    showPrompt() {
      const { Analytics, TRK_CATEGORY } = this;
      // because Android app is down, prompt for PWA on both iOS and Android
      if (isPlatform("ios")) {
        // works only on safari
        Analytics("event", {
          action: "click",
          category: TRK_CATEGORY + " - MobileWeb",
          label: "InstallCTA",
        });
        document.getElementById("install-pixel").click(); // trigger popover on iOS devices (iphone/iPad)
      } /* else if (isPlatform("android")) {
        if (this.appUrlOnStore) {
          window.open(this.appUrlOnStore, "_blank");
          Analytics("event", {
            action: "click",
            category: TRK_CATEGORY + " - MobileWeb",
            label: "DownloadAppCTA",
          });
        } else {
          const { toast } = this;
          toast(
            "Unable to open Playstore for you. Please open PlayStore yourself and search for app by name",
            "error"
          );
        }
      }*/ else {
        const platform = isPlatform("desktop") ? "Desktop" : "Android";
        Analytics("event", {
          action: "click",
          category: TRK_CATEGORY + " - " + platform,
          label: "InstallCTA",
        });
        this.promptForWebInstall().catch((err) =>
          Analytics("error", {
            label: "PwaInstallError-" + platform + "-" + err?.toString(),
            isFatal: true,
          })
        );
      }
    },
    initPwaPromptForLater() {
      const { Analytics, TRK_CATEGORY: category } = this;
      this.pwaSetInstallPrompt().catch((reason) => {
        console.log("pwa-setup failed: ", reason);
        if (reason !== "pwa_installed") {
          Analytics("event", {
            category,
            label: "rejected: " + reason.toString(),
          });
        }
      });
    },
  },
  mounted() {
    this.appUrlOnStore = null; // prevents taking users to playstore on android
    if (this.isDesktopOrWeb && !isPlatform("ios")) {
      this.initPwaPromptForLater();
    }
  },
  setup() {
    const { Analytics } = useTracker();
    const { toast } = usePrompts();
    return {
      isPlatform,
      toast,
      TRK_CATEGORY: "PWA_INSTALL",
      Analytics,
      icons: {
        shareOutline,
      },
    };
  },
};
</script>

<style lang="scss">
#install-pixel {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 1px;
  height: 1px;
  border: 1px solid transparent;
  background: transparent;
  @media (min-width: 576px) {
    bottom: unset;
    top: -50px;
    left: unset;
    right: 100px;
  }
}
.popover-ios-pwa {
  --width: 98%;
  --backdrop-opacity: 0.1;
  --background: var(--ion-color-primary);
  --offset-y: -8px;
  // iPhone 5 and SE
  @media only screen and (max-height: 568px) {
    --offset-y: -28px;
  }

  ion-item {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-light);
    @media (prefers-color-scheme: dark) {
      --color: var(--ion-color-dark-tint);
    }
  }
  &::part(arrow) {
    right: 120px !important;
    bottom: 10px !important;
    @media (max-width: 576px) {
      top: unset;
      bottom: 0;
      right: unset;
      transform: none;
    }
    @media (min-width: 576px) {
      left: unset;
    }
  }
  .popover-wrapper {
    overflow: auto;
  }
}
</style>
