<template>
  <ion-item
    v-if="canShowBanner"
    lines="none"
    color="light"
    class="pwa-install-banner"
  >
    <ion-img
      :src="
        isPlatform('ios') && !isSafari
          ? `assets/icons/apple_safari_logo_32x.png`
          : `assets/icons/icon-round.png`
      "
      slot="start"
    />
    <ion-label v-if="isSafari || isPlatform('android')" class="ion-text-wrap">
      <ion-button
        size="small"
        class="ion-no-margin"
        @click="$refs.installPopover.showPrompt()"
      >
        Get Our App
      </ion-button>
      <p style="margin-top: 8px">
        Save Moamin.in <strong>App Shortcut</strong> on your home-screen and
        unlock more features. No installation required!
      </p>
      <app-install-popover ref="installPopover" />
    </ion-label>
    <ion-label v-else class="ion-text-wrap">
      <h2 class="ion-text-strong">
        {{ $t("banners.appInstall.nonSafari.heading") }}
      </h2>
      <p
        v-html="
          $t('banners.appInstall.nonSafari.subtext', {
            url: appUrl,
          })
        "
      ></p>
    </ion-label>
    <ion-icon slot="end" :icon="icons.close" @click="dismissBanner()" />
  </ion-item>
  <slot v-else></slot>
</template>

<script>
import { isPlatform } from "@ionic/vue";
import { close } from "ionicons/icons";
import $storage from "@/utils/storage";
import AppInstallPopover from "./AppInstallPopover.vue";
import notificationMixin from "@/mixins/notifications";
import { usePrompts, useTracker } from "@/composables";

export default {
  components: { AppInstallPopover },
  mixins: [notificationMixin],
  data() {
    return {
      isBannerDismissed: $storage.$session.get("hide_aib"), // hide_appInstallBanner
    };
  },
  computed: {
    appUrl() {
      return window.location.origin;
    },
    canShowBanner() {
      const { isBannerDismissed, pwaInstalled, isPWA } = this;
      const {
        $route: { name: routeName },
      } = this;

      const isMobile = isPlatform("mobileweb");

      const hideBanner =
        isBannerDismissed ||
        pwaInstalled ||
        routeName === "onboardingPage" ||
        isPWA ||
        !isMobile;
      // console.log("hide: ", isBannerDismissed, pwaInstalled, isPWA);
      return !hideBanner;
    },
  },
  methods: {
    async dismissBanner() {
      const { Analytics, TRK_CATEGORY, isSafari } = this;
      const onConfirm = () => {
        $storage.$session.set("hide_aib", 1);
        this.isBannerDismissed = true;
        this.toast(this.$t("common.untilNextTime"));

        // tracking only for mobile
        if (!isSafari) {
          Analytics("event", {
            action: "click",
            category: TRK_CATEGORY + " - MobileWeb",
            label: "ContinueWithNonSafariCTA",
          });
          return;
        }
        Analytics("event", {
          action: "click",
          category: TRK_CATEGORY + " - MobileWeb",
          label: "SkipCTA",
        });
      };
      if (isSafari) {
        try {
          await this.confirm(
            "App shortcut doesn't need any installation and gets you more features like notification, quick page load, etc. without taking any space on your phone. Are you sure you don't want these features?",
            { heading: "You may miss out many features!" }
          );
          onConfirm();
        } catch (e) {
          console.log("result: ", e);
        }
      } else onConfirm();
    },
  },
  mounted() {
    console.log("issafari: ", this.isSafari);
  },
  setup() {
    const { toast, confirm } = usePrompts();
    const { Analytics } = useTracker();
    return {
      Analytics,
      TRK_CATEGORY: "PWA_INSTALL",
      isPlatform,
      toast,
      confirm,
      icons: {
        close,
      },
    };
  },
};
</script>

<style lang="scss">
.pwa-install-banner {
  ion-img {
    margin-inline-end: 8px;
    width: 40px;
  }
  ion-icon {
    margin-inline-start: 8px;
  }
}
</style>
