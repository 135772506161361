import { setI18nLanguage } from "@/i18n";
import { $language } from "@/apis";

export default {
  methods: {
    async loadLocaleTranslations(isOnLoad) {
      this.langErr = null;
      const { langCodeChosen: langCode } = this;
      const lcLangCode = langCode?.toLowerCase?.();
      const {
        $moamin: { languages },
      } = this;
      const { rtl } = languages.find((lang) => lang.code === lcLangCode) || {};
      return this.setLanguage(lcLangCode, rtl).catch((err) => {
        console.log("failed to load tempLocale: ", err);
        if (isOnLoad === true) {
          this.langCodeChosen = this.selectedLang;
        } else {
          this.langErr = { ...err };
        }
      });
    },
    async setLanguage(lcLangCode, isRtl) {
      this.langSet = true;
      const loader = await this.loader();

      return (
        setI18nLanguage(lcLangCode, isRtl)
          // .then((msgs) => (messages[lcLangCode] = msgs[lcLangCode]))
          // .then(() => (this.$root.$i18n.locale = lcLangCode))
          .finally(() => loader.dismiss())
      );
    },
    saveLangChoice() {
      const {
        langCodeChosen,
        $moamin: { languages },
      } = this;
      const langObj = languages.find((lang) => lang.code === langCodeChosen);
      $language.setSelectedLang(langObj, true);
      this.saved = true;
      console.log("saving lang selection");
    },
  },
};
