import { setOptions, bootstrap, screenview, event, exception } from "vue-gtag";
import { GA_KEYS } from "@/constants/ga-tracking/root";
import { isPlatform } from "@ionic/vue";

function GA() {
  return {
    enable() {
      const gaConfigKey =
        process.env.NODE_ENV === "production" ? "liveApp" : "devApp";
      const gaConfig = GA_KEYS[gaConfigKey];

      const includes = [
        {
          id: gaConfig.allTraffic,
          params: {
            send_page_view: false,
          },
        },
      ];
      if (isPlatform("pwa") && process.env.NODE_ENV === "production") {
        includes.push({
          id: gaConfig.pwaTraffic,
          params: {
            send_page_view: false,
          },
        });
      }

      setOptions({
        config: {
          id: gaConfig.webApp,
          params: {
            send_page_view: false,
          },
        },
        appName: "Moamin",
        pageTrackerScreenviewEnabled: true,
        includes,
        onReady() {
          console.log("gTag is ready now");
        },
        onAfterTrack() {
          console.log("GA triggered");
        },
      });
      return bootstrap().then((gtag) => {
        console.log("gtag enabled: ", gtag);
      });
    },
    trackScreenView(data) {
      // this.$ga.page(this.alias+'set', 'page', data.url);
      const obj = {
        ...data,
        hitType: "screenview",
        title: data.title || data.label,
        // location: data.url,
        page: data.path,
        url: data.url,
      };
      // this.$ga || this.init();
      screenview(obj, () => console.log("sent pageview"));
    },
    trackEvent: (name, values, callback) => {
      event(name, {
        event_category: values.category,
        event_label: values.label,
        event_callback: callback || event.handler,
        ...values,
      });
    },
    trackException: (description, fatal) => {
      exception({
        description,
        fatal,
      });
    },
  };
}
export { GA };
