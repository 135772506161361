import api from "@/utils/api";
import $storage from "@/utils/storage";
import { BASE_URL, FEED_APIS } from "@/constants/api";
import { getHeaders } from "@/utils/fns";
import { getUser } from "@/composables";

const LET_PASS = true;
export default {
  async createUpdatePost(data) {
    const url = BASE_URL() + FEED_APIS.list + (data.itemId || "");
    data.itemId && delete data.itemId;
    const params = { ...data };

    return api
      .post(url, { ...params }, { headers: getHeaders(!LET_PASS) })
      .then((response) => {
        const { data } = response || {};
        if (data) {
          const { success, result } = data;
          if (success) {
            return Promise.resolve(result);
          }
        }
        Promise.reject(response);
      })
      .catch((error) => Promise.reject(error));
  },
  async getFeedList(params = {}) {
    let url = BASE_URL() + FEED_APIS.list;
    if (params.userId) {
      url += parseInt(params.userId);
      delete params.userId;
    }
    // only available when loading feed-list (home)
    let additional = null;
    if (params.geo) {
      additional = { geo: params.geo };
      delete params.geo;
    }
    const LP = getUser().isGuest();
    const headers = getHeaders(LP, additional);
    return api.get(url, { params, headers }).then((response) => response.data);
  },
  async fetchItemAudience(params) {
    const { itemType, itemId } = params;
    const url = BASE_URL() + FEED_APIS.audience + itemType + "/" + itemId;

    return api
      .get(url, { headers: getHeaders(LET_PASS) })
      .then((response) => response.data);
  },
  saveTagsLocally(list) {
    //TODO: Add app-version here
    // $storage.setObject('post.tags', {version: appConfig.version, list});
    $storage.set("post.tags", { version: "2.0.1", list });
  },
  async getTags() {
    const tags = $storage.get("post.tags", null);
    return api.get(BASE_URL() + FEED_APIS.tags).then((response) => {
      const { data } = response;
      if (data) {
        const { success, result } = data;
        if (success) {
          const { list } = result;
          if (tags && tags.list) {
            list.forEach((tag) => {
              tag.counter =
                (tags.list.find((t) => t.tagId === tag.tagId) || {}).counter ||
                0;
            });
          }
          this.saveTagsLocally(list);
          return list;
        }
      }
      return data;
    });
  },
  async sendReaction(params) {
    const { itemId, itemType, userReaction } = params;
    const url = BASE_URL() + FEED_APIS.detail + itemId + "/" + userReaction;
    return api
      .post(url, { itemType }, { headers: getHeaders(!LET_PASS) })
      .then((response) => {
        const { data } = response;
        if (data) {
          const { success, result } = data;
          if (success) {
            return Promise.resolve(result);
          }
        }
        Promise.reject(data || response);
      })
      .catch((error) => Promise.reject(error));
  },
  fetchItemDetail(itemId, itemType) {
    let URL = BASE_URL() + FEED_APIS.detail + itemId + "/";
    if (itemType) {
      URL += itemType.toUpperCase();
    }
    const headers = getHeaders(LET_PASS);
    return api.get(URL, { headers }).then((response) => {
      const { data } = response || {};

      const { success, result } = data;
      if (success && result) return result;

      throw data;
    });
  },
  deleteItem(itemId) {
    let URL = BASE_URL() + FEED_APIS.deleteItem + itemId;
    const headers = getHeaders(!LET_PASS);
    return api.delete(URL, null, { headers }).then((response) => response.data);
  },
  reportItem(params) {
    const URL = BASE_URL() + FEED_APIS.reportItem;
    const headers = getHeaders(!LET_PASS);
    return api
      .post(URL, { ...params }, { headers })
      .then((response) => response.data);
  },
  comments: {
    postComment(params) {
      const data = { ...params };
      const URL = BASE_URL() + FEED_APIS.comments + (data.commentId || "");
      delete data.commentId;
      return api
        .post(
          URL,
          {
            ...data,
            itemId: data.itemId, //itemAuthorId included in data
            itemType: data.itemType,
            itemAuthorId: data.itemAuthorId,
            commentId: data.commentId,
            commentText: data.commentText,
          },
          { headers: getHeaders(!LET_PASS) }
        )
        .then((json) => {
          const { data } = json || {};
          return data || new Error(json);
        });
    },
    fetch(itemType, itemId, params) {
      let URL = BASE_URL() + FEED_APIS.comments + `${itemType}/${itemId}`;
      const headers = getHeaders(LET_PASS);
      return api.get(URL, { params, headers }).then((json) => {
        const { data } = json || {};
        if (!data) {
          return new Error(json);
        }
        const { success, result } = data;
        return !success ? new Error(data) : result;
      });
    },
    deleteCommentReply(params) {
      const { commentId, itemParentType } = params;
      const URL =
        BASE_URL() +
        FEED_APIS.comments +
        commentId +
        `/${itemParentType || ""}`;
      const headers = getHeaders(!LET_PASS);

      return api
        .delete(URL, null, { headers })
        .then((response) => response.data);
    },
  },
};
