<template>
  <div></div>
</template>

<script>
var done = false,
  loading = false;

export default {
  props: {
    videoId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
    };
  },
  methods: {
    // 4. The API will call this function when the video player is ready.
    onReady(event) {
      console.log("video ready");
      this.$nextTick(() => event.target.playVideo());
    },
    onStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING && !done) {
        // this.stopVideo();
        // done = true;
      }
    },
    loadVideoById(videoId) {
      this.player.loadVideoById(videoId);
    },
    stopVideo() {
      this.player?.stopVideo?.();
    },
    initVideo(videoId) {
      Promise.all([loading]).then(() => {
        if (this.player) this.loadVideoById(videoId);
        else {
          const { onReady, onStateChange } = this;
          const width = window.innerWidth;
          const height = Math.ceil(width / 4) * 3;
          this.player = new YT.Player(this.$el, {
            height,
            width,
            videoId,
            playerVars: {
              playsinline: 1,
            },
            events: {
              onReady,
              onStateChange,
            },
          });
        }
      });
    },
    loadSDK() {
      loading = new Promise((resolve, reject) => {
        if (document.getElementById("ytube")) return resolve();

        // 2. This code loads the IFrame Player API code asynchronously.
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        tag.id = "ytube";
        tag.onerror = (e) => reject(e);
        const [firstScriptTag] = document.getElementsByTagName("script");
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => resolve();
      });
      return loading;
    },
  },
  created() {
    this.loadSDK();
  },
};
</script>

<style lang="scss" scoped></style>
