import $storage from "@/utils/storage";
import { BASE_URL, TRACKS_APIS } from "@/constants/api";

function CustomAudio(url) {
  const audio = new Audio(url);
  audio.on = function (event, callback) {
    event = event.match(/^loaded/i) ? "loadeddata" : event;
    this.addEventListener(event, callback);
    return () => audio.off(event, callback);
  };

  audio.off = function (event, callback) {
    this.removeEventListener(event, callback);
  };

  audio.once = function (event, callback) {
    const turnOff = audio.on(event, (e) => {
      // console.warn(event + ' event monitoring off');
      callback && callback(e);
      turnOff();
    });
  };
  return audio;
}

export function AudioTrack() {
  let audio;
  return {
    load(track, options) {
      $storage.set("track.current", track);
      const { trackId } = track;
      let url = BASE_URL();
      //NOTE: Support for legacy version.
      //TODO: Remove in future and make a single unified URL
      const trackType = !track.audios ? trackId.trackType || "nauha" : "";
      if (trackType.toUpperCase() === "NAUHA") {
        url += TRACKS_APIS.url + track.trackId;
      } else {
        url += TRACKS_APIS.stream + track.trackType + "/";
        const index = track.selected || 0;
        url += track.audios[index];
      }
      //Note: For now, options is for tracking purposes only
      if (options) {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "mode=" + (options.userClicked ? "click" : "auto");
      }
      audio = new CustomAudio(url);
      audio.load();
      return audio;
    },
    seek(val) {
      if ([NaN, undefined].includes(val)) {
        console.warn("Invalid seek value. Returning: ", val);
        return;
      }
      if (audio && audio.duration) {
        const seekTo =
          typeof val === "string" && val[val.length - 1] === "%"
            ? Math.floor((audio.duration * val) / 100)
            : val;
        audio.currentTime = seekTo;
        console.log("seekTo: ", audio, seekTo);
      }
    },
    getCurrentTrack() {
      return $storage.get("track.current", {});
    },
    setRepeatValue(val) {
      $storage.set("audio.repeat", val);
    },
    getRepeatValue() {
      return $storage.get("audio.repeat", 0);
    },
    setShuffleState(state) {
      const value = (state && 1) || 0;
      $storage.set("audio.shuffle", value);
    },
    getShuffleState() {
      const value = $storage.get("audio.shuffle", 0);
      return (value && true) || false;
    },
  };
}
