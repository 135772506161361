import { GA } from "@/lib/ga";

const analytics = new GA();

export function useTracker() {
  const sendPageView = analytics.trackScreenView;

  const Analytics = (type, values) => {
    switch (type) {
      case "event":
        const event = values.action;
        delete values.action;
        analytics.trackEvent(event, values, (id) =>
          console.info("tracking '%s' event for %s:", event, id, values)
        );
        break;
      case "error":
        const desc = values.label || values.description;
        const fatal = values.fatal || values.isFatal;
        analytics.trackException(desc, fatal, () =>
          console.info("tracking error: ", values)
        );
        break;
    }
  };

  return { Analytics, enable: analytics.enable, sendPageView };
}
