<template>
  <ion-item v-if="isPuppeteer()" lines="full">
    <ion-avatar slot="start">
      <ion-img :src="logo" />
    </ion-avatar>
    <ion-label color="primary">
      {{ title }}
    </ion-label>
  </ion-item>
  <ion-header v-else class="navbar" :collapse="collapse">
    <ion-toolbar
      :color="mainToolbarColor"
      :class="{
        empty: $router.stack.length <= 1 || showCloseBtn,
        'visible-all': visibleAll,
      }"
    >
      <ion-buttons
        v-if="$router.stack.length > 1 && !showCloseBtn && backBtn !== false"
        slot="start"
      >
        <ion-back-button
          :default-href="prevPath"
          :color="altColor"
          fill="clear"
          :icon="icons.chevronBackOutline"
        />
      </ion-buttons>
      <template v-if="isSearchPage">
        <ion-searchbar
          color="light"
          v-model="searchStr"
          :placeholder="searchPh || $t('common.search')"
          inputmode="search"
          :animated="false"
          class="main-searchbar"
          @ionInput="onSearchChange"
        />
        <ion-button
          v-if="showBellIcon"
          slot="end"
          fill="clear"
          class="right-icon"
          @click="
            $router.push({
              name: 'notificationsPage',
              query: { via: $route.name },
            })
          "
        >
          <ion-icon :icon="icons.notificationsOutline" />
        </ion-button>
      </template>
      <template v-else>
        <ion-title
          v-if="title"
          v-html="title"
          :color="showsOnDesktop ? 'primary' : titleColor"
          mode="md"
        />
        <ion-buttons slot="end">
          <slot v-if="showCloseBtn" name="closeButton">
            <ion-button
              slot="end"
              fill="clear"
              :color="altColor"
              v-track-this="closeIcon ? {} : trackClose"
              @click="$emit('navbar-close')"
            >
              <ion-icon :icon="icons.close" />
            </ion-button>
          </slot>
          <slot v-else-if="$slots.endBtn" name="endBtn"></slot>
          <!-- TODO: Enable search (remove 'hidden') when search-pages are built -->
          <template v-else>
            <ion-button
              v-if="!hideSearchIcon"
              @click="gotoSearchPage"
              class="right-icon"
            >
              <ion-icon slot="icon-only" :icon="icons.searchOutline" />
            </ion-button>
            <ion-button
              v-if="showBellIcon"
              fill="clear"
              class="right-icon"
              @click="
                $router.push({
                  name: 'notificationsPage',
                  query: { via: $route.name },
                })
              "
            >
              <ion-icon :icon="icons.notificationsOutline" />
            </ion-button>
          </template>
        </ion-buttons>
      </template>
    </ion-toolbar>
    <ion-toolbar
      v-if="hasDefaultSlot()"
      :color="subHeaderColor || color"
      class="secondary-toolbar"
    >
      <slot></slot>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonSearchbar,
  isPlatform,
  IonAvatar,
} from "@ionic/vue";
import {
  searchOutline,
  close,
  chevronBackOutline,
  notificationsOutline,
} from "ionicons/icons";
import { getUser } from "@/composables";
import { isPuppeteer } from "@/utils/fns";

let searchTimer = null;
export default {
  components: {
    IonTitle,
    IonHeader,
    IonButtons,
    IonBackButton,
    IonSearchbar,
    IonAvatar,
  },
  emits: ["navbar-close", "navbar-search"],
  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: null,
    },
    searchPh: {
      type: String,
      default: null,
    },
    // color alternate to background
    altColor: {
      type: String,
      default: "light",
    },
    titleColor: {
      type: String,
      default: "light",
    },
    subHeaderColor: {
      type: String,
      default: null,
    },
    trackClose: {
      type: Object,
      default: null,
    },
    // when instead of SearchIcon, close icon is required (eg, for modal navbar)
    closeIcon: {
      type: [Boolean, String],
      default: null,
    },
    backBtn: {
      type: Boolean,
      default: true,
    },
    //to make header fade away on scroll
    collapse: {
      type: String,
      default: "fade",
    },
    visibleAll: {
      type: Boolean,
      default: false,
    },
    hideBell: {
      type: Boolean,
      default: false,
    },
    hideSearchIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchStr: "",
    };
  },
  computed: {
    logo() {
      return isPuppeteer()
        ? require("../../../public/assets/icons/icon-round.png")
        : null;
    },
    mainToolbarColor() {
      const { showsOnDesktop, color } = this;
      return showsOnDesktop ? "" : color;
    },
    showBellIcon() {
      const { hideBell } = this;
      return (
        this.$route.name !== "notificationsPage" &&
        !getUser().isGuest() &&
        !hideBell
      );
    },
    hasCloseBtnSlot() {
      return this.$slots.closeButton;
    },
    showCloseBtn() {
      return this.trackClose || this.closeIcon || this.hasCloseBtnSlot;
    },
    isSearchPage() {
      return this.$route.name === "searchPage";
    },
    prevPath() {
      const { stack } = this.$router;
      const { fullPath } = this.$route;
      const index = stack.indexOf(fullPath);
      if (index >= 1) {
        return stack.slice(0, index).pop();
      }
      const list = stack.length ? [...stack] : ["/"];
      return list.pop();
    },
    showsOnDesktop() {
      return isPlatform("desktop") && this.visibleAll;
    },
  },
  mounted() {
    this.searchStr = decodeURIComponent(this.$route.query.query || "");
  },
  methods: {
    onSearchChange() {
      const { searchStr, $emit } = this;

      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => $emit("navbar-search", searchStr), 200);
    },
    gotoSearchPage() {
      const params = {};
      const {
        $route: { path, name },
      } = this;
      if (path.includes("/media")) {
        if (name === "nauheSoazList" || name === "majalisList") {
          params.section = name === "nauheSoazList" ? "nauhe" : "majalis";
        } else if (name === "booksList") {
          params.section = "books";
        } else if (name.match(/^dua/)) {
          params.section = "duas";
        }
      } else if (path.includes("tools") && name.match(/^qna/)) {
        params.section = "questions";
      } else if (name.match(/^user/i)) {
        params.section = "people";
      }
      this.$router.push({
        name: "searchPage",
        params,
      });
    },
  },
  setup(props, { slots }) {
    const hasDefaultSlot = () => {
      const defaultSlot = slots.default;
      const [slot] = defaultSlot ? defaultSlot() : [null];
      return slot && slot.props;
    };

    return {
      isPlatform,
      hasDefaultSlot,
      isPuppeteer,
      icons: {
        searchOutline,
        close,
        chevronBackOutline,
        notificationsOutline,
      },
    };
  },
};
</script>

<style lang="scss">
.navbar {
  ion-toolbar {
    ion-buttons[slot="start"] {
      height: 48px;
      & + ion-search-bar {
        --padding-start: 0;
      }
    }
    ion-back-button {
      min-width: 28px;
      &::part(native) {
        --padding-start: 0px;
        --padding-end: 8px;
      }
    }
    // left-padding when backicon is there
    ion-buttons + ion-title {
      padding-left: 0;
    }
    &:not(.empty) ion-title {
      padding-left: 0;
    }
    &:first-child {
      ion-searchbar {
        --box-shadow: none;
        padding-bottom: 0;
        input {
          // padding-inline-start: 16px !important;// was causing bug in search-bar for searchpage
        }
      }
    }
    ion-searchbar:not(.custom) {
      --border-radius: 4px;
      input:not(:has(+ ion-icon)) {
        padding-left: 8px !important;
      }
      // skip for searchbars where color was specified
      &:not(.ion-color) {
        @media (prefers-color-scheme: dark) {
          color: var(--ion-color-light) !important;
        }
      }
    }
    &.ion-color-light {
      @media (prefers-color-scheme: dark) {
        --background: var(--ion-color-light-shade) !important;
        --ion-color-base: var(--ion-color-light-shade) !important;
      }
    }
    .right-icon {
      --color: var(--ion-color-light) !important;
      margin: 0;
      @media (prefers-color-scheme: dark) {
        --color: var(--ion-color-medium) !important;
      }
    }
    ion-title {
      @media (prefers-color-scheme: dark) {
        color: var(--ion-color-dark-shade);
      }
    }
  }
  [mode="ios"] & {
    // padding fix for ios
    ion-buttons + ion-title {
      // padding-left: 90px;// don't know why it was done in first place
    }
  }
}
@media (min-width: 768px) {
  ion-page .navbar ion-toolbar:not(.visible-all):first-child,
  .ion-page .navbar ion-toolbar:not(.visible-all):first-child {
    display: none;
  }
}
ion-modal .navbar {
  ion-toolbar:first-child,
  ion-toolbar:first-child {
    display: flex !important;
  }
}
</style>
