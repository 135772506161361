const AUTH_ACTION = Object.freeze({
  category: "LoginSignup Button",
  signupBtn: "SignupButton",
  loginBtn: "LoginButton",
});
const MOBILE_VERIFY = Object.freeze({
  category: "Mobile Verification Modal",
  logout: "Logout Button",
  ccSelector: "CountryCode Selector",
  mobileInput: "Mobile Number field",
  sendOTP: "Send OTP Button",
  resendOTP: "Resend OTP Button",
  changeNumber: "Change Number Button",
  otpField: "OTP Input",
  verifyOTP: "Verify OTP Button",
});
const AUTH = Object.freeze({
  category: "Login/Signup Modal",
  closeBtn: {
    event: "click",
    label: "Close Modal button",
  },
  togglePwd: {
    event: "click",
    label: "Toggle Password",
  },
  forgotPwd: {
    event: "click",
    label: "Forgot Password",
  },
  rememberMe: {
    event: "click",
    label: "RememberMe",
  },
  doLogin: {
    event: "click",
    label: "Login button",
  },
  doSignup: {
    event: "click",
    label: "Signup button",
  },
  openSignup: {
    event: "click",
    label: "Open Signup",
  },
  openLogin: {
    event: "click",
    label: "Open Login",
  },
  login: {
    event: "ajax",
    success: "Login Successful",
    failure: "Login failed",
  },
  fbConnect: {
    event: "click",
    label: "Connect using Facebook",
  },
  fbLogin: {
    event: "ajax",
    success: "Facebook Login Successful",
    failure: "Facebook Login failed",
    error: "Facebook Login Error",
  },
  googleConnect: {
    event: "click",
    label: "Connect using Google",
  },
  googleLogin: {
    event: "ajax",
    success: "Google Login Successful",
    failure: "Google Login failed",
    error: "Google Login Error",
  },
  signup: {
    event: "ajax",
    success: "Signup Successful",
    failure: "Signup failed",
  },
  ccSelector: {
    event: "click",
    label: "DialCode Selector",
  },
});
const USER_SETTINGS = Object.freeze({
  category: "UserSettings Modal",
  personalize: "PersonalizeSharing Button",
  changePwd: "ChangePwd Button",
  closeBtn: "CloseModal Button",
  cancelBtn: "Cancel Button",
  saveBtn: {
    event: "click",
    error: "ChangePwd API Error: ",
    label: "Save Button",
  },
  toggleBtn: {
    event: "change",
    label: "UseName Toggle",
  },
});
const DIALCODE_SELECTOR = Object.freeze({
  category: "DialCodeSelector Modal",
  closeBtn: "Close Modal Button",
  searchCountry: "Search Country input",
  countrySuggestion: "Country Suggestion",
});
const MEDIA_PLAYER = Object.freeze({
  api: {
    videoSuggestions: {
      error: "GET-videoSuggestion-API-error: ",
    },
  },
  category: {
    video: "VideoPlayer",
    audio: "AudioPlayer",
  },
  minBtn: "Minimize Modal Button",
  closeBtn: "Close Modal Button",
  closeAudioPlayerBtn: "CloseAudioPlayer Button",
  maxBtn: "Maximize Video",
  videoSuggestion: "Play Video Suggestion",
  playAudio: "Toggle Audio",
  seekAudio: "Seek Audio Track",
  repeatAudio: "Repeat Audio Button",
  prevAudio: "Load Previous Track",
  nextAudio: "Load Next Track",
  audioShuffle: "Shuffle Audio Tracks",
});
const READ_TEXT = Object.freeze({
  category: "ReadText Modal",
  closeBtn: "Close Modal Button",
  langBtn: {
    arabic: "Switch To Arabic",
    english: "Switch To Translation",
    trans: "Switch To Meaning",
  },
});
const TRACKS_LIST = Object.freeze({
  category: {
    audioNauha: "Audio Nauha",
    audioMajalis: "Audio Majalis",
    videoMajalis: "Video Majalis",
    audioDua: "Audio Dua",
    readDua: "Read Dua",
    videoDua: "Video Dua",
  },
});
const PICTURE_SRC = Object.freeze({
  category: "PictureSrc Selector",
  cta: "OpenSelector Btn",
  cameraBtn: "UseCamera Btn",
  galleryBtn: "UseGallery Btn",
  imageSelectErr: "CameraPlugin err: ",
});
const VIEW_MEDIA = Object.freeze({
  category: "ViewMedia Modal",
  reportBtn: "ReportMedia Btn",
  initMediaView: "MediaView Trigger",
  initMediaViewErr: "MediaView Error: Image path missing",
  closeMediaView: "CloseMediaView: Back/Close Btn",
  toggleZoom: "Toggle Image Zoom",
  showOptions: "ShowOptions Btn",
});
const TO_STATE = Object.freeze({
  category: "ToState",
  triggerBtn: "ToState Link",
});
const ADD_RELATIVE = Object.freeze({
  category: "AddRelative Modal",
  apis: {
    postFatihaError: "POST-postFatiha-API-error:",
    addLovedOneError: "POST-addUpdateLovedOne-API-error:",
  },
  imageUploadSuccess: "ImageUpload Success",
  imageUploadError: "ImageUpload Error",
  selectRelative: "SelectRelative",
});
const MORE_BTN = Object.freeze({
  category: "MoreOptions Pane",
  showOptions: "ShowOptions Btn",
  reportBtn: "ReportBtn",
  deleteBtn: "DeleteBtn",
  cancelBtn: "Cancelled",
  apis: {
    deleteItemError: "DELETE-deleteItem-API-error:",
    reportItemError: "POST-reportItem-API-error:",
  },
});
const FEED_ACTION = Object.freeze({
  category: "FeedActions Btn",
  reactBtn: "ReactTo: ",
  audienceBtn: "CheckAudience",
  audienceItem: "CheckAudienceDetail",
  reactApiErr: "POST-addUpdateReaction-API-Error: ",
  notifyBtn: "Notify LiveStream",
  notifyApiErr: "POST-addUpdateNotify-API-Error: ",
});
const CREATE_POST = Object.freeze({
  category: "CreatePost",
  lsModal: "LiveStreamModal",
  unAuthModal: "UnAuth Popup",
  postModal: "CreatePostModal",
  previewPane: "Preview",
  ctaBtn: "CreatePostBtn",
  streamBtn: "CreateLiveStreamBtn",

  goBackBtn: "GoBackBtn",
  showMoreOptions: "ShowMoreOptions",
  moreOptionsSections: "MoreOptionsSection:",
  changeLocBtn: "ChangeLocationButton",
  addImageFrom: "ImageSourceSelector",
  removeImage: "RemoveImage",
  dateSelect: {
    now: "DateSelect:Now",
    later: "DateSelect:Later",
  },
  selectDateBtn: {
    start: "SelectStartDate",
    end: "SelectEndDate",
  },
  selectTimeBtn: {
    start: "SelectStartTime",
    end: "SelectEndTime",
  },
  tagSelected: "TagSelected: ",
  subTagSelected: "SubTagSelected: ",

  saveButton: "savePostButton",
  removeCard: "RemoveCard: ",
  images: "Images",
  post: "Post",

  openEvent: "opened",
  closeEvent: "closed",
  onLoadEvent: "onLoad",
  uploadEvent: "uploaded",
  createdEvent: "created",

  apis: {
    getTags: "GET-getTagsForPost-API-Error: ",
    uploadImages: "POST-postImages-API-Error: ",
    createPost: "POST-createPost-API-Error: ",
  },
});
const FEED_ITEM = Object.freeze({
  category: "FeedItem",
  video: "Video",
  livestream: "Livestream",
  audience: "AudienceBtn",
  videoPopup: "VideoPopup",
  feedAddress: "FeedAddressLink",
  muteVideo: "MuteVideoBtn",
  togglePlay: "TogglePlayBtn",
  fullscreen: {
    view: "FullScreenView",
    exit: "FullScreenExit",
  },
  events: {
    finish: "finished",
    click: "click",
    closed: "closed",
    played: "Played:InView",
    paused: "Paused:OutOfView",
    autoplay: "AutoPlay",
    requestWatch: "InView:WatchRequested",
  },
});
const GO_LIVE = Object.freeze({
  category: "GoLive",
  openEvent: "opened",
  closeEvent: "closed",

  goliveBtn: "GoLiveBtn",
  statsBtn: "StatsBtn",
  acceptGuidelineBtn: "AcceptGuidelinesBtn",
  statCategoryBtn: "StatCategory:",
  switchCameraBtn: "SwitchCameraBtn",
  showAudienceBtn: "ShowAudienceBtn",
  stopStreamBtn: "StopStreaming",
  initStreamBtn: "InitStreaming",
  cancelStreamBtn: "CancelStreaming",
  saveVideoToggle: "SaveVideoToggle",
  surveyLink: "SurveyLink",

  goliveModal: "LiveStreamModal",
  statModal: "StatsModal",

  errors: {
    chartsLoad: "GET-chartsJS-loadError: ",
    audienceApi: "GET-audience-API-error: ",
  },
});
const LOCATION = Object.freeze({
  category: "Location",
  events: {
    loaded: "loaded",
    opened: "opened",
    click: "click",
    clicked: "clicked",
    closed: "closed",
    searched: "searched",
    requested: "requested",
    failed: "failed",
  },
  changeBtn: "ChangeButton",
  saveLocBtn: "SaveLocationButton",
  closeBtn: "CloseButton",
  locInput: {
    event: "change",
    label: "Location Input",
    clear: "Clear Input",
    apiErr: "GET-LocationSuggestion-API-error: ",
  },
  locSelect: "SelectSuggestion",

  modal: "ChangeLocationModal",
  autoDetect: {
    label: "AutoDetect Button",
    apiErr: "AutoDetect-API-Error: ",
    pluginErr: "AutoDetect-Plugin-Error: ",
  },
  resolveAddressApiErr: "ResolveAddress-API-Error: ",
  mapAPI: "MapsAPI",
});
const REQUEST_ACCESS = Object.freeze({
  category: "RequestAccess",
  permError: "PERMISSION_ERR",
  cameraErr: "CAMERA_ERR",
  reqPermBtn: "RequestPermButton",
  events: {
    click: "click",
    clicked: "clicked",
  },
});
export const ONBOARDING = Object.freeze({
  category: "Onboarding",
  completeProfile: {
    setupCard: {
      cta: "CompleteProfileCTA",
    },
  },
});

export {
  AUTH_ACTION,
  MOBILE_VERIFY,
  AUTH,
  USER_SETTINGS,
  DIALCODE_SELECTOR,
  MEDIA_PLAYER,
  READ_TEXT,
  TRACKS_LIST,
  PICTURE_SRC,
  VIEW_MEDIA,
  TO_STATE,
  ADD_RELATIVE,
  MORE_BTN,
  FEED_ACTION,
  CREATE_POST,
  FEED_ITEM,
  GO_LIVE,
  LOCATION,
  REQUEST_ACCESS,
};
