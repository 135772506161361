// TODO: check cordova permissions
let cordovaPlugin;
const windowPlugin =
  navigator && navigator.permissions && navigator.permissions.query
    ? navigator.permissions
    : null;

const PERMITION_MAP = {
  geolocation: {
    window: "geolocation",
    cordova: [
      "ACCESS_COARSE_LOCATION",
      "ACCESS_FINE_LOCATION",
      "ACCESS_LOCATION_EXTRA_COMMANDS",
    ],
  },
  camera: {
    window: "camera",
    cordova: "CAMERA",
  },
  audio: {
    window: "microphone",
    cordova: "RECORD_AUDIO",
  },
  contacts: {
    window: null,
    cordova: "READ_CONTACTS",
  },
  files_read: {
    window: null,
    cordova: "READ_EXTERNAL_STORAGE",
  },
};
export default {
  getPermName(forName, typeIndex) {
    typeIndex = typeIndex || 0;
    let permName = forName.toLowerCase();
    let available = PERMITION_MAP[permName] || {};
    if (!cordovaPlugin) {
      return available.window;
    }
    if (typeof available.cordova === "string") {
      available.cordova = [available.cordova];
    }
    return "android.permission." + available.cordova[typeIndex];
  },
  getAllPermName(forName) {
    let permName = forName.toLowerCase();
    let available = PERMITION_MAP[permName] || {};
    if (cordovaPlugin) {
      if (typeof available.cordova === "string") {
        available.cordvoa = [available.cordova];
      }
      return available.cordova.map((i) => "android.permission." + i);
    }
    return available.window;
  },
  check(list) {
    if (windowPlugin) {
      return windowPlugin.query({ name: list });
    } else {
      return new Promise((resolve, reject) => {
        cordovaPlugin.has(
          list,
          (hasListed) => {
            console.warn("has permissions: ", hasListed);
            hasListed[list]
              ? resolve({ state: "granted" })
              : resolve({ state: "prompt" });
          },
          reject
        );
      });
    }
  },
  async getPermissionStatus(permissions) {
    const granted = {};
    const promises = permissions.map((permKey) => {
      const permName = this.getPermName(permKey);
      if(!permName) return Promise.reject(permKey+" is not available for this device");      
      return this.check(permName).then(
        (permissionObj) => (granted[permKey] = permissionObj.state)
      );
    });

    return Promise.all(promises).then(() => granted);
  },
  request(permissions) {
    const names = [];
    const list = typeof permissions === "string" ? [permissions] : permissions;
    const keys = Object.keys(PERMITION_MAP);
    list.forEach((name) => {
      const permName = name.toLowerCase();
      if (keys.includes(permName)) {
        let permission;
        if (cordovaPlugin) {
          permission = PERMITION_MAP[permName].cordova;
          if (typeof permission !== "string") {
            permission = permission
              .map((i) => "android.permission." + i)
              .join(", ");
          } else {
            permission = "android.permission." + permission;
          }
        } else {
          permission = PERMITION_MAP[permName].window;
        }
        names.push(permission);
      }
    });

    if (windowPlugin) {
      const promises = names.map((name) => this.check(name));
      return Promise.all(promises).then(() =>
        this.getPermissionStatus(permissions)
      );
    }
    return new Promise((resolve, reject) =>
      cordovaPlugin.request(names, resolve, reject)
    );
  },
};
