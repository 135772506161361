export function useColor() {
  let randomColor;
  return {
    generate: function (strColor) {
      let len = 6;
      if (strColor) {
        strColor = strColor.replace(/[^0-9a-f]+/i, '');
        if (strColor.length > len) {
          strColor = strColor.substr(0, len);
        }
        len = 6 - strColor.length;
      }
      // const color = (Math.random()*16777215).toString(16).substr(2, 6);
      randomColor = len ? (strColor || '') + Math.random().toString(16).substr(2, len) : strColor;
      randomColor = randomColor.replace(/[^a-f0-9]+/ig, '');
      if (randomColor.length < 6) {
        randomColor = (randomColor + '123456').substr(0, 6);
      }
      randomColor = '#' + randomColor;
      // randomColor = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
      return randomColor;
    },
    //from: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    brighten: function (percent, useHex) {
      let hex = useHex || randomColor;
      if (!hex) {
        return '';
      }
      if (percent < 0) {
        if (this.colorIsDark()) {
          return hex;
        }
      }

      const rgb = parseInt(randomColor, 16);   // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff;  // extract red
      let g = (rgb >> 8) & 0xff;  // extract green
      let b = (rgb >> 0) & 0xff;  // extract blue

      const calculatedPercent = (100 + percent) / 100;

      r = Math.round(Math.min(255, Math.max(0, r * calculatedPercent)));
      g = Math.round(Math.min(255, Math.max(0, g * calculatedPercent)));
      b = Math.round(Math.min(255, Math.max(0, b * calculatedPercent)));

      let color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
      if (color.length < 7) {
        const shade = percent > 0 ? '000000' : 'FFFFFF';
        color = (color + shade).substr(0, 7);
      }
      return color.toUpperCase();
    },
    colorIsDark: function () {
      const c = randomColor.substring(1);      // strip #
      const rgb = parseInt(c, 16);   // convert rrggbb to decimal
      const r = (rgb >> 16) & 0xff;  // extract red
      const g = (rgb >> 8) & 0xff;  // extract green
      const b = (rgb >> 0) & 0xff;  // extract blue

      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      return luma > 40;// pick a different colour otherwise
    },
    //from: https://gist.github.com/renancouto/4675192
    lighter: function (percent, color) {
      const hex = color || randomColor;
      if (!hex) {
        return '';
      }
      const num = parseInt(hex.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = (num >> 8 & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;
      // return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
      return "rgba(" + (R < 255 ? R < 1 ? 0 : R : 255) + "," + (G < 255 ? G < 1 ? 0 : G : 255) + "," + (B < 255 ? B < 1 ? 0 : B : 255) + "," + (percent / 100).toFixed(2) + ")";
    }
  }
}