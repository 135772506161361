import { BASE_URL, TRACKS_APIS, BOOKS_API } from "@/constants/api";
import { getHeaders } from "@/utils/fns";
import $device from "./DeviceApis";

import api from "@/utils/api";

const LET_PASS = true;
export default {
  async fetchNauhe(params) {
    const data = { ...(params || {}) };
    const URL = BASE_URL() + TRACKS_APIS.list + (data.type || "nauhe");
    delete data.type;
    const headers = getHeaders(LET_PASS);
    //GET uses 'params', POST uses the object itself
    return api
      .get(URL, { params: data, headers })
      .then((response) => response.data);
  },
  tracks: {
    async fetchTracks(params, forType) {
      const data = { ...params };
      let URL = BASE_URL() + TRACKS_APIS.list + forType;
      if (forType === "majalis") {
        URL += "/" + (data.type || "audio");
      }
      delete data.type;
      return new Promise((resolve, reject) => {
        const headers = getHeaders(LET_PASS);
        api
          .get(URL, { params: data, headers })
          .then((response) => {
            const { data } = response;
            if (data) {
              const { success, list } = data;
              if (success) {
                // const {filters} = params;
                //if no filters, other than page & size are applied, save the list
                // const saveList = !filters || (filters && filters.page<2);
                // let list = !saveList || params.refresh ? data.list : $storage.getObject(params.type, []).concat(data.list);

                // saveList && $storage.set('majalis.'+params.type, data.list);//for later
                return resolve(list);
              }
              reject(data);
            }
            reject(response);
          })
          .catch(reject);
      });
    },
    fetchSuggestions(forCategory, { type, basedOn }) {
      const data = { basedOn };
      const URL =
        BASE_URL() + TRACKS_APIS.suggestion + forCategory + "/" + type;
      return api.post(URL, data).then((response) => response.data);
    },
    async fetchDuaCategories() {
      const URL = BASE_URL() + TRACKS_APIS.duaCategories;
      let geo = {};
      try {
        geo = await $device.getDefaultLocation();
        geo.latLng = geo.latLng || [geo.coords.lat, geo.coords.lng].join(",");
      } catch (e) {
        console.warn("Error in getting geo-info for dua-categories: ", e);
      }
      const headers = getHeaders(LET_PASS);
      return api
        .get(URL, {
          params: { latLong: geo.latLng, timezone: geo.timezone },
          headers,
        })
        .then((response) => response.data);
    },
    fetchStaticList() {
      const URL = BASE_URL() + TRACKS_APIS.list + "/dua/101";
      const headers = getHeaders(LET_PASS);
      return api.get(URL, { headers }).then((response) => response.data);
    },
  },
  books: {
    async fetchList(params) {
      const URL = BOOKS_API.basePath + BOOKS_API.list;
      const headers = getHeaders(LET_PASS);
      return api
        .get(URL, { params, headers })
        .then((response) => response && response.data);
    },
    async getBookDetails(bookId) {
      const URL = BOOKS_API.basePath + BOOKS_API.list + bookId;
      const headers = getHeaders(LET_PASS);
      return api
        .get(URL, { headers })
        .then((response) => response && response.data);
    },
  },
};
