<template>
  <ion-card class="advertise">
    <ion-card-content>
      <ion-item lines="none">
        <div class="ion-text-wrap">
          <strong v-html="$t('banners.advertise.heading')" />
          <div v-html="$t('banners.advertise.text')" />
          <ion-button :href="advertiseUrl" target="_blank" class="mt-8">
            {{ $t("banners.advertise.cta") }}
          </ion-button>
        </div>
      </ion-item>
    </ion-card-content>
  </ion-card>
</template>

<script>
export default {
  name: "advertise-banner",
  data() {
    return {
      advertiseUrl: "https://moamin.in/advertise-with-us",
    };
  },
};
</script>

<style lang="scss">
.advertise {
  ion-card-content {
    padding-inline-start: 0;
    padding-inline-end: 0;
    ion-item {
      align-items: baseline;
    }
  }
}
</style>
