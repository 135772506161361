<template>
  <ion-menu
    content-id="main-content"
    menu-id="rightMenu"
    side="end"
    :swipe-gesture="false"
    class="right-menu"
  >
    <ion-header>
      <ion-toolbar>
        <div
          v-if="!isGuest()"
          slot="end"
          style="display: flex; align-items: center"
        >
          <router-link :to="{ name: 'notificationsPage' }">
            <ion-icon
              :icon="icons.notifications"
              class="fs-24 mr-16"
              color="medium"
            />
          </router-link>
          <router-link :to="{ name: 'UserAboutPage', params: { userId } }">
            <ion-avatar style="width: 40px; height: 40px" class="mr-8">
              <ion-img :src="myProfile.avatarUrl" />
            </ion-avatar>
          </router-link>
          <more-btn :disable="['edit']" item-id="main-context-menu" />
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <!-- hide banner from RightMenu on android -->
      <app-install-desktop />

      <!-- todo: maybe add more items here -->
      <ion-card v-if="isGuest()">
        <ion-card-content>
          <p>
            An account on Moamin gives you access to interaction on post,
            creating new content, saving your progress and so much more.
          </p>
          <p>
            Start by logging into your account, or if you don't have an account
            yet, create one! It takes only a minute.
          </p>
          <div class="mt-8">
            <ion-button fill="outline" router-link="/login" class="mr-8">
              Login
            </ion-button>
            <ion-button router-link="/signup"> Sign up </ion-button>
          </div>
        </ion-card-content>
      </ion-card>

      <advertise-banner />
      <feedback-desktop />
    </ion-content>
  </ion-menu>
</template>
<script>
import { inject } from "vue";
import { IonHeader, IonMenu, IonAvatar, isPlatform } from "@ionic/vue";
import { notifications } from "ionicons/icons";
import { getUser, usePrompts } from "@/composables";
import AppInstallDesktop from "@/components/banners/AppInstallDesktop.vue";
import FeedbackDesktop from "@/components/banners/FeedbackDesktop.vue";
import AdvertiseBanner from "@/components/banners/AdvertiseBanner.vue";
import MoreBtn from "@/components/MoreBtn/MoreBtn.vue";

export default {
  name: "right-menu",
  components: {
    IonHeader,
    IonMenu,
    IonAvatar,
    AppInstallDesktop,
    FeedbackDesktop,
    AdvertiseBanner,
    MoreBtn,
  },
  computed: {
    isAndroid() {
      return isPlatform("android") && !isPlatform("mobileweb");
    },
  },
  mounted() {
    // TODO: Update menu content based on current page and data received through event
    this.$events.on("rightMenu.content", (data) => {
      console.log("right menu got data to display: ", data);
    });
  },
  setup() {
    const myProfile = inject("myProfile");
    const { currentUser, isGuest } = getUser();
    const { userId } = currentUser();
    const { toast } = usePrompts(); // used by promptsMixin
    return {
      currentUser,
      isGuest,
      myProfile,
      userId,
      toast,
      icons: { notifications },
    };
  },
};
</script>
<style lang="scss">
.right-menu {
  --border: none;
  --width: 120px;
  ion-content {
    padding: 16px;
    --background: transparent;

    .guest-view {
      .cards {
        .x-scroll {
          display: block;
          white-space: normal;
          ion-card {
            max-width: 100%;
            display: block;
          }
        }
      }
    }
  }
}
</style>
