import { isPlatform } from "@ionic/vue";
import { AppLauncher } from "@capacitor/app-launcher";
import { usePrompts, useTracker } from "@/composables";
import { useNotifications } from "@/lib/notifications";
import $storage from "@/utils/storage";

// push fns to be registered in parent component (since mixin cannot use 'setup')
export default {
  data() {
    return {
      notificationAccess: null,
      pwaInstallPrompt: null,
      androidInstalled: false,
      pwaPrompted: false,
      pwaInstalled: false,
    };
  },
  computed: {
    isSafari() {
      const { userAgent } = navigator;
      return userAgent.includes("Safari")
        ? !(userAgent.includes("CriOS") || userAgent.includes("Chrome")) // chrome UA includes these while being similar to safari
        : false;
    },
    isAndroid() {
      return isPlatform("android") && !isPlatform("mobileweb");
    },
    isPWA() {
      return isPlatform("pwa") || navigator?.standalone;
    },
    isDesktopOrWeb() {
      return isPlatform("desktop") || isPlatform("mobileweb");
    },
  },
  methods: {
    onPushAccessGranted(handlers = {}) {
      const { addListeners } = useNotifications();
      console.log("push available");
      return addListeners({
        onError: handlers.onError || ((error) => console.warn(error)),
        onPushReceived: handlers.onPush || ((push) => console.info(push)),
      }).catch((err) => {
        const { code, error } = err;
        if (code) {
        }
      });
    },
    promptForNotificationAccess() {
      console.log("Requesting notification access");

      return new Promise(async (resolve, reject) => {
        const { isAccessGranted, requestAccess } = useNotifications();

        const { isSafari, isPWA } = this;
        if (isSafari && !isPWA) {
          console.log("Safari and not PWA. Returning");
          return reject("not_supported");
        }

        const isGrantedAlready = await isAccessGranted();
        console.log("is access granted already? ", isGrantedAlready);

        if (isGrantedAlready) {
          console.log("access granted already. Returning");
          return this.onPushAccessGranted().finally(() =>
            resolve(isGrantedAlready)
          );
        }

        const promptSkipped = $storage.$session.get("prompt_skipped");
        if (promptSkipped) {
          return reject("prompt_skipped");
        }

        if (!isGrantedAlready) {
          const { $t } = this;
          const { confirm, toast } = usePrompts();
          confirm($t("common.pushPrompt.desc"), {
            header: $t("common.pushPrompt.heading"),
            buttons: {
              cancel: $t("common.pushPrompt.cancelCta"),
              confirm: $t("common.pushPrompt.okCta"),
            },
          })
            .then(() => {
              console.log("user accepted to ask for notification permission");
              requestAccess(true)
                .then(async (access) => {
                  console.log("notification access %s", access);
                  if (access === "granted") {
                    return this.onPushAccessGranted().finally(() =>
                      resolve(access)
                    );
                  }
                  reject(access);
                })
                .catch((error) => {
                  if (error === "not_supported") {
                    toast($t("common.pushPrompt.jsPushUnsupported"));
                  }
                  console.warn("Notification Access error: ", error);
                  reject(error);
                });
            })
            .catch(() => {
              console.warn("Did not go ahead with permission prompt");
              $storage.$session.set("prompt_skipped", 1);
              reject("prompt_skipped");
            });
        }
      });
    },
    // TODO: test on mobile-web
    pwaSetInstallPrompt() {
      return new Promise((resolve, reject) => {
        if (isPlatform("pwa")) {
          reject("pwa_installed");
        } else {
          // https://levelup.gitconnected.com/vue-pwa-example-298a8ea953c9
          window.addEventListener("beforeinstallprompt", (ev) => {
            ev.preventDefault(); // stop automatic prompt
            if (!this.pwaInstallPrompt) {
              this.pwaInstallPrompt = ev;
              resolve();
            }
          });
          window.addEventListener("appinstalled", function () {
            this.pwaInstalled = true;
            reject("pwa_installed");
          });
        }
      });
    },
    promptForWebInstall() {
      const { Analytics } = useTracker();
      const category = "PWA Install";

      return new Promise((resolve, reject) => {
        if (this.pwaInstallPrompt) {
          const deniedTimes = $storage.get("pwa.install.denied") || 0;
          this.pwaInstallPrompt.prompt();
          // wait for user to respond to choice
          this.pwaInstallPrompt.userChoice.then(({ outcome }) => {
            if (outcome === "accepted") {
              resolve("accepted");
              Analytics("event", {
                category,
                label: "installed_after",
                value: deniedTimes,
              });
            } else {
              reject("denied");
              console.log("denied pwa install prompt: ", deniedTimes);
              Analytics("event", {
                category,
                label: "install_cancelled",
                value: deniedTimes + 1,
              });
            }
            //timeout ensure that
            setTimeout(() => (this.pwaInstallPrompt = null), 1000);
          });
          return;
        }
        reject("prompt_unavailable");
      });
    },
    async notificationPermissionStatus() {
      const { permissionStatus } = useNotifications();
      try {
        const result = await permissionStatus();
        this.notificationAccess =
          result === "granted" || result.receive === "granted"
            ? "granted"
            : result;
        console.log("has push/notification?: ", this.notificationAccess);
      } catch (e) {
        console.error("Push permission error: ", e);
        throw new Error(e);
      }
    },
  },
  mounted() {
    if (isPlatform("ios")) {
      this.pwaInstalled = window?.navigator?.standalone;
    }
  },
};
