<template>
  <ion-page>
    <ion-tabs ref="tabs">
      <ion-router-outlet :key="$route.fullPath" />
      <ion-tab-bar v-if="!isPuppeteer()" :slot="tabsPosition">
        <ion-tab-button
          tab="homeTab"
          :href="$router.resolve({ name: 'homeTab' }).href"
          @click.prevent.stop="$router.push({ name: 'homeTab' })"
        >
          <ion-icon :icon="homeOutline" />
          <ion-label v-html="$t('tabs.home.name')" />
        </ion-tab-button>

        <ion-tab-button
          tab="mediaTab"
          :href="$router.resolve({ name: 'mediaTab' }).href"
          @click.prevent.stop="$router.push({ name: 'mediaTab' })"
        >
          <ion-icon :icon="filmOutline" />
          <ion-label v-html="$t('tabs.media.name')" />
        </ion-tab-button>

        <ion-tab-button
          tab="toolsTab"
          :href="$router.resolve({ name: 'toolsTab' }).href"
          @click.prevent.stop="$router.push({ name: 'toolsTab' })"
        >
          <ion-icon :icon="briefcaseOutline" />
          <ion-label v-html="$t('tabs.tools.name')" />
        </ion-tab-button>

        <ion-tab-button
          tab="userTab"
          :href="userTabUrl()"
          @click.prevent.stop="$router.push(userTabUrl())"
        >
          <ion-icon :icon="personCircleOutline" />
          <ion-label v-html="$t('tabs.user.name')" />
        </ion-tab-button>
      </ion-tab-bar>
      <app-install-mobile v-if="!isPuppeteer()">
        <feedback-mobile />
      </app-install-mobile>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonRouterOutlet } from "@ionic/vue";
import {
  homeOutline,
  filmOutline,
  briefcaseOutline,
  personCircleOutline,
} from "ionicons/icons";
import { getUser } from "@/composables";
import { isPuppeteer } from "@/utils/fns";
import AppInstallMobile from "@/components/banners/AppInstallMobile.vue";
import FeedbackMobile from "@/components/banners/FeedbackMobile.vue";

export default {
  name: "TabsSetup",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
    AppInstallMobile,
    FeedbackMobile,
  },
  data() {
    return {
      tabsPosition: "bottom",
    };
  },
  methods: {
    userTabUrl() {
      const { userId, username } = this.currentUser();
      const params = {};
      if (username) params.username = username;
      params.userId = userId || "guest";
      const url = this.$router.resolve({
        name: "userTab",
        params,
      }).href;
      return url;
    },
    isActiveTab(name) {
      const { path } = this.$route;
      // const regex = new RegExp(name, "gi");
      // return Boolean(path.match(regex));
      return path.includes(name) || name.includes(path);
    },
  },
  mounted() {
    window.onresize = () => {
      this.tabsPosition = window.innerWidth > 768 ? "top" : "bottom";
    };
    window.onresize();
  },
  setup() {
    const { currentUser, isGuest } = getUser();
    return {
      currentUser,
      isGuest,
      isPuppeteer,

      homeOutline,
      filmOutline,
      briefcaseOutline,
      personCircleOutline,
    };
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 576px) {
  // for banners appearing on iOS mobile, below tabs
  .plt-pwa[mode="ios"] ion-tab-bar + ion-item {
    --padding-bottom: 20px;
    margin-top: -24px;
    z-index: 10;
  }
}
@media screen and (min-width: 576px) {
  ion-tabs {
    background: var(--ion-color-light);
    ion-tab-bar {
      border: none;
      @include boxShadow(0, 2px, 4px, 2px, #aaa);
      @media (prefers-color-scheme: dark) {
        @include boxShadow(0, 2px, 4px, 2px, #1a1a1a);
      }
    }
    .tabs-inner {
      ion-router-outlet ion-content {
        --padding-top: 16px;
        --padding-bottom: 16px;
        --padding-start: 16px;
        --padding-end: 16px;
      }
    }
  }
}
</style>
