import $storage from "@/utils/storage";

export default {
  methods: {
    accountCompleteStat() {
      const { myProfile: profile } = this;
      let percent = 0;
      const { emailVerified, mobileVerified, dateOfBirth, genderLabel, user_religion, user_sect, is_shia, cityName, countryName, } = profile;
      const loginInfo = emailVerified && mobileVerified;
      percent += loginInfo ? 25 : 0;
      const basicInfo = dateOfBirth && genderLabel !== 'unknown';
      percent += basicInfo ? 25 : 0;
      const locInfo = cityName && countryName;
      percent += locInfo ? 25 : 0;
      const identityInfo = user_religion && (user_religion !== 'muslim' ? true : (user_sect !== 'shia' ? true : is_shia))
      percent += identityInfo ? 25 : 0;
      const dontRemind = $storage.get('dont_remind_account_setup', 0);
      return { loginInfo, basicInfo, locInfo, identityInfo, percent, dontRemind };
    },
    takeToPage(redirectUrl, preferPrevious = true) {
      const { $router } = this;
      $router.stack = [];
      const prev_url = $storage.$session.get('pp_url');// saved in auth-prompt
      // uses prev redirectUrl, if prevUrl is preferred (despite having redirectUrl)
      if (prev_url ? preferPrevious : !redirectUrl) {
        $storage.$session.remove('pp_url');
        redirectUrl = prev_url;
        if ($storage.$session.get('pp_data')) {
          redirectUrl += prev_url.includes('?') ? '' : '?';
          redirectUrl += '_session=1';
        }
      }
      this.$nextTick(() => {
        $router.replace(redirectUrl || { name: 'homeTab' });
      });
    },
  },
};