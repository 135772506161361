<template>
  <div v-if="canShowAds" :class="{ 'ad-block': true, [placement]: true }">
    <InFeedAdsense
      v-if="placement === 'list'"
      data-ad-layout-key="-fb+5w+4e-db+86"
      data-ad-client="ca-pub-9183635444489907"
      data-ad-slot="1371632741"
    />
    <InArticleAdsense
      v-else-if="placement === 'detail' || placement === 'article'"
      data-ad-format="fluid"
      data-ad-client="ca-pub-9183635444489907"
      data-ad-slot="6666250937"
    />
    <Adsense
      v-else
      data-ad-format="fluid"
      data-ad-layout-key="-fb+5w+4e-db+86"
      data-ad-client="ca-pub-9183635444489907"
      data-ad-slot="6212169531"
    />
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: "list",
    },
  },
  computed: {
    canShowAds() {
      return (
        process.env.SHOW_ADS ||
        process.env.NODE_ENV === "development" ||
        this.$route.query.show_ads
      );
    },
  },
};
</script>

<style lang="scss">
.ad-block {
  position: relative;
  padding-top: 16px;
  --background: var(--ion-color-light-shade);
  background: var(--ion-color-light-shade);
  &:before {
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    content: "Advertisement";
    padding: 2px 4px;
  }
  &.list {
    margin: 24px 16px;
  }
}
</style>
