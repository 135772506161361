const APP_SERVER_URL = "https://app.moamin.in";
import { $user } from "@/apis";

export default {
  routeResolver: null,
  myProfile: null, // set via components that uses this service
  getSharingSubject(itemType, item) {
    let subject = "";
    const type = (itemType || "").split(":")[0];
    switch (type.toLowerCase()) {
      case "fatiha":
        subject =
          "Please recite a surah-fatiha for " + (item.relName || item.feedData);
        break;
      case "nauhe":
      case "nauha":
        const nauha = item;
        subject =
          nauha.trackTitle +
          " - Nauha by " +
          (nauha.nkName || nauha.nauhakhwan);
        break;
      case "dua":
        const dua = item;
        const hasText = dua.texts && dua.texts.length;
        const hasVideo = dua.videos && dua.videos.length;
        let sub = " - Listen";
        sub += hasText ? (hasVideo && ", Read") || " and Read" : "";
        sub += hasVideo ? " and Watch" : "";
        sub += " this Dua";

        subject = (dua.duaTitle || dua.label) + sub;
        break;
      case "majalis":
      case "majlis":
        const majlis = item;
        if (item.type === "video") {
          subject = majlis.title + " - Watch this majalis";
        } else {
          subject =
            majlis.majlisTitle +
            " - Listen " +
            (majlis.zakirName
              ? ` majalis by ${majlis.zakirName}`
              : "this majalis");
        }
        break;
      //books-list
      case "books":
        //item is custom-object with info about list
        if (item.count) {
          subject = item.count;
        }
        subject += subject === "" ? "Books " : " books ";
        if (item.text) {
          subject += `matching "${item.text}"`;
        } else if (item.authors) {
          subject += `by "${item.authors}"`;
        } else if (item.lang) {
          subject += `in ${item.lang}`;
        }
        subject += " available";
    }
    return (subject && "*" + subject + "* on *Moamin App*") || "";
  },
  getSharingDesc(itemType, item) {
    const PREF = $user.getSharingPreferences();
    let desc, pre;
    const type = (itemType || "").split(":")[0];
    const { myProfile } = this;
    let userName = `*${
      myProfile?.displayFullName || myProfile?.fullName || ""
    }*`;
    const genderLabels = ["her", "his"]; // index is important here
    const gender = genderLabels[item.userGender] || "";
    let skipSuffix = false;
    switch (type.toLowerCase()) {
      case "fatiha":
        userName = item.userDisplayName || item.userName;
        desc = PREF.useName ? userName + " has requested for" : null;
        if (!desc) {
          desc = "Kindly recite ";
        }
        if (type[1] && type[1].toLowerCase() === "namaz") {
          desc += " 2 raka't *Namaz-e-Wahshat* and";
        }
        desc +=
          " *Surah-fatiha* for *Late" + (item.relName || item.feedData) + "*";
        break;
      case "post":
        userName = item.userDisplayName || item.userName;
        desc = item.feedText ? item.feedText + "\n\n" : "";
        desc += `${userName}'s post on *Moamin*. Read more about this and others`;
        break;
      case "livestream":
        let what = "*livestream*";
        userName = item.userDisplayName || item.userName;
        const stream = item.stream;
        if (item.feedTagLabel || item.feedParentTagLabel) {
          what = [];
          what.push(item.feedParentTagLabel ? item.feedParentTagLabel : "");
          what.push(item.feedTagLabel ? item.feedTagLabel : "");
          what = what.join(" - ");
          what = "*livestream* session for *" + what + "*";
        }
        desc = item.isLive
          ? `Join ${userName} in ${gender}${what} now`
          : stream.isFinished
          ? `${userName} hosted a ${what} session. Click the link to watch it now.`
          : `${userName} will host a ${what}. Click the link to get notified when it starts.`;
        desc += "\n";
        desc += (item.feedText ? "\n" + item.feedText : "") + "\n\nWatch it";
        break;
      case "nauhe":
      case "nauha":
        const nauha = item;
        desc = PREF.useName
          ? userName + " recommends listening to this Nauha "
          : "Listen to this Nauha ";
        desc +=
          nauha.nkName || nauha.nauhakhwan
            ? "by " + (nauha.nkName || nauha.nauhakhwan) + " "
            : "";
        break;
      case "dua":
        const dua = item;

        const hasText = dua.texts && dua.texts.length;
        const hasVideo = dua.videos && dua.videos.length;
        let sub = " - Listen";
        sub += hasText ? (hasVideo && ", Read") || " and Read" : "";
        sub += hasVideo ? " and Watch" : "";
        sub += " this Dua";

        pre = PREF.useName
          ? userName + " recommends this dua: "
          : "Listen to this dua ";
        desc =
          pre +
          (dua.desc || dua.longDesc || " ").replace(/<\/?p>/g, "*").trim();
        break;
      case "majalis":
      case "majlis":
        const majlis = item;

        if (item.type === "video") {
          pre = PREF.useName
            ? userName + " recommends watching this "
            : "Watch this";
          desc =
            pre +
            " majalis posted by " +
            (majlis.author.channelName || majlis.author.name);
        } else {
          pre = PREF.useName
            ? userName + " recommends listening this "
            : "Listen this ";
          desc =
            pre +
            " this majalis" +
            (item.zakirName
              ? " by " +
                item.zakirName +
                (item.zakirAlias ? ` (${item.zakirAlias})` : "")
              : "");
        }
        break;
      case "user":
        desc = (item.userDisplayName || item.userName) + " updated " + gender;
        desc += itemType === "USER:AVATAR" ? "profile picture" : "cover image";
        skipSuffix = true;

        desc +=
          "\n*Download Moamin App* today and start sharing your updates on Moamin!\n\n";
        break;
      //books list
      case "books":
        //item is custom-object with info about list
        desc = "Find books ";
        if (item.text) {
          desc += `matching *"${item.text}"*`;
        } else if (item.authors) {
          desc += `'by *"${item.authors}"*'`;
        } else if (item.lang) {
          desc += `in *"${item.lang}"* language`;
        }
        desc +=
          " on Moamin app. Some other similar books available on app are:\n\n";
        desc += item.list
          .slice(0, 5)
          .map((book, index) => {
            let url = $state.href(
              APP_ROUTES.mediaTab.children.bookDetail.name,
              { bookId: book.bookId, title: book.bookTitle.toSeoLabel() }
            );
            url = url.match(/^https?:/i)
              ? url
              : "https://" + APP_SERVER_URL + url;
            url = url.replace("#", "");
            return `*${index + 1 + ". " + book.bookTitle}*: ${url}`;
          })
          .join("\n");
        desc += "\n\nMore books like these available for *free*";
        break;
      case "book":
        desc = item.shareConfig.sharingDesc + "\n\n";
        skipSuffix = true;
    }
    if (!skipSuffix) {
      desc += [".", ":"].includes(desc[desc.length - 1])
        ? "\nOnly on "
        : " only on ";
    }
    desc +=
      "*Moamin* - Social Network App for Shia muslims around the world! Download today:\n";
    // desc += ($rootScope.debug && $rootScope.debug.app && $rootScope.debug.app.downloadLink || "https://ply.gl/com.giriyazaari.app?utm_source=direct&utm_medium=sharing") + "\n";
    desc +=
      "https://ply.gl/com.giriyazaari.app?utm_source=direct&utm_medium=sharing" +
      "\n";

    if (PREF.signature) {
      desc += "\n\n" + PREF.signature;
    }
    desc = this.getSharingUrl(itemType, item) + "\n\n" + desc;
    return this.getSharingSubject(itemType, item) + "\n" + desc;
  },
  getSharingUrl(itemType, item) {
    let url = "",
      params;
    const type = (itemType || "").split(":")[0].toLowerCase();
    switch (type) {
      case "user": //status, avatar, or bgImage update
      case "fatiha": //fatiha or fatiha:namaz
      case "post":
      case "livestream":
        params = {
          itemId: item.feedId,
        };
        if (["user", "fatiha"].includes(type)) {
          params.itemType = "post";
          params.postType = itemType.toLowerCase().split(":").join("+");
        } else {
          params.itemType = type;
        }
        url = this.routeResolver({ name: "postDetail", params }).href;
        break;
      case "nauhe":
      case "nauha":
        params = {
          type: type.toLowerCase(),
          tags: (item.tags || item.tagNames || []).join("-"),
        };
        if (item.trackId) {
          params.id = item.trackId;
        }
        url = this.routeResolver({ name: "nauheSoazList", params }).href;
        break;
      case "dua":
        url = this.routeResolver({ name: "duaList" }).href;
        break;
      case "majalis":
      case "majlis":
        url = this.routeResolver({ name: "majalisList" }).href;
        break;
      //share books-list
      case "books":
        //item is params-object with info about list-page
        // url += $state.href(APP_ROUTES.mediaTab.children.bookList.name, item);
        url = this.routeResolver({ name: "booksList", params: { ...item } })
          .href;
        break;
      //share book
      case "book":
        // url += $state.href(APP_ROUTES.mediaTab.children.bookDetail.name, { bookId: item.bookId, title: item.bookTitle.toSeoLabel() });
        url = this.routeResolver({
          name: "booksList",
          params: {
            bookId: item.bookId,
            title: item.bookTitle,
          },
        }).href;
        break;
    }
    return window.location.origin + url;
  },
  getSharingImg(itemType, item) {
    if (!itemType || !item) return null;
    let image;
    const type = itemType.split(":").shift().toLowerCase();
    switch (type) {
      case "post":
      case "livestream":
        image = (item && item.feedImages && item.feedImages[0]) || null;
        break;
      case "user":
        break;
      case "fatiha":
        image =
          item.postDataUrl ||
          (item.feedImages && item.feedImages[0]) ||
          item.userAvatarUrl;
        break;
      case "nauhe":
      case "nauha":
        image =
          item.albumThumbRemote ||
          item.albumThumbUrl ||
          item.thumbnail_xs ||
          null;
        break;
      case "dua":
      case "majalis":
      case "majlis":
    }
    return image;
  },
};
