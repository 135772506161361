const MOBILE_VERIFICATION = Object.freeze({
  category: "Mobile Verification Modal",
  logout: {
    event: "click",
    label: "Logout Button",
  },
  ccSelector: {
    event: "click",
    label: "CountryCode Selector",
  },
  mobileInput: {
    event: "change",
    label: "Mobile Number field",
  },
  sendOTP: {
    event: "click",
    label: "Send OTP Button",
  },
  resendOTP: {
    event: "click",
    label: "Resend OTP Button",
  },
  changeNumber: {
    event: "click",
    label: "Change Number Button",
  },
  otpField: {
    event: "change",
    label: "OTP Input",
  },
  verifyOTP: {
    event: "click",
    label: "Verify OTP Button",
  },
});
const LANG_SELECTOR = Object.freeze({
  category: "LANGUAGE",
  popup: {
    event: "appear",
    label: "SelectorPopup",
  },
  popupOptions: {
    event: "click",
    label: "SelectorPopup-Btn:",
  },
});
const GA_KEYS = Object.freeze({
  devApp: {
    webApp: "G-YZNHTLSCDZ",
    allTraffic: "G-QM5DCDLKYG",
  },
  liveApp: {
    webApp: "G-S86MXY8PFS",
    pwaTraffic: "G-XFYQ44K5WC",
    allTraffic: "G-K6Q68GGHDD",
  },
});
export { MOBILE_VERIFICATION, LANG_SELECTOR, GA_KEYS };
