<template>
  <ion-modal
    :is-open="isOpenRef"
    v-bind="$attrs"
    css-class="lang-selector"
    @willDismiss="beforeCancel()"
  >
    <ion-page>
      <slot name="header" />
      <ion-content>
        <slot name="intro" />
        <ion-radio-group v-model="langCodeChosen" @ionChange="loadLocaleTranslations()">
          <template v-if="countryLangList.length">
            <ion-item v-for="(lang, index) in countryLangList" :key="lang.code" lines="full">
              <ion-radio
                :value="lang.code"
                label-placement="start"
                justify="space-between"
                mode="md"
              >
                <ion-text>
                  <component :is="langCodeChosen === lang.code ? 'strong' : 'p'">
                    {{
                    $t(
                    "comps.langPrompt.choices.useLang." +
                    (index === 0 ? 0 : 1),
                    { langName: lang.label }
                    )
                    }}
                  </component>
                </ion-text>
                <ion-text
                  v-if="langErrorUrl?.includes(lang.code + '.json')"
                  color="warning"
                  class="ion-text-wrap"
                >
                  <p>
                    {{
                    $t("comps.langPrompt.langError", {
                    langName: lang.label,
                    })
                    }}
                  </p>
                </ion-text>
              </ion-radio>
            </ion-item>
          </template>
          <ion-item lines="full">
            <ion-radio value="en" justify="space-between" label-placement="start" mode="md">
              <ion-text>
                <component :is="langCodeChosen === 'en' ? 'strong' : 'p'">No, continue in English</component>
              </ion-text>
            </ion-radio>
          </ion-item>
          <template v-if="otherLangs?.length">
            <ion-item-divider class="ion-padding" color="light">
              <ion-label class="ion-text-wrap" style="margin: 0">
                <p>{{ $t("comps.langPrompt.choices.selectFrom") }}</p>
              </ion-label>
            </ion-item-divider>
            <ion-item v-for="lang in otherLangs" :key="lang.code" lines="full">
              <ion-radio
                :value="lang.code"
                justify="space-between"
                label-placement="start"
                mode="md"
              >
                <ion-text>
                  <component
                    :is="langCodeChosen === lang.code ? 'strong' : 'p'"
                    v-html="lang.label"
                  />
                </ion-text>
                <ion-text
                  v-if="langErrorUrl?.includes(lang.code + '.json')"
                  color="warning"
                  class="ion-text-wrap"
                >
                  <p>
                    {{
                    $t("comps.langPrompt.langError", {
                    langName: lang.label,
                    })
                    }}
                  </p>
                </ion-text>
              </ion-radio>
            </ion-item>
          </template>
        </ion-radio-group>
      </ion-content>
      <slot name="footer" />
    </ion-page>
  </ion-modal>
</template>

<script lang="js">
import { ref } from "vue";
import { IonModal, IonRadioGroup, IonRadio, IonItemDivider,IonText, } from "@ionic/vue";
import { usePrompts } from "@/composables";
import langaugeMixin from "@/mixins/language"

export default {
  components: { IonModal, IonRadioGroup, IonRadio, IonItemDivider,IonText, },
  mixins: [langaugeMixin],
  props: {
    // 'breakpoints' and 'initial-breakpoints' can still be passed and be assigned to child component via $attrs
    selectedLang: {
      type: String,
      default: null
    },
    countryCode: {
      type: String,
      default: null
    },
    countryLang: {
      type: String,
      default: ''
    },
    searchLang: {
      type: String,
      default: ''
    }
  },
  emits: ['ready', 'dismiss'],
  data() {
    return {
      langCodeChosen: null,
      langSet: false,// tells if lang was already set when component mounted
      langErr: null,
    }
  },
  computed: {
    langErrorUrl() {
      return this.langErr?.error?.config?.url;
    },
    countryLangList() {
      const {
        $moamin: { languages },
        countryCode,
        countryLang
      } = this;
      if (!countryCode) return [];
      return languages
        .filter((lang) => {
          return (
            !lang.disabled &&
            lang.inCountryCodes.includes(countryCode)
          );
        })
        .map((lang) => {
          const { code, label, spokenInCountries, inCountryCodes } =
            lang;
          return {
            code,
            label,
            spokenInCountries,
            inCountryCodes,
            countryFlag:
              code.toUpperCase() === countryLang ? countryFlag : null,
          };
        });
    },
    otherLangs() {
      const {
        $moamin: { languages },
        countryLangList,
        searchLang
      } = this;
      const spokenLangList = countryLangList
        .map((lang) => lang.code) // lang spoken in region (already shown at the top)
        .concat(['en']);// english (already shown at the top in the list)
      const newList = languages.filter((lang) => !spokenLangList.includes(lang.code));
      if(!searchLang) {
        return newList;
      }
      const lcSearchText = searchLang.toLowerCase();
      return newList.filter(({code, label}) => code.toLowerCase() === lcSearchText || label.toLowerCase().includes(lcSearchText))
    },
  },
  methods: {
    beforeCancel() {
      // console.log('will dismiss called: ', this.selectedLang, this.langCodeChosen);
      this.$emit('dismiss', this.saved)// informs if changes were saved
      // since lang was change, that was already handled by 'saveSelection'
      if(this.selectedLang !== this.langCodeChosen) {
        return this.closeModal(true);
      }
      const {
        $moamin: { languages },
        selectedLang
      } = this;
      const {rtl} = (languages || []).find(lang => lang.code === selectedLang) || {};
      this.setLanguage(this.selectedLang, rtl)
        .finally(() => this.closeModal(true))
    },
    saveSelection() {
      this.saveLangChoice();//from mixin
      this.closeModal();
    }
  },
  mounted() {
    this.langCodeChosen = this.selectedLang;

    // No need for getting languages if lang is already set
    const timer = setInterval(() => {
      const {
        $moamin: { languages, error, features },
      } = this;
      const isTranslationDisabled = features && features.translation && features.translation.disabled;
      if(isTranslationDisabled) {
        clearInterval(timer);
        return;
      }
      if (languages || error) {
        clearInterval(timer);
        if(error) {
          this.langErr = error;
        }
        else if (languages) {
          if (!this.langSet) {
            this.$emit('ready', languages);
          }
          if (this.langCodeChosen && this.isOpenRef) {
            this.loadLocaleTranslations(true);
          }
        }
      }
    }, 100);
  },
  setup() {
    const isOpenRef = ref(false);
    const setModalState = (state) => (isOpenRef.value = Boolean(state));
    const openModal = () => setModalState(true);
    const closeModal = () => setModalState(false);
    const { loader } = usePrompts();

    return { isOpenRef, openModal, closeModal, loader };
  }
}
</script>
