import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";
import { Capacitor } from "@capacitor/core";
import { PUSH_CONFIG } from "@/constants/push-config";
import { $device } from "@/apis";
import { useOneSignal } from "@onesignal/onesignal-vue3";// for web
import { urlB64ToUint8Array } from "@/utils/fns";

import OneSignal from "onesignal-cordova-plugin";// for real devices
import $storage from "@/utils/storage";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const isPushAvailable = Capacitor.isPluginAvailable("PushNotifications");

// uses PushNotifications plugin (in combination with OneSignal). Will not be available on web
function usePush() {
  const {
    setLogLevel,
    setExternalUserId,
  } = OneSignal;
  let tokenRegistered = false;

  // if not using OneSignal, can save 'registrationToken' instead in 'registration' event
  const registerPushToken = async () => {
    if (tokenRegistered) return;// prevents multiple calls to the API

    OneSignal.getDeviceState((deviceState) => {
      const { subscribed, userId: playerId, pushToken } = deviceState;
      // 'pushToken' will be null if 'subscribed' is false
      if (subscribed && playerId) {
        $device.registerPushToken({playerId, pushToken})
          .then((res) => {
            tokenRegistered = true;
            Promise.resolve(res);
          })
          .catch(err => Promise.reject(err));
      }
    });
  };

  const init = (userId) => {
    OneSignal.setAppId(PUSH_CONFIG.ONE_SIGNAL.APP_ID);
    console.log("Push available. will use OneSignal");
    setLogLevel(6, 0); // Uncomment to set OneSignal device logging to VERBOSE
    if (userId) {
      setExternalUserId(userId);
    }
    registerPushToken();
  };

  const addListeners = async (handlers) => {
    const {
      onRegister,
      onError,
      onPushReceived,
      onPushAction
    } = handlers || {};

    OneSignal.setNotificationOpenedHandler(function (ev) {
      console.log("notificationOpened: ", ev);
      const { action, notification } = ev;

      if (action) {
        const { actionID: btnId } = action;//clicked btn's ID
        if (btnId && notification) {
          const payloadStr = JSON.parse(notification.rawPayload).custom;
          const customPayload = JSON.parse(payloadStr || '{}').a;
          const btns = customPayload.actionButtons;
          const clickedBtn = btns.find(b => b.id === btnId);
          if (clickedBtn && (clickedBtn.url || clickedBtn.state)) {
            if (clickedBtn.url) {
              console.log("Handling notification via url");
              onPushAction && onPushAction(clickedBtn.url, null, { refresh: true });
            } else if (clickedBtn.state) {
              console.log("Handling notification via state");
              onPushAction && onPushAction(clickedBtn.state, clickedBtn.stateParams || clickedBtn.params, { refresh: clickedBtn.refresh });
            }
          }
        }
      }
    });

    await PushNotifications.addListener("registration", (token) => {
      console.info("Registration token: ", token.value);
      onRegister && onRegister(token);
      if (token) {
        registerPushToken();
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
      onError && onError(err);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification);
        onPushReceived && onPushReceived(notification);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
        onPushAction && onPushAction(notification);
      }
    );
  };

  const permissionStatus = async () =>
    await PushNotifications.checkPermissions();

  const isAccessGranted = async () => {
    const status = await permissionStatus();
    console.log('push-access status: ', status);
    return typeof status === 'object' && status.receive === 'granted' || status === 'granted';
  };

  const requestAccess = async (display_permission) => {
    console.log("requesting push access: ", display_permission);
    let permStatus = await permissionStatus();

    if (["prompt", "denied"].includes(permStatus.receive)) {
      console.log("requesting push access");
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("permission_denied");
    }

    await PushNotifications.register();
    if (display_permission) {
      const { display } = await LocalNotifications.checkPermissions();
      console.log("granted permissions:", display);
      const granted = display === "granted";
      if (!granted) {
        await delay(3000);
        const perms = await LocalNotifications.requestPermissions();
        console.log("requested permissions: ", perms);
        if (!perms) {
          throw new Error("permission_denied");
        }
        return perms;
      }
      return display;
    }
  };

  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log("delivered notifications", notificationList);
  };

  return {
    init,
    addListeners,
    requestAccess,
    isAccessGranted,
    permissionStatus,
    getDeliveredNotifications,
  };
}

// used for local-notifications
function useBrowserNotification() {
  let 
    browserRegistered = false,
    swRegistered = false;
  // if not using OneSignal, can save 'registrationToken' instead in 'registration' event
  // CAN BE DEPRECATED later, since we're using our own script for browser-push notifications
  const registerBrowser = async () => {
    if (browserRegistered) return;// prevents multiple calls to the API

    const { getUserId, getSubscription, } = useOneSignal();
    const playerId = await getUserId();
    const pushToken = await getSubscription();
    if (pushToken && playerId) {
      $device.registerPushToken({playerId, pushToken})
        .then((res) => {
          console.log("Browser registered: ", res);
          browserRegistered = true;
          Promise.resolve(res);
        })
        .catch(err => Promise.reject(err));
    }
  };

  const init = (userId) => {
    console.log("Using Browser notifications");
    const { init, setExternalUserId, setDefaultNotificationUrl } = useOneSignal();
    init(PUSH_CONFIG.ONE_SIGNAL.WEB_CONFIG);
    if (userId) {
      setExternalUserId(userId);
    }
    const URL = window.location.protocol + '//' + window.location.host;// includes port too
    setDefaultNotificationUrl(URL);
    registerBrowser();
  };

  const isSupported = () => {
    return "Notification" in window && !!("PushManager" in window);
  };

  const addListeners = async (handlers) => {
    console.log('getting swRegistration')

    if(!swRegistered) {
      const lastChecked = Number($storage.$session.get('sw-checked', 0));
      swRegistered = Date.now() - lastChecked < (3600 * 1000);// checked within last hour
    }
    if(swRegistered) {
      console.log('SW already registered. Returning');
      return;
    }// Subscription already registered

    $storage.$session.set('sw-checked', Date.now());

    navigator.serviceWorker.ready.then((swRegistration) => {
      console.log('swRegistration: ', swRegistration)
      swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true, // set user to see every notification
          applicationServerKey: urlB64ToUint8Array(PUSH_CONFIG.VAPID_PUBLIC_KEY),
        })
        .then((subscription) => {
          console.log("got browser-push subscription: ", subscription);
          $device.registerBrowser(subscription)
            .then(res => Promise.resolve(res))
            .catch(err => Promise.reject(err));
        })
        .catch((error) => {
          console.error("Error occurred while enabling push ", error);
          Promise.reject({ error, code: 'subscribeError' });
        });
    }).catch(error => {
      console.log('Error in serviceWorker Ready: ', error);
      Promise.reject({ error, code: 'serviceWorkerReadyError' });
    });
  };

  const permissionStatus = () => {
    return new Promise((resolve, reject) =>
      !isSupported()
        ? reject("not_supported")
        : resolve(Notification.permission)
    );
  };

  const isAccessGranted = async () => {
    try {
      const status = await permissionStatus();
      return status === 'granted';
    } catch(e) {
      return false;
    }
  };

  const requestAccess = () => {
    return new Promise((resolve, reject) => {
      if (!isSupported()) {
        return reject("not_supported");
      }
      if (Notification.permission === "granted") {
        return resolve("granted");
      } else if (Notification.permission === "denied") {
        return reject("denied");
      } else {
        Notification.requestPermission().then((status) => {
          //status can be 'default', 'granted' or 'denied'
          if (status === "granted") {
            registerBrowser();
            return resolve(status);
          }
          reject(status);
        });
      }
    });
  };

  return {
    init,
    isSupported,
    isAccessGranted,
    permissionStatus,
    addListeners,
    requestAccess,
  };
}

function useNotifications() {
  return isPushAvailable ? usePush() : useBrowserNotification();
}

export { isPushAvailable, usePush, useBrowserNotification, useNotifications };
