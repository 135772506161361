import api from "@/utils/api";
import { BASE_URL, VENDOR_PATHS } from "@/constants/api";
import { getHeaders } from "@/utils/fns";

export default {
  initSession(params) {
    const URL =
      BASE_URL() +
      VENDOR_PATHS.payments.basePath +
      VENDOR_PATHS.payments.newSession;
    return api
      .post(URL, params, { headers: getHeaders() })
      .then((response) => response.data);
  },
  updateSession(params) {
    const URL =
      BASE_URL() +
      VENDOR_PATHS.payments.basePath +
      VENDOR_PATHS.payments.newSession;
    return api
      .put(URL, { payload: params }, { headers: getHeaders() })
      .then((response) => response.data);
  },
};
