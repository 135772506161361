import CryptoJS from "crypto-js";
import hashObject from "object-hash";
import { isPuppeteer } from "./fns";

const keyName = "_lsaesk"; // LS AES key
let ENC_KEY = localStorage.getItem(keyName);

window.encodeKey = (key, global = false) => {
  key = global ? "g." + key : key;
  if (isPuppeteer() && !ENC_KEY) return key;
  return hashObject({ key });
};

const encrypt = (str) => CryptoJS.AES.encrypt(str, ENC_KEY).toString();
const decrypt = (str) =>
  CryptoJS.AES.decrypt(str, ENC_KEY).toString(CryptoJS.enc.Utf8);
// window.dcrpt = decrypt;

export default {
  setup() {
    if (isPuppeteer() && !ENC_KEY) return;
    if (!ENC_KEY) {
      const keys = Object.keys({ ...localStorage });
      ENC_KEY = CryptoJS.lib.WordArray.random(5).toString();
      localStorage.setItem(keyName, ENC_KEY);
      keys.map((key) => {
        const value = localStorage.getItem(key);
        this.set(key, value);
        localStorage.removeItem(key);
      });
    }
  },
  get(key, defValue = null, global = false) {
    const encKey = encodeKey(key, global); //convert normal key to enc-key to get data
    let item = localStorage.getItem(encKey);
    if ([null, undefined].includes(item)) {
      return defValue;
    }
    if (typeof item === "string" && item.length > 1 && item[0] === "=") {
      item = item.slice(1); // remove flag from first place to get real-encrypted data
      try {
        item = decrypt(item);
      } catch (e) {}
    }
    try {
      item = JSON.parse(item);
    } catch (e) {}
    return item;
  },
  set(key, value, global = false) {
    let data = value;
    if (["object", "Array"].includes(typeof value)) {
      data = JSON.stringify(value);
    }
    const encKey = encodeKey(key, global);
    // console.log("setting %s as %s", key, encKey);
    let val = data;
    if (typeof data === "string") {
      val = "=" + encrypt(data); // flag to identify encrypted data
    }
    localStorage.setItem(encKey, val);
  },
  delete(key, global = false) {
    localStorage.removeItem(key);
    const encKey = encodeKey(key, global); //convert normal key to enc-key to get data
    console.log("deleting key %s and %s: ", encKey, key);
    localStorage.removeItem(encKey);
  },
  $session: {
    ...sessionStorage,
    get(key, defValue) {
      const encKey = encodeKey(key);
      let item = sessionStorage.getItem(encKey) || defValue;
      if (item?.length > 1 && item[0] === "=") {
        item = item.slice(1); // remove flag from first place to get real-encrypted data
        try {
          item = decrypt(item);
        } catch (e) {}
        try {
          item = JSON.parse(item);
        } catch (e) {}
      }
      // console.log("getting session %s: ", encKey, key, item);
      return item || null;
    },
    set(key, value) {
      const encKey = encodeKey(key);
      let val = value;
      if (["Array", "object"].includes(typeof value)) {
        val = JSON.stringify(val);
      }
      let data = val;
      if (typeof data === "string" && data.length > 1) {
        data = "=" + encrypt(data); // flag to identify encrypted data
      }
      // console.log("setting session %s: ", encKey, key, value);
      sessionStorage.setItem(encKey, data);
    },
    remove(key) {
      sessionStorage.removeItem(key);
      const encKey = encodeKey(key);
      sessionStorage.removeItem(encKey);
    },
  },
  $cookie: {
    get(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return undefined;
    },
    set(cname, cvalue, expiresIn = 1) {
      const d = new Date();
      d.setTime(d.getTime() + expiresIn * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
  },
  $ls: localStorage,
};
