<template>
  <ion-item v-if="canShowBanner" class="feedback-banner" color="medium">
    <ion-label class="ion-text-wrap">
      <h2>
        <strong>{{ $t("banners.webFeedback.heading") }}</strong>
      </h2>
      <p v-html="$t('banners.webFeedback.altText')" />

      <div class="ion-text-end">
        <ion-button
          fill="clear"
          size="small"
          color="dark"
          @click="dismiss"
          v-track-this="{
            event: 'click',
            category: 'FeedbackDesktop',
            label: 'dismiss_cta',
          }"
        >
          {{ $t("banners.webFeedback.dismiss") }}
        </ion-button>
        <ion-button
          color="primary"
          size="small"
          class="cta"
          target="_blank"
          :href="googleFormUrl"
          @click="opened = true"
          v-track-this="{
            event: 'click',
            category: 'FeedbackMobile',
            label: 'open_form',
          }"
        >
          {{ $t("banners.webFeedback.cta") }}
        </ion-button>
      </div>
    </ion-label>
    <ion-icon
      v-if="opened"
      slot="end"
      color="dark"
      @click="dismiss()"
      :icon="icons.close"
      v-track-this="{
        event: 'click',
        category: 'FeedbackDesktop',
        label: 'dismiss_cta',
      }"
    />
  </ion-item>
</template>

<script>
import { isPlatform } from "@ionic/core";
import { close } from "ionicons/icons";
import { getUser, usePrompts } from "@/composables";
import $storage from "@/utils/storage";

export default {
  components: {},
  data() {
    return {
      opened: false,
      dismissed: $storage.get("dismiss.mf", 0),
      timedOut: false,
      googleFormUrl:
        "https://docs.google.com/forms/d/e/1FAIpQLSfPXdquHaQncOS4HwtNVLp7hX58r7cHjYnqmjcFylkKPkI6EQ/viewform?usp=pp_url",
    };
  },
  computed: {
    canShowBanner() {
      return !this.dismissed && isPlatform("mobileweb") && this.timedOut;
    },
  },
  methods: {
    dismiss() {
      $storage.set("dismiss.mf", 1); // dismiss.mobileFeedback
      this.dismissed = true;
      if (!this.opened) this.toast(this.$t("common.untilNextTime"));
    },
  },
  mounted() {
    const randomUser = Math.round(Math.random());
    const isUser = !this.isGuest();
    if (!this.dismissed && (randomUser || isUser)) {
      const DELAY_SEC = 30 * 1000; // display banner after 30 sec
      console.log(
        "Feedback banner will appear in %d seconds",
        DELAY_SEC / 1000
      );
      setTimeout(() => {
        this.timedOut = true;
      }, DELAY_SEC);
    } else console.log("Will not show mobile-feedback banner");
  },
  setup() {
    const { toast } = usePrompts();
    const { isGuest } = getUser();
    return { toast, isGuest, icons: { close } };
  },
};
</script>

<style lang="scss">
.feedback-banner {
  ion-label {
    margin-bottom: 0 !important;
  }
  @media (prefers-color-scheme: dark) {
    --background: initial;
    .cta::part(native) {
      color: var(--ion-color-dark);
    }
  }
  ion-icon {
    margin-top: 0;
  }
}
</style>
