import { BASE_URL } from "@/constants/api";
import APP_META from "@/constants/meta";

import { getUser } from "@/composables";
import { AUTH } from "@/constants/pages/auth";

import api from "@/utils/api";
import $storage from "@/utils/storage";

const $qs = document.querySelector.bind(document);
const [head] = document.getElementsByTagName("head");

export function shareSupported() {
  return window.plugins && window.plugins.socialsharing;
}
export function openSharer(params) {
  const { message, subject, image, url } = params;
  if (shareSupported()) {
    window.plugins.socialsharing.share(
      message,
      subject,
      image,
      url,
      (success) => {
        console.log("Share completed? " + success.completed);
        // On Android apps mostly return false even while it's true
        console.log("Shared to app: " + success.app);
        // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      },
      (failed) => {
        console.log("Sharing failed with message: " + failed);
      }
    );
    return true;
  } else if (navigator.share !== undefined) {
    return navigator
      .share({
        title: subject,
        text: message,
        url,
      })
      .then(function () {
        console.log("Successful share");
      })
      .catch(function (error) {
        console.log("Error sharing:", error);
      });
  }
  return null;
}
// if date in Y-m-d or d-m-Y format
export const isProperDate = (str) => {
  if (str) {
    if (typeof str === "object" && str.constructor === Date) return true;
    return (
      typeof str === "string" &&
      (str.match(/^\d{4}-\d{1,2}-\d{1,2}/) ||
        str.match(/\d{1,2}-\d{1,2}-\d{4}/))
    );
  }
  return false;
};

export const dateHasYear = (date) => {
  if (date) {
    if (typeof date === "object") {
      return date.year || date.constructor === Date;
    }
    if (typeof date === "number") return /\d{2,4}/.test(date);
    const d = date.split("-");
    if (d.length) {
      const [year] = d[0].match(/\d{4}/) ? d : d.reverse();
      return year.match(/\d{4}/);
    }
  }
};

export const dateHasMonth = (date) => {
  if (!dateHasYear(date)) return false;
  if (date) {
    if (typeof date === "object") {
      return date.month || date.constructor === Date;
    }
    if (typeof date === "number") return /\d{1,2}/.test(date);
    const d = date.split("-");
    if (d.length) {
      const [year] = d[0].match(/\d{4}/) ? d : d.reverse();

      const [, month] = d[0].match(/\d{4}/) ? d : d.reverse();
      return Boolean(month);
    }
  }
};

// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the push-subscription option
export function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function isValidMobile(mobile, dialCode) {
  let isValid = mobile && mobile.length >= 7 && dialCode;
  if (!isValid) return false;

  let regex = AUTH.regex.MOBILE.split("_VS_");
  regex = dialCode.match(/\+91/) ? regex[0] : regex[1];
  const mobileRegex = new RegExp(regex, "i");

  return (dialCode + "-" + mobile).match(mobileRegex);
}

export function isValidEmail(email) {
  if (!email) return;
  const mailRegex = new RegExp(AUTH.regex.EMAIL, "i");
  return email.match(mailRegex);
}
export function isValidPassword(pwd) {
  return pwd?.length >= 8 && pwd?.match(/[a-z0-9_.#$]+/gi, "");
}

export function setMetaForPage(meta, self) {
  const $addMeta = (property, content, keyName) => {
    const el = document.createElement("meta");
    // eg, '<meta name="twitter:card" content="some value">
    // eg, '<meta property="og:url" content="some value">
    el.setAttribute(keyName || "property", property);

    const tmp = document.createElement("DIV");
    tmp.innerHTML = content;
    const text = tmp.textContent || tmp.innerText;

    el.content = text;
    head.append(el);
    head.setAttribute("has-title", 1);
    setTimeout(() => head.removeAttribute("has-title"), 2000);
  };

  let title = meta.title;
  // ideal length: 60-70, adding check on 50 to keep margin for text that will be added
  if (meta.titleBranding !== false && title.length <= 50) {
    title += meta.titleBranding || self.$t(APP_META.titleBranding);
  }
  title = title.replace(/__/g, "|");
  const desc = meta.description.replace(/__/g, "|");

  //* facebook
  $qs("[property='og:title']")
    ? $qs("[property='og:title']").setAttribute("content", title)
    : $addMeta("og:title", title);

  $qs("[property='og:description']")
    ? $qs("[property='og:description']").setAttribute("content", desc)
    : $addMeta("og:description", desc);

  if (meta.image) {
    $qs("[property='og:image']")
      ? $qs("[property='og:image']").setAttribute("content", meta.image)
      : $addMeta("og:image", meta.image);
  }
  if (meta.type) {
    $qs("[property='og:type']")
      ? $qs("[property='og:type']").setAttribute("content", meta.type)
      : $addMeta("og:type", meta.type);
  }
  if (meta.video) {
    $qs("[property='og:video']")
      ? $qs("[property='og:video']").setAttribute("content", meta.video)
      : $addMeta("og:video", meta.video);
  }

  const url = meta.url || window.location.href;
  $qs("[property='og:url']")
    ? $qs("[property='og:url']").setAttribute("content", url)
    : $addMeta("og:url", url);

  if (meta.profile) {
    $qs("[property='og:type']")
      ? $qs("[property='og:type']").setAttribute("content", "profile")
      : $addMeta("og:type", "profile");

    $qs("[property='og:profile:first_name']")
      ? $qs("[property='og:profile:first_name']").setAttribute(
          "content",
          meta.profile.firstName
        )
      : $addMeta("og:profile:first_name", meta.profile.firstName);
    $qs("[property='og:profile:last_name']")
      ? $qs("[property='og:profile:last_name']").setAttribute(
          "content",
          meta.profile.lastName
        )
      : $addMeta("og:profile:last_name", meta.profile.lastName);
    const gender = meta.profile.gender !== 0 ? "male" : "female";
    $qs("[property='og:profile:gender']")
      ? $qs("[property='og:profile:gender']").setAttribute("content", gender)
      : $addMeta("og:profile:gender", gender);
  }
  if (meta.article) {
    const article = { ...meta.article };
    $qs("[property='og:type']")
      ? $qs("[property='og:type']").setAttribute("content", "article")
      : $addMeta("og:type", "article");

    $qs("[property='og:article:published_time']")
      ? $qs("[property='og:article:published_time']").setAttribute(
          "content",
          article.publishTime
        )
      : $addMeta("og:article:published_time", article.publishTime);

    if (article.updateTime) {
      $qs("[property='og:article:modified_time']")
        ? $qs("[property='og:article:modified_time']").setAttribute(
            "content",
            article.updateTime
          )
        : $addMeta("og:article:modified_time", article.updateTime);
    }
    if (article.author) {
      $qs("[property='og:article:author']")
        ? $qs("[property='og:article:author']").setAttribute(
            "content",
            article.author
          )
        : $addMeta("og:article:author", article.author);
    }
    if (article.section) {
      $qs("[property='og:article:section']")
        ? $qs("[property='og:article:section']").setAttribute(
            "content",
            article.section
          )
        : $addMeta("og:article:section", article.section);
    }
    if (article.tag) {
      $qs("[property='og:article:tag']")
        ? $qs("[property='og:article:tag']").setAttribute(
            "content",
            article.tag
          )
        : $addMeta("og:article:tag", article.tag);
    }
  }

  //* twitter
  $qs("[property='twitter:title']")
    ? $qs("[property='twitter:title']").setAttribute("content", title)
    : $addMeta("twitter:title", title);

  $qs("[property='twitter:description']")
    ? $qs("[property='twitter:description']").setAttribute("content", desc)
    : $addMeta("twitter:description", desc);

  if (meta.image) {
    $qs("[name='twitter:card']")
      ? $qs("[name='twitter:card']").setAttribute("content", meta.image)
      : $addMeta("twitter:card", meta.image, "name");
  }

  $qs("[property='twitter:url']")
    ? $qs("[property='twitter:url']").setAttribute("content", url)
    : $addMeta("twitter:url", url);

  //* general
  $qs("[name='title']")
    ? $qs("[name='title']").setAttribute("content", title)
    : $addMeta("title", title, "name");

  $qs("[name='description']")
    ? $qs("[name='description']").setAttribute("content", desc)
    : $addMeta("description", desc, "name");

  if (meta.script) {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(meta.script, null, 4);
    document.head.append(script);
  }
  if (!document.getElementById("#aboutUsMeta")) {
    const script = document.createElement("script");
    script.id = "aboutUsMeta";
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        url: "https://" + window.location.host,
        logo: `/assets/icons/icon-round.png`,
      },
      null,
      4
    );
    head.append(script);
  }

  setTimeout(() => (document.title = title));
}

export function containsUrl(str) {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  const matches = str.match(urlRegex);
  return (matches && matches[0]) || "";
}

export function fetchUrlPreview(data, callback) {
  const apiUrl = BASE_URL() + "/other/meta";
  api
    .get(apiUrl, { params: { ...data, _lp: true } })
    .then(({ data }) => {
      if (data.success) {
        return callback(null, data.result);
      }
      callback(data);
    })
    .catch((err) => {
      const error = err
        ? (err.responseJSON && err.responseJSON.result) ||
          err.responseText ||
          err.statusText
        : "Unknown error";
      callback(error);
    });
}
export function getHeaders(letPass, additionalHeaders) {
  const headers = {
    token: $storage.get("token"),
  };
  if (letPass && !headers.token) {
    delete headers.token;
    headers["x-moamin-pass"] = 1;
  }
  if (additionalHeaders) {
    return {
      ...headers,
      ...additionalHeaders,
    };
  }
  return headers;
}

export function getReadableTime(duration) {
  let min = 0,
    sec = 0;
  const hrs = duration > 0 ? parseInt(duration / 3600) : 0;
  const minusHr = duration > 0 ? duration % 3600 : 0;
  if (minusHr > 0) {
    min = parseInt(minusHr / 60);
    sec = parseInt(duration % 60);
  }
  let span = hrs ? ("0" + hrs).slice(-2) + ":" : "";
  span += ("0" + min).slice(-2) + ":";
  span += ("0" + sec).slice(-2);
  return span;
}

export function isApiDown(error) {
  return error && error.message === "Network Error";
}

export function isPuppeteer() {
  return navigator.webdriver;
}
export const seoLabel = (str) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[?.]/g, " ")
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9- ]+/g, "")
    .replace(/ {2,}/g, " ")
    .replace(/ /g, "+");

export const labelFromSEO = (str) =>
  str && decodeURIComponent(str.replace(/\+/g, " "));

export function locale() {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en"
    );
  }
}
export function currencySymbol(currency, forLocale) {
  if (!currency || currency.length < 2) return currency;
  forLocale = forLocale || locale();
  try {
    return new Intl.NumberFormat(forLocale, { style: "currency", currency })
      .formatToParts(1)
      .find((x) => x.type === "currency").value;
  } catch (e) {
    console.warn("Failed to get currency symbol for: ", currency, forLocale);
    throw e;
  }
}

export const getJsonFromUrl = (url, debug) => {
  let headers = {};
  if (url.includes("API_URL/") || url.match(/^\//)) {
    const LET_PASS = false;
    let additionalHeaders = {
      "x-moamin-app": 1,
    };
    const user = getUser().currentUser({});
    if (user.roleIs === "ADMIN") {
      additionalHeaders = {
        ...additionalHeaders,
        "x-moamin-admin": 1,
      };
    }
    if (debug) {
      additionalHeaders["X-DEBUG"] = 1;
    }
    if (url.match(/\.json$/)) {
      const chunks = url.split("/");
      const name = chunks.pop();
      const token = btoa(name + "_" + Date.now());

      additionalHeaders = {
        ...additionalHeaders,
        "x-moamin-resource": token, // value hidden under key named with new hash
        //hardcoded token. safe to add here, since hooks are not part of 'dist'
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInVzZXJFbWFpbCI6ImNzZWVyOTBAZ21haWwuY29tIn0.VrYmKytEjygqbK9w0Ic3Unz9LulDwblSJtdV0r-muR0",
      };
    }
    headers = getHeaders(LET_PASS, additionalHeaders);
  }

  const src = url.match(/API_URL/) ? BASE_URL() + url.split("API_URL")[1] : url;
  return api
    .get(src, { headers })
    .then((response) => response.data)
    .catch((error) => {
      console.warn("error in loading json: %s\n", url, error);
      const err = new Error(error);
      window.onerror &&
        window.onerror(
          `Error in loading JSON from url: ${url}`,
          src,
          err.lineNumber,
          err.stack,
          err
        );
      throw error;
    });
};

export function getFlagUrl(countryCode) {
  // return `https://countryflagsapi.com/png/` + countryCode.toLowerCase();
  return `https://ipdata.co/flags/${countryCode.toLowerCase()}.png`;
}
