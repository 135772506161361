<template>
  <ion-app>
    <ion-router-outlet
      v-if="$route.meta.fullWidth"
      id="main-content"
      class="split-pane-main menu-content menu-content-overlay"
    />
    <template v-else>
      <ion-split-pane when="md" content-id="main-content">
        <main-menu @show-cookie-policy="$refs.cookieBanner.show()" />
        <ion-router-outlet
          id="main-content"
          class="split-pane-main menu-content menu-content-overlay"
        />
        <right-menu />
      </ion-split-pane>
    </template>

    <cookie-prompt ref="cookieBanner" @dismissed="seenCookieBanner = true" />
    <template v-if="isAppWindow && seenCookieBanner">
      <lang-prompt @closed="!isGuest() ? promptIncompleteAccount() : null" />
      <finish-account-setup-prompt
        v-if="!hidePromptOnPage && !isGuest()"
        ref="finishSetup"
      />
      <!-- <app-install-desktop place="content" /> -->
    </template>
    <media-player
      v-if="isAppWindow"
      v-show="!playerClosed"
      @player-closed="onPlayerClosed"
      @play-audio="onPlayStart"
    />
  </ion-app>
</template>

<script>
import { IonApp, IonSplitPane, IonRouterOutlet, isPlatform } from "@ionic/vue";
import { informationCircleOutline as infoIcon } from "ionicons/icons";
import { App } from "@capacitor/app";
import { provide, ref } from "vue";

import { usePrompts, getUser, useTracker } from "@/composables";
import { setMetaForPage, isPuppeteer } from "@/utils/fns";
import { $user } from "@/apis";

import $storage from "@/utils/storage";
import MainMenu from "@/views/menus/MainMenu.vue";
import RightMenu from "@/views/menus/RightMenu.vue";
import FinishAccountSetupPrompt from "@/components/Auth/FinishAccountSetupPrompt.vue";
import CookiePrompt from "@/components/banners/CookiePrompt.vue";
// import AppInstallDesktop from "@/components/banners/AppInstallDesktop.vue";
import MediaPlayer from "@/components/MediaPlayer/MediaPlayer.vue";
import LangPrompt from "@/components/Lang/LangPrompt.vue";
import notificationMixin from "@/mixins/notifications";
import authMixin from "@/mixins/auth";
import { getJsonFromUrl } from "@/utils/fns";
import { useNotifications } from "@/lib/notifications";
import { useTranslation } from "@/i18n";

export default {
  components: {
    IonApp,
    IonSplitPane,
    IonRouterOutlet,
    MainMenu,
    RightMenu,
    FinishAccountSetupPrompt,
    CookiePrompt,
    // AppInstallDesktop,
    MediaPlayer,
    LangPrompt,
  },
  mixins: [notificationMixin, authMixin],
  data() {
    const isFrameOrPuppet = window.top !== window.self || isPuppeteer();
    return {
      playerClosed: $storage.get("player.closed", 0),
      // app-content-update variables
      refreshing: false,
      registration: null,
      updateExists: false,
      isAppWindow: !isFrameOrPuppet,
      hidePromptOnPage:
        this.$route.name === "onboardingPage" ||
        this.$route.name.match(/^user/i),
      seenCookieBanner: false,
    };
  },
  methods: {
    setMeta(options) {
      const isWebVersion = isPlatform("mobileweb") || isPlatform("desktop");
      if (isWebVersion && !isPlatform("pwa")) {
        setMetaForPage(options, this);
      }
    },
    onPlayerClosed() {
      this.playerClosed = true;
      $storage.set("player.closed", 1);
    },
    onPlayStart() {
      this.playerClosed = false;
      $storage.set("player.closed", 0);
    },
    setupShareFromExternal() {
      const { userId } = this.currentUser();
      const intentPlugin = window.plugins && window.plugins.intent;
      intentPlugin &&
        intentPlugin.getCordovaIntent(
          (intent) => {
            const action =
              intent && intent.action && intent.action.split(".").pop();
            if (["SEND", "SEND_MULTIPLE"].includes(action)) {
              const { toast, $t } = this;
              if (!userId) {
                // return $toast.showAuthPrompt();
                console.log("Not logged in");
                return;
              }

              console.warn("Got share-intent: ", intent);
              const items = [...intent.clipItems];
              let files = [];
              items.forEach((item) => {
                const type = (item.type || intent.type).split("/").shift();
                let url;
                switch (type) {
                  case "text":
                    url = item.text; //Note: only available for URLs from social media. For text files, it's null
                    if (!url) {
                      toast(
                        $t("common.actions.jsShareFileTypeError", {
                          fileType: "text",
                        }),
                        "error"
                      );
                    } else {
                      console.log("Sharing url: ", url);
                      files.push({ type, text: url });
                    }
                    break;
                  case "image":
                    url = item.uri;
                    console.log("Image: ", url);
                    files.push({ type, imageUrl: url });
                    break;
                  default:
                    const subtype = item.type.split("/").pop();
                    let fileType = "this";
                    if (subtype === "zip") {
                      fileType = "zip";
                    } else if (type === "video") {
                      fileType = "video";
                    }
                    toast(
                      $t("common.actions.jsShareFileTypeError", { fileType }),
                      "error"
                    );
                }
              });
              setTimeout(() => {
                console.log("processed all urls: ", files);
                document
                  .querySelector('[create-post][type="post"]')
                  .trigger("click", files);
              }, 1000);
            }
          },
          (error) => console.error("Error in intent: ", error)
        );
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      const { toast, $t } = this;
      toast({
        duration: undefined,
        header: $t("common.actions.jsUpdateAvailable.header"),
        message: $t("common.actions.jsUpdateAvailable.message"),
        color: "success",
        icon: infoIcon,
        position: "top",
        buttons: [
          {
            side: "end",
            text: $t("common.actions.jsUpdateAvailable.cta"),
            handler: () => this.refreshApp(),
          },
        ],
      });
    },
    refreshApp() {
      this.updateExists = false;

      const { registration } = this;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!registration || !registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      const worker = registration.waiting;
      worker.postMessage({ type: "SKIP_WAITING" });
    },
    promptIncompleteAccount() {
      const {
        currentUser,
        $refs: { finishSetup },
      } = this;
      const { userId } = currentUser();
      if (finishSetup && userId) {
        const { myProfile, accountCompleteStat } = this;
        const { dontRemind } = accountCompleteStat();
        if (dontRemind) return false;

        const onHasProfile = () => {
          const { percent } = accountCompleteStat();
          if (percent !== 100) {
            this.$nextTick(() => finishSetup.openModal());
          }
        };

        if (myProfile.loading) {
          const clear = this.$watch("myProfile", () => {
            clear();
            onHasProfile();
          });
        } else {
          onHasProfile();
        }
      }
    },
  },
  created() {
    // Listen for our custom event from the SW registration
    if ("serviceWorker" in navigator) {
      document.addEventListener("swUpdated", this.updateAvailable, {
        once: true,
      });

      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    }

    const { $moamin } = this;

    getJsonFromUrl("/data/app/app.features.json").then(
      (f) => ($moamin.features = f)
    );
    getJsonFromUrl("/data/app/app.services.json").then(
      (s) => ($moamin.services = s)
    );
    getJsonFromUrl("/data/app/languages.json").then(
      (l) => ($moamin.languages = l)
    );
  },
  mounted() {
    const { currentUser } = this;
    this.setupShareFromExternal();
    this.enableTracker();

    const { userId } = currentUser();
    if (userId) {
      this.fetchProfile(userId); // fetches fresh profile
    }

    window.addEventListener("resize", () => {
      this.isMobileSize = window.innerWidth <= 576;
    });

    // TODO: test this
    App.addListener("appUrlOpen", (data) => {
      // Example url: https://beerswift.app/tabs/tabs2
      // slug = /tabs/tabs2
      const { url } = data;
      const chunks = url.split("//").pop().split("/");
      chunks.shift(); //remove domain part
      const slug = chunks.join("/");

      // We only push to the route if there is a slug present
      if (slug) {
        router.push({
          path: slug,
        });
      }

      // Legacy logic. Might be handy for later
      /*
      if (eventData.params && Object.keys(eventData.params)) {
        let via = eventData.params.v || eventData.params.enc;
        // console.log('via: ', via);
        if (via) {
          via = via.split("|");
          let stateName = via[0];
          stateName = atob(stateName);
          let params = via[1] || null;
          if (params) {
            params = JSON.parse(atob(params));
          }
          console.log("dest: ", stateName, params);
          this.$router.push({ name: stateName, params, refresh: true });
        }
      }*/
    });
  },
  setup() {
    const { t: $t } = useTranslation();
    const skipAds = ref(true);
    provide("skipAds", skipAds);

    const myProfile = ref({ loading: true });
    provide("myProfile", myProfile);

    const isMobileSize = ref(window.innerWidth <= 576);
    provide("isMobileSize", isMobileSize);

    const { currentUser, isGuest } = getUser();
    // window.currentUser = currentUser;

    const fetchProfile = (profileId, cached, moreParams) => {
      const { userId } = currentUser();
      return $user
        .getProfile(profileId, cached === true, moreParams)
        .then((userObj) => {
          const origResult = { ...userObj };
          if (userId === profileId) {
            if (userObj._) {
              delete userObj._;
            }
            myProfile.value = { ...userObj };
          }
          return origResult;
        })
        .catch((error) => {
          const { data } = error?.data ? error : { data: error };
          const msg =
            data?.message || data?.statusText || "errors.service.unknown";
          toast($t(msg), "error");
          throw data;
        })
        .finally(() => console.log("called profile api: ", myProfile.value));
    };

    provide("fetchProfile", fetchProfile);

    useNotifications().init(currentUser().userId); // inits different plugins for web and devices

    const { toast, confirm } = usePrompts();
    const { enable: enableTracker, Analytics } = useTracker();

    return {
      myProfile,
      isMobileSize,
      fetchProfile,
      toast,
      confirm,
      isGuest,
      currentUser,
      enableTracker,
      Analytics,
    };
  },
};
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  ion-split-pane {
    --side-max-width: 25%;
    --side-min-width: 200px;
    @media screen and (min-width: 576px) {
      --side-max-width: 28%;
      --side-min-width: 280px;
    }
  }
  @media (prefers-color-scheme: dark) {
    ion-item {
      --background: transparent;
    }
    // primary-color ion-button
    ion-button:not(.ion-color) {
      --color: var(--ion-color-step-750);
    }
  }
  .display-flex {
    display: flex;
  }
  // fixes height issue in action-sheet button on iOS
  .ios .action-sheet-container .action-sheet-group button {
    min-height: 64px;
  }
  .overflow-x {
    white-space: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  [hidden] {
    display: none !important;
  }
  ion-item,
  ion-item-divider {
    > ion-label:not(.ion-no-margin).ios {
      margin: 8px 0;
    }
  }

  ion-button.anchor {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    margin: 0;
  }
}
</style>
