import {
  loadingController,
  toastController,
  alertController,
  isPlatform,
} from "@ionic/vue";
import { isPuppeteer } from "@/utils/fns";

export function usePrompts() {
  const alert = async (options = {}) => {
    const message = typeof options === "string" ? options : options.message;
    const { cssClass, header, subHeader, subtitle, buttons } = options;
    const params = {
      cssClass: cssClass || "my-custom-class",
      header: header || "Alert",
      message,
      buttons: ["OK"],
      ...options,
    };
    if (subHeader || subtitle) {
      params.subHeader = subHeader || subtitle;
    }
    if (typeof options !== "string" && buttons) {
      params.buttons = buttons;
    }
    const alert = alertController.create(params);
    // await alert.present();

    // const { role } = await alert.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
    return alert.then(($alert) => {
      $alert.present();
      return $alert;
    });
  };

  const confirm = (message, options = {}) => {
    return new Promise(async (resolve, reject) => {
      const { buttons } = options || {};
      const params = {
        ...options,
        message,
        header: options.header || options.heading || "Please confirm",
        buttons: [
          {
            role: "cancel",
            text: (buttons && buttons.cancel) || "Cancel",
            cssClass: "button-dark",
          },
          {
            role: "destructive",
            text: (buttons && buttons.confirm) || "Confirm",
          },
        ],
        backdropDismiss: false,
      };
      // console.log("params: ", params);
      const modal = await alert(params);
      const { role } = await modal.onDidDismiss();

      role === "destructive" ? resolve() : reject();
    });
  };

  const loader = async (options = {}, skipShow = false) => {
    const message =
      typeof options === "string"
        ? options
        : options?.message || options?.msg || null;
    const onDelay = options?.onDelay;
    const params = {
      cssClass: options.cssClass || "",
      message,
    };
    if (options.timeout) {
      params.duration = options.timeout;
    }
    if (options.spinner === false) {
      params.spinner = null;
    }
    if (options.transparent) {
      params.translucent = true;
    }
    if (options.backdropDismiss) {
      params.backdropDismiss = true;
    }
    if (options.buttons) {
      params.buttons = options.buttons;
    }
    const loading = await loadingController.create(params);

    // shows 'taking-longer' message after 5-secs
    if (onDelay) {
      const oldPresent = loading.present;
      const oldDismiss = loading.dismiss;
      loading.present = async function () {
        const self = this;
        oldPresent.call(loading);
        loading.dismiss = function () {
          oldDismiss.call(loading);
          self.dismissed = true;
        };
        params.message = onDelay;
        const tempLoading = await loadingController.create(params);

        setTimeout(() => {
          if (!self.dismissed) {
            if (!skipShow) {
              loading.dismiss();
              tempLoading.present();
            }
            loading.dismiss = () => tempLoading.dismiss.call(tempLoading);
          }
        }, 5000);
      };
    }
    if (!skipShow) {
      loading.present();
    }
    return loading;
  };

  const toast = (options = {}, color = "dark", skipShow = false) => {
    // skips showing toast on puppet
    if (isPuppeteer())
      return new Promise((resolve) =>
        resolve(console.log("toast called: ", options))
      );

    color = color === "error" ? "danger" : color;
    let params = {
      duration: 2000,
      color,
      position: isPlatform("desktop") ? "top" : "bottom", //can be overridden by passing in options
    };
    // if a 'dismiss' button is passed, auto-dismiss will not work if 'duration' is passed too
    const cantDismiss =
      options?.buttons?.find((item) => item.role === "cancel") &&
      options.duration === undefined;
    if (cantDismiss) {
      delete params.duration;
    }
    if (typeof options === "string") {
      params.message = options;
    } else {
      const msg = options.messge || options.msg || "Oops";
      params = { ...params, message: msg, ...options };
    }
    return toastController.create(params).then((toast) => {
      if (!skipShow) {
        toast.present();
      }
      return toast;
    });
  };

  return { loader, toast, alert, confirm };
}
