const PUSH_CONFIG = {
  ONE_SIGNAL: {
    APP_ID: "a6a37619-48af-455c-a1fe-033d34c9561e",
    WEB_CONFIG: {
      appId: "a6a37619-48af-455c-a1fe-033d34c9561e",
      safari_web_id: "web.onesignal.auto.4ddec2dc-5c48-40c7-bde8-da7159bee241",
      notifyButton: {
        enable: false,// shows bell at bottom-right
      },
      subdomainName: "bazm-dev",
    }
  },
  ANDROID_PROJECT_NUMBER: 'giriyazaari-001',
  GCM: {
    gcm_id: '877940299872',
    app_id: "a2b180a4",
    api_key: "5c6ca5d0e567cb7100a080411e0b8af0b060ccd929662c3e"
  },
  FIREBASE: {
    "sender_id": "316123844372",//sender ID for Giriyazaari in Firebase console
    "pluginConfig": {
      "ios": {
        "badge": true,
        "sound": true,
        "alert": true
      },
      "android": {
        "iconColor": "#343434"
      }
    }
  },
  VAPID_PUBLIC_KEY: "BLWerZUGYWeedJ8EgGv4mM6kCPyOC_uwKgIoort6wBJkVCXwNrgIcB1Qj5hXYTy9K_c-M_k9LYziwemAMt89YJY"
};
export {PUSH_CONFIG};