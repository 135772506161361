<template>
  <bare-modal
    css-class="media-modal"
    ref="modal"
    :title="modalTitle"
    :show-close-icon="icons.chevronDown"
    :breakpoints="[0, 1]"
    @closed="onClosed"
  >
    <ion-content ref="ionContent">
      <template v-if="isVideo">
        <div
          id="video-player"
          v-track-this="{
            category: TRK.category.video,
            event: 'click',
            label: TRK.maxBtn,
          }"
          @click="maximizeWindow"
        >
          <media-youtube-player ref="ytPlayer" />
        </div>

        <div class="content">
          <h4 class="padding" style="margin-top: 0">
            {{ track.title }}
          </h4>
          <div
            v-if="!suggestions.length && loading"
            class="ion-padding ion-text-center"
          >
            <ion-spinner icon="android" />
          </div>
          <div v-if="suggestions.length && !loading">
            <ion-item-divider color="light" class="ion-text-uppercase">
              <ion-label> more suggestions </ion-label>
            </ion-item-divider>
            <div class="list suggestions">
              <ion-item
                v-for="(item, $index) in suggestions"
                :key="$index"
                v-track-this="{
                  category: TRK.category.video,
                  event: 'click',
                  label: TRK.videoSuggestion + ': ' + item.title,
                }"
                @click="switchVideo(item)"
              >
                <ion-thumbnail slot="start">
                  <img
                    :src="
                      item.image ||
                      'https://i3.ytimg.com/vi/' +
                        item.videoId +
                        '/sddefault.jpg'
                    "
                  />
                </ion-thumbnail>
                <ion-label class="ion-text-wrap">
                  <h2>{{ item.title }}</h2>
                  <div>{{ item.author.name }} | {{ item.ago }}</div>
                  <div>{{ item.duration.timestamp }}</div>
                </ion-label>
              </ion-item>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <nauhe-list
          :list="suggestions"
          :config="{ canFavorite: false, canPlay: false }"
          @clicked="($event) => $emit('track-change', $event)"
        />
      </template>
    </ion-content>
    <ion-footer>
      <slot name="mediaPlayer" />
    </ion-footer>
  </bare-modal>
</template>
<script>
import { MEDIA_PLAYER } from "@/constants/ga-tracking/components";
import { useTracker } from "@/composables";
import { IonThumbnail, IonItemDivider } from "@ionic/vue";
import {
  chevronDown,
  closeOutline,
  play,
  pause,
  reloadOutline,
  playSkipBack,
  playSkipForward,
  expandOutline,
} from "ionicons/icons";

import BareModal from "@/components/BareModal.vue";
import NauheList from "@/components/Media/NauheList.vue";
import MediaYoutubePlayer from "./MediaYoutubePlayer.vue";

import { $media } from "@/apis";

export default {
  components: {
    BareModal,
    IonItemDivider,
    IonThumbnail,
    NauheList,
    MediaYoutubePlayer,
  },
  emits: ["track-change"],
  data() {
    return {
      suggestions: [],
      loading: false,
      track: {},
    };
  },
  computed: {
    modalTitle() {
      const { track } = this;
      return track.isAudio ? "Now playing" : "Video";
    },
    isVideo() {
      const { track } = this;
      return !track.isAudio || track.type === "video";
    },
  },
  methods: {
    onClosed() {
      const { Analytics, TRK } = this;
      Analytics("event", {
        category: TRK.category.video,
        event: "click",
        label: TRK.closeBtn + "top",
      });
      if (this.isVideo) {
        this.$refs.ytPlayer.stopVideo();
      }
    },
    maximizeWindow(e, track, list) {
      this.$refs.modal.openModal(e);
      this.track = track;

      if (list) {
        this.suggestions = [...list];
      } else if (this.isVideo) {
        this.fetchSuggestions();
        // because it takes sometime for modal to open
        setTimeout(() => this.switchVideo(track), 100);
      }
    },
    minimizeWindow() {
      return this.closeWindow();
    },
    closeWindow() {
      return this.$refs.modal.closeModal();
    },
    switchVideo(item) {
      this.track = { ...item };
      this.$refs.ionContent.$el.scrollToTop();
      this.$refs.ytPlayer.initVideo(item.videoId);
    },
    fetchSuggestions() {
      this.loading = true;
      this.suggestions = [];
      const {
        Analytics,
        TRK: { api },
      } = this;
      $media.tracks
        .fetchSuggestions("majalis", { type: "video", basedOn: this.track })
        .then((result) => {
          const { success, list } = result;
          if (success) {
            this.suggestions = list;
          }
        })
        .catch((err) => {
          const { data } = err?.data ? err : { data: err };
          console.warn("err: ", data);
          Analytics("error", {
            label:
              api.videoSuggestions.error +
              (data.message || data.msg || "Unknown error"),
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  setup() {
    const { Analytics } = useTracker();
    return {
      TRK: MEDIA_PLAYER,
      Analytics,
      icons: {
        chevronDown,
        closeOutline,
        play,
        pause,
        reloadOutline,
        playSkipBack,
        playSkipForward,
        expandOutline,
      },
    };
  },
};
</script>

<style lang="scss">
.media-modal {
  .media-player {
    position: unset;
  }

  ion-thumbnail {
    --size: 140px;
    --border-radius: 5px;
  }
}
</style>
