<template>
  <ion-card v-if="canShowBanner" class="app-install-banner">
    <ion-card-content>
      <ion-item lines="none">
        <ion-avatar slot="end">
          <ion-img :src="`assets/icons/icon-round.png`" />
        </ion-avatar>
        <div class="ion-text-wrap">
          <strong v-html="$t('banners.appInstall.card.heading')" />
          <p v-html="cardText" />

          <div class="mt-8">
            <!-- order of CTAs is different for desktop vs mdot -->
            <ion-button
              fill="solid"
              v-html="$t('banners.appInstall.card.cta.action')"
              @click="$refs.installPopover.showPrompt()"
            />
            <ion-button
              v-html="skipLabel"
              fill="clear"
              color="dark"
              @click="hideBanner"
            />
          </div>
        </div>
      </ion-item>
    </ion-card-content>
    <app-install-popover ref="installPopover" />
  </ion-card>
</template>

<script>
import { inject } from "vue";
import { isPlatform, IonAvatar } from "@ionic/vue";
import { shareOutline, close } from "ionicons/icons";
import { usePrompts, useTracker } from "@/composables";
import $storage from "@/utils/storage";
import notificationMixin from "@/mixins/notifications";
import AppInstallPopover from "./AppInstallPopover.vue";

export default {
  name: "app-install-banner",
  components: { IonAvatar, AppInstallPopover },
  mixins: [notificationMixin],
  props: {
    place: {
      type: String,
      default: "menu",
    },
    mode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBannerDismissed: $storage.$session.get("hide_aib"), // hide_appInstallBanner
      hasWebInstallPrompt: null,
      appUrl: window.location.origin,
      TRK_CATEGORY: "PWA_INSTALL",
    };
  },
  computed: {
    cardText() {
      if (isPlatform("ios") && !this.isSafari) {
        return this.$t("banners.appInstall.nonSafari.desc");
      }
      const version = isPlatform("android") ? 1 : 0;
      return this.$t("banners.appInstall.card.desc", version);
    },
    skipLabel() {
      const { $t, isSafari } = this;
      if (isPlatform("ios") && !isSafari) {
        return $t("banners.appInstall.nonSafari.skipCta");
      }
      return $t("banners.appInstall.card.cta.skip");
    },
    isPlatformSupported() {
      if (this.isPWA) return false;
      return isPlatform("desktop") ? !isPlatform("ios") : true;
    },
    canShowBanner() {
      const {
        isBannerDismissed,
        androidInstalled,
        pwaInstalled,
        isPlatformSupported,
        isMobileSize,
      } = this;
      const {
        $route: { name: routeName },
      } = this;

      if (
        isBannerDismissed ||
        androidInstalled ||
        pwaInstalled ||
        routeName === "onboardingPage" ||
        (isMobileSize && !isPlatform("desktop"))
      )
        return false;
      return isPlatformSupported;
    },
    appUrlOnStore() {
      return window._ && window._.app && window._.app.playStoreUrl;
    },
  },
  methods: {
    hideBanner() {
      const { Analytics, TRK_CATEGORY } = this;
      $storage.$session.set("hide_aib", 1);
      this.isBannerDismissed = true;
      this.toast(this.$t("common.untilNextTime"));
      // while hiding banner, set prompt to be displayed later if user continues to use app for longer

      // prompt on desktop only
      // this.promptPwaInstallAfterUsage();
      Analytics("event", {
        action: "click",
        category: TRK_CATEGORY + " - Desktop",
        label: "SkipCTA",
      });
    },
    promptPwaInstallAfterUsage() {
      const PROMPT_AFTER_SEC = 60 * 1000; // prompt after 60s of usage
      setTimeout(() => {
        this.pwaPrompted = $storage.$session.get("aip"); // app_install_prompt
        if (!this.pwaPrompted) {
          $storage.$session.set("aip", 1); // app_install_prompt
          if (!this.pwaInstalled) {
            const desc = this.$t("banners.appInstall.card.desc", 1);
            const heading = this.$t("banners.appInstall.prompt.heading");
            this.confirm(desc, { heading })
              .then(this.promptForWebInstall)
              .catch(() => {
                const deniedTimes = $storage.get("pwa.install.denied", 0);
                const { Analytics, TRK_CATEGORY: category } = this;
                Analytics("event", {
                  category,
                  label: "prompt_denied",
                  value: deniedTimes,
                });
                $storage.set("pwa.install.denied", deniedTimes + 1);
              });
          }
        }
      }, PROMPT_AFTER_SEC);
    },
  },
  mounted() {
    this.pwaSetInstallPrompt();
  },
  setup() {
    const { toast, confirm } = usePrompts();
    const isMobileSize = inject("isMobileSize");
    const { Analytics } = useTracker();

    return {
      toast,
      confirm,
      icons: { shareOutline, close },
      isPlatform,
      isMobileSize,
      Analytics,
    };
  },
};
</script>

<style lang="scss">
.app-install-banner {
  z-index: 10;
  @media screen and (max-width: 576px) {
    &:before {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: " ";
      background: rgba(0, 0, 0, 0.5);
    }
  }
  ion-card-content {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 12px;
    padding-bottom: 4px;
    background: white;
    @media (prefers-color-scheme: dark) {
      background: var(--ion-item-background);
    }
    ion-item {
      align-items: center;
      @media (max-width: 560px) {
        &:first-child {
          --min-height: 30px;
          ion-img {
            margin-inline-end: 8px;
            width: 24px;
          }
          ion-label {
            margin: 0;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    position: fixed;
    bottom: 56px;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid $white_f3f3f3;
    // iphone
    @media (-webkit-min-device-pixel-ratio: 2) {
      bottom: 50px;
    }
  }
}
</style>
