import moment from "moment";

export function useFormatters() {
  const newlineToBreak = (str) => str?.replace(/\n/gim, "<br />") || "";

  const textToSmiley = (str) => {
    const smileyMap = {
      "<3": "\u2764\uFE0F",
      "</3": "\uD83D\uDC94",
      ":D": "\uD83D\uDE00",
      ":)": "\uD83D\uDE03",
      ";)": "\uD83D\uDE09",
      ":(": "\uD83D\uDE12",
      ":p": "\uD83D\uDE1B",
      ":P": "\uD83D\uDE1B",
      ";p": "\uD83D\uDE1C",
      ";P": "\uD83D\uDE1C",
      ":'(": "\uD83D\uDE22",
    };
    let text = str || "";
    Object.keys(smileyMap).forEach((smiley) => {
      text = text
        .split(smiley)
        .join(smileyMap[smiley] ? smileyMap[smiley] : "");
    });
    return text;
  };

  const makeBold = (str) => {
    return (str || "").replaceAll(
      /\*[^*]*\*/gim,
      (match) => "<b>" + match.replaceAll("*", "") + "</b>"
    );
  };

  const makeItalic = (str) => {
    return (str || "").replace(
      /_[\w0-9\W\s?]+_/gi,
      (match) => '<span class="italic">' + match.replace(/_/g, "") + "</span>"
    );
  };

  const markHashtags = (str) => {
    return (str || "").replace(
      /#(\w+)/gi,
      (match) => '<span color="primary">' + match + "</span>"
    );
  };

  const wrapLinksInAnchor = (str) => {
    let replacedText;
    const inputText = str;

    //URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    // '<a href="$1" target="_system" rel="nofollow">$1</a>'

    const matches1 = inputText.match(replacePattern1);
    replacedText = inputText;
    matches1?.map((url) => {
      const regex = new RegExp(url, "g");
      const attrs = url.includes(window.location.host)
        ? ""
        : 'target="_blank" rel="nofollow"';
      replacedText = replacedText.replace(
        regex,
        `<a href="${url}" ${attrs}>${url}</a>`
      );
    });

    //URLs starting with "www." (without // before it, or it would re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a href="http://$2" target="_blank" rel="nofollow">$2</a>'
    );

    //Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(
      replacePattern3,
      '<a href="mailto:$1" target="_system" rel="nofollow">$1</a>'
    );

    return replacedText;
  };
  const isDarkTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const decorateText = (str) => {
    let text = str || "";
    if (!text) return "";
    text = newlineToBreak(text);
    text = wrapLinksInAnchor(text);
    text = markHashtags(text);
    text = makeItalic(text);
    text = makeBold(text);
    text = textToSmiley(text);
    return text;
  };

  const formatDate = (date, useFormat, relative) => {
    if (!date) return "";
    const postTime = new Date(date).getTime();
    const diff = Date.now() - postTime;
    const day = 24 * 3600 * 1000;
    const week = day * 7;
    if (useFormat && !relative) {
      return moment(date).format(useFormat);
    }
    const labels = [];
    if (diff <= 2 * week) {
      if (diff <= 2 * day) {
        labels.push(
          diff <= day ? moment(date).fromNow() : moment(date).calendar()
        );
      } else {
        const format = diff <= week ? "dddd, hh:mm a" : "dddd";
        labels.push(moment(date).format(useFormat || format));
      }
      if (diff > week) {
        labels.push("Last week");
      }
    } else {
      labels.push(moment(date).format("ddd, MMM DD, YYYY"));
    }
    return labels.join(", ");
  };

  return {
    newlineToBreak,
    textToSmiley,
    makeBold,
    makeItalic,
    markHashtags,
    wrapLinksInAnchor,
    decorateText,
    isDarkTheme,
    formatDate,
  };
}
