<template>
  <ion-modal
    ref="modal"
    v-bind="passedAttrs"
    :is-open="isOpenRef"
    :presenting-element="parentEl"
    @didPresent="$emit('opened')"
    @willDismiss="$emit('dismiss')"
    @didDismiss="($event) => (sentCloseEvent ? close() : dismissModal($event))"
  >
    <modal-nav-bar
      v-if="title"
      :title="title"
      :title-mode="$attrs['title-mode']"
      :close-icon="showCloseIcon"
      :back-icon="showBackIcon"
      :color="$attrs.color"
      :alt-color="$attrs['alt-color'] || $attrs.altColor"
      @navbar-close="dismissModal('navbar')"
      @navbar-back="$emit('back')"
    >
      <template v-if="$slots.endBtn" #endBtn>
        <slot name="endBtn" />
      </template>
      <slot v-if="$slots.navbar" name="navbar" v-bind="$attrs" />
    </modal-nav-bar>
    <!-- use named slot 'content' for full-height cards. -->
    <!-- otherwise, shows regular modals (with ion-page) -->
    <template v-if="$slots.content">
      <div class="content-only">
        <slot name="content" />
      </div>
      <slot v-if="$slots.footer" name="footer" />
    </template>
    <slot v-else />
  </ion-modal>
</template>

<script>
import { ref } from "vue";
import { IonModal } from "@ionic/vue";

export default {
  components: { IonModal },
  emits: ["opened", "closed", "dismiss", "back"],
  props: {
    title: {
      type: String,
      default: null,
    },
    showCloseIcon: {
      type: [Boolean, String], // allows passing icons too
      default: false,
    },
    showBackIcon: {
      type: [Boolean, String], // allows passing icons too
      default: false,
    },
  },
  data() {
    return {
      parentEl: null,
      sentCloseEvent: null,
    };
  },
  computed: {
    passedAttrs() {
      const { $attrs, $slots } = this;
      const params = { ...$attrs };
      if (!params.presentingElement) delete params.presentingElement;
      if ($slots.content) {
        params.cssClass = (params.class || "") + " auto-height";
        delete params.class;
      }
      return params;
    },
  },
  methods: {
    open(ev) {
      this.openModal(ev);
    },
    async openModal(ev) {
      const { passedAttrs } = this;
      if (
        ev &&
        !(passedAttrs["breakpoints"] && passedAttrs["initial-breakpoint"])
      ) {
        const target = ev?.target || ev;
        const parent =
          target.closest(".show-modal") || target.closest(".ion-page");
        // console.log("target: ", parent);
        this.parentEl = parent;
      }
      return new Promise((resolve) => {
        this.$nextTick(() => {
          this.setModalState(true);
          setTimeout(resolve, 500);
        });
      });
    },
    dismissModal(src) {
      this.sentCloseEvent = true;
      this.$emit("closed", typeof src === "string" ? src : null);
      setTimeout(() => this.closeModal(), 50); // so that 'closed' event is handled well by parent
    },
  },
  setup() {
    const isOpenRef = ref(false);
    const setModalState = (state) => (isOpenRef.value = state);
    const closeModal = () =>
      new Promise((resolve) => {
        setModalState(false);
        setTimeout(resolve, 200);
      }); // this doesn't emit any events
    const close = () => closeModal();

    return { isOpenRef, setModalState, closeModal, close };
  },
};
</script>

<style lang="scss">
// class set at ion-modal level using passedAttrs
ion-modal.auto-height {
  --height: auto;
  .content-only {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }
}
</style>
