import { createI18n, useI18n } from "vue-i18n";
import { getJsonFromUrl } from "@/utils/fns";
// import { $language } from "@/apis";

let i18n;
const { language, browserLanguage, systemLanguage, userLanguage } = navigator;
const DEF_CONFIG = {
  legacy: false,
  locale: // $language.getSelectedLang().code ||// was causing issue in fallback to EN, if other language didn't load in time
  (language || browserLanguage || systemLanguage || userLanguage)
    .split("-")
    .shift(),
  fallbackLocale: "en", // set fallback locale
  globalInjection: true, // make $t available globally: https://vue-i18n.intlify.dev/guide/advanced/composition.html#explicit-with-usei18n
};

export function setupI18n(options = DEF_CONFIG) {
  i18n = createI18n(options);
  setI18nLanguage(options.locale);
  return i18n;
}

export async function setI18nLanguage(locale, isRtl) {
  console.log("setting i18n language to: %s, isRtl: ", locale, isRtl);
  try {
    await loadLocaleMessages(locale);
    if (i18n.mode === "legacy") {
      i18n.global.locale = locale;
    } else {
      i18n.global.locale.value = locale;
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector("html").setAttribute("lang", locale);
    if (isRtl) {
      document.querySelector("html").setAttribute("dir", "rtl");
    } else {
      document.querySelector("html").removeAttribute("dir");
    }
  } catch (e) {
    console.log("Failed to setI18nLanguage: ", e);
    return e;
  }
}

export async function loadLocaleMessages(locale) {
  // load locale messages with dynamic import
  let messages;
  if (locale === "en") {
    messages = await import(
      /* webpackChunkName: "locale-[request]" */ `../public/assets/translations.json`
    );
  } else {
    const {
      global: {
        messages: { value },
      },
    } = i18n;
    // if cached already
    if (Object.keys(value[locale] || {}).length) {
      // console.log('has messages for locale :', locale, Object.keys(value[locale]));
      messages = value;
    } else {
      try {
        // for chinese languages
        if (locale.match(/-(cn|tw)$/)) {
          locale = locale.split("-");
          locale[1] = locale[1].toUpperCase();
          locale = locale.join("-");
        }
        messages = await getJsonFromUrl(
          `https://raw.githubusercontent.com/bazm-apps/translations/main/${locale}.json`
        );
      } catch (e) {
        console.warn("Failed to load translation: ", e);
        throw {
          message: "Failed to load translation file.",
          error: e,
        };
      }
    }
  }

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages[locale]);

  return messages;
}

export function useTranslation() {
  const { t, locale } = useI18n();
  return { t, locale };
}
