<template>
  <ion-card v-if="canShowBanner" class="web-banner">
    <ion-card-content>
      <h2>
        <strong>{{ $t("banners.webFeedback.heading") }}</strong>
      </h2>
      <div v-html="$t('banners.webFeedback.text')" />
      <ion-button
        fill="outline"
        size="small"
        class="cta"
        target="_blank"
        :href="googleFormUrl"
        v-track-this="{
          event: 'click',
          category: 'FeedbackDesktop',
          label: 'open_form',
        }"
      >
        {{ $t("banners.webFeedback.cta") }}
      </ion-button>
      <ion-button
        fill="clear"
        size="small"
        color="dark"
        @click="dismiss"
        v-track-this="{
          event: 'click',
          category: 'FeedbackDesktop',
          label: 'dismiss_cta',
        }"
      >
        {{ $t("banners.webFeedback.dismiss") }}
      </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, isPlatform } from "@ionic/vue";
import { usePrompts } from "@/composables";
import $storage from "@/utils/storage";

export default {
  components: {
    IonCard,
    IonCardContent,
  },
  data() {
    return {
      dismissed: $storage.$session.get("dismiss.wf", 0),
      timedOut: false,
      googleFormUrl:
        "https://docs.google.com/forms/d/e/1FAIpQLSfPXdquHaQncOS4HwtNVLp7hX58r7cHjYnqmjcFylkKPkI6EQ/viewform?usp=pp_url",
    };
  },
  computed: {
    canShowBanner() {
      return !this.dismissed && isPlatform("desktop") && this.timedOut;
    },
  },
  methods: {
    dismiss() {
      $storage.$session.set("dismiss.wf", 1); // dismiss.webFeedback
      this.dismissed = true;
      this.toast(this.$t("common.untilNextTime"));
    },
  },
  mounted() {
    if (!this.dismissed) {
      const DELAY_SEC = 30 * 1000; // display banner after 30 sec
      console.log(
        "WebFeedback banner will appear in %d seconds",
        DELAY_SEC / 1000
      );
      setTimeout(() => {
        this.timedOut = true;
      }, DELAY_SEC);
    }
  },
  setup() {
    const { toast } = usePrompts();
    return { toast };
  },
};
</script>

<style lang="scss">
.web-banner {
  --background: #{$offWhite};
  @media (prefers-color-scheme: dark) {
    --background: initial;
  }
  box-shadow: none;
  ion-card-content {
    color: var(--ion-color-dark);
    border: 1px solid $washed-out;
    @media (prefers-color-scheme: dark) {
      border: 1px solid var(--ion-color-step-450);
    }
    @include curved(4px);
    overflow: hidden;
    > div {
      margin: 8px 0;
    }
    .cta {
      margin-right: 4px;
    }
  }
}
</style>
