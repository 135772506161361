import { STRIPE } from "@/constants/payments";

let $stripe;
export function usePayments() {
  const init = () => {
    console.log("Initializing Stripe SDK");
    return new Promise((resolve, reject) => {
      const hasStripe = document.getElementById("stripejs");
      const KEY = STRIPE.keys[process.env.NODE_ENV];
      if (!hasStripe) {
        const script = document.createElement("script");
        script.setAttribute("src", "https://js.stripe.com/v3/");
        script.id = "stripejs";
        script.onload = () => {
          $stripe = window.Stripe(KEY);
          console.info("Stripe SDK loaded", $stripe);
          resolve($stripe);
        };
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      } else if (typeof window.Stripe !== "undefined") {
        $stripe = $stripe || window.Stripe(KEY);
        console.info("Stripe SDK loaded", $stripe);
        return resolve($stripe);
      } else {
        const timer = setInterval(() => {
          let sdkAvailable = typeof window.Stripe !== "undefined";
          if (sdkAvailable) {
            clearInterval(timer);
            console.log("Payment SDK initialized");
            $stripe = $stripe || window.Stripe(KEY);
            resolve($stripe);
          }
        }, 1000);
      }
    });
  };

  const getStripe = () => $stripe;

  const checkPaymentStatus = (clientSecret) => {
    return init().then(async () => {
      if (!clientSecret) {
        return;
      }
      const { paymentIntent } = await $stripe.retrievePaymentIntent(
        clientSecret
      );
      const result = {
        ...paymentIntent,
      };
      switch (paymentIntent.status) {
        case "succeeded":
          result.paymentStatus = "success";
          break;
        case "processing":
          result.paymentStatus = "processing";
          break;
        case "requires_payment_method":
          result.paymentStatus = "pending";
          break;
        default:
          result.paymentStatus = "unknown";
          break;
      }
      return result;
    });
  };

  return {
    init,
    getStripe,
    checkPaymentStatus,
  };
}
