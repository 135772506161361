<template>
  <ion-modal
    :is-open="isOpenRef"
    :breakpoints="[0.5, 1]"
    :initialBreakpoint="0.5"
    css-class="utll-modal"
    @didDismiss="setModalState(false)"
  >
    <ion-page>
      <ion-content :fullscreen="true">
        <ion-item
          v-for="(lang, index) in accessList"
          :key="index"
          @click="onLangClick(lang)"
        >
          <ion-avatar slot="start">
            <ion-img
              v-text-img="{
                userName:
                  lang.lang_code === '*' ? 'All' : langName(lang.lang_code),
                size: 3,
              }"
            />
          </ion-avatar>
          <ion-label>
            <ion-text color="primary">
              <h2>
                {{ langName(lang.lang_code) }}
              </h2>
            </ion-text>
            <ion-text>
              <p>
                {{
                  lang.updated
                    ? "You have access since " + formatDate(lang.updated)
                    : "Added on " + formatDate(lang.added)
                }}
              </p>
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-content>
    </ion-page>
  </ion-modal>
</template>

<script>
import { ref } from "vue";
import { IonAvatar, IonImg, IonText, IonModal } from "@ionic/vue";
import { useFormatters, getUser } from "@/composables";
import { getFlagUrl } from "@/utils/fns";
import $storage from "@/utils/storage";

export default {
  name: "UserTransLangsListModal",
  components: { IonModal, IonAvatar, IonImg, IonText },
  emits: ["lang-selected"],
  computed: {
    accessList() {
      const {
        translate: { languages },
      } = this.currentUser();
      if (!languages) return [];

      const prevChoices = $storage.get("transLangChoices", []);
      if (!prevChoices.length) return languages;
      const list = [];
      prevChoices.map((langCode) => {
        const index = languages.findIndex(
          (lang) => lang.lang_code === langCode
        );
        const [lang] = languages.splice(index, 1); // splice returns array
        list.push(lang);
      });
      return [...list, ...languages];
    },
    languages() {
      return this.$moamin?.languages;
    },
    langName() {
      const { languages } = this;
      return (langCode) =>
        langCode === "*"
          ? "All"
          : languages.find((lang) => lang.code === langCode)?.label;
    },
  },
  methods: {
    onLangClick(lang) {
      this.setModalState(false);
      const { lang_code } = lang;
      if (lang_code !== "*") {
        let transLangChoices = $storage.get("transLangChoices", []);
        const index = transLangChoices.indexOf(lang_code);
        if (index >= 0) {
          transLangChoices.splice(index, 1);
        }
        transLangChoices = [lang_code, ...transLangChoices];
        $storage.set("transLangChoices", transLangChoices);
        return this.$emit("lang-selected", lang_code);
      }
      this.$emit("lang-selected");
    },
  },
  setup() {
    const isOpenRef = ref(false);
    const setModalState = (state) => (isOpenRef.value = state);
    const openModal = () => setModalState(true);
    const { formatDate } = useFormatters();
    const { currentUser } = getUser();

    return {
      isOpenRef,
      setModalState,
      openModal,
      formatDate,
      currentUser,
      getFlagUrl,
    };
  },
};
</script>

<style lang="scss" scoped></style>
