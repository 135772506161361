const LOC_TYPES = Object.freeze({
  any: { filters: [], zoom: 18 }, //*leave blank
  rooftop: { filters: ["street_address", "place"], zoom: 18 },
  road: { filters: ["route"], zoom: 17 },
  locality: {
    filters: ["political", "sublocality", "sublocality_level_1", "locality"],
    zoom: 16,
  },
  postal: { filters: ["postal_code"], zoom: 15 },
  neighbor: { filters: ["neighborhood", "political"], zoom: 14 },
  sublocality: {
    filters: ["political", "sublocality", "sublocality_level_2"],
    zoom: 12,
  },
  city: { filters: ["local", "political", "district"], zoom: 11 },
  state: {
    filters: ["administrative_area_level_1", "political", "region"],
    zoom: 10,
  },
  country: { filters: ["country", "political"], zoom: 8 },
});

const MAPBOX_PUBLIC_TOKEN =
  "pk.eyJ1IjoiZXRlcm5hbHNheWVkIiwiYSI6ImNrbWcwazhkajA0bjIzMG1vamR2a3M2eWoifQ.63VLfMMdYtVZ3FktNsnnzw";
const MAPBOX_SECRET_TOKEN =
  "sk.eyJ1IjoiZXRlcm5hbHNheWVkIiwiYSI6ImNrbWcwdGdwajNha3Uybm53YWI0aXpxZmcifQ.Mgl9Njlh3qh0nf70FV3WFA";
const MAPBOX_TILE_URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${MAPBOX_SECRET_TOKEN}`;

const GOOGLE = Object.freeze({
  // from: https://console.cloud.google.com/google/maps-apis/credentials?project=giriyazaari-001
  API_KEY: "AIzaSyC-qHoMceNyAT4nmNPbzcLITYS7ZifezrM",
});

export {
  LOC_TYPES,
  MAPBOX_TILE_URL,
  MAPBOX_PUBLIC_TOKEN,
  MAPBOX_SECRET_TOKEN,
  GOOGLE,
};
