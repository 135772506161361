<template>
  <div :class="{ 'media-player container': true, minified }" ref="mediaPlayer">
    <audio-media-player
      v-if="track"
      :currentTime="currentTime"
      :hasList="trackList.length > 1"
      :disableNext="isDisabled('next')"
      :disablePlay="isDisabled('play')"
      :disablePrev="isDisabled('prev')"
      @seek-audio="($event) => (currentTime = $event)"
      @play-next="($event) => playSibling('next', $event)"
      @play-prev="($event) => playSibling('prev', $event)"
      @toggle-play="togglePlay"
      @toggle-minify="($e) => (minified = $e)"
      @close-player="closeAudioPlayer"
      :audio="audio"
      :trackLabel="track[labelKey]"
      :isPlaying="playing"
      :isLoading="loading"
      @open-modal="
        ($event) => $refs.modal.maximizeWindow($event, track, trackList)
      "
    />
    <!-- within div, because root element is required -->
    <media-player-modal ref="modal">
      <template v-if="!isVideo" #mediaPlayer>
        <!-- excess div with class for styling to work properly in modal -->
        <div class="media-player">
          <audio-media-player
            :currentTime="currentTime"
            :disableNext="isDisabled('next')"
            :disablePlay="isDisabled('play')"
            :disablePrev="isDisabled('prev')"
            @seek-audio="($event) => (currentTime = $event)"
            @play-next="($event) => playSibling('next', $event)"
            @play-prev="($event) => playSibling('prev', $event)"
            @toggle-play="togglePlay"
            @close-player="
              $refs.modal.minimizeWindow().then(() => closeAudioPlayer())
            "
            :audio="audio"
            :trackLabel="track[labelKey]"
            :isPlaying="playing"
            :isLoading="loading"
            :canMinify="false"
          />
        </div>
      </template>
    </media-player-modal>
  </div>
</template>

<script>
import { MEDIA_PLAYER } from "@/constants/ga-tracking/components";
import { usePrompts, AudioTrack } from "@/composables";
import MediaPlayerModal from "./MediaPlayerModal.vue";
import AudioMediaPlayer from "./AudioMediaPlayer.vue";

export default {
  name: "media-player",
  components: {
    MediaPlayerModal,
    AudioMediaPlayer,
  },
  emits: ["player-closed", "play-audio"],
  data() {
    return {
      loading: false,
      playing: false,
      reload: false,
      currentTime: 0,
      audio: {
        currentTime: 0,
        duration: 0,
        errored: false,
      },
      currentAudio: {},
      track: null,
      currentId: null,
      labelKey: null,
      trackList: [],
      minified: 0,
    };
  },
  computed: {
    isVideo() {
      if (this.track) {
        return !this.track.isAudio;
      }
      return false;
    },
  },
  watch: {
    currentTime(time) {
      const isTimeMoved = Math.abs(time - this.currentAudio.currentTime) > 0.5;
      if (isTimeMoved) {
        console.log("timemoved: ", time);
        this.currentAudio.currentTime = time;
      }
    },
  },
  methods: {
    isDisabled(btn) {
      const { track, trackList, currentAudio, audio } = this;
      switch (btn) {
        case "play":
          return (
            !Boolean(track) ||
            !currentAudio?.src ||
            audio.errored ||
            this.loading
          );
        case "prev":
          if (!trackList.length) return true;
          const current = trackList.findIndex(
            (t) => t.trackId === track.trackId
          );
          // console.log("current: ", current);
          return current === 0 || !trackList.length;
        case "next":
          if (!trackList.length) return true;
          const i = trackList.findIndex((t) => t.trackId === track.trackId);
          // console.log("next: ", i, trackList.length);
          return i === trackList.length - 1 || !trackList.length;
      }
      return true;
    },
    closeAudioPlayer() {
      const PAUSE = true;
      this.togglePlay(PAUSE);
      this.$emit("player-closed");
    },
    playSibling(which, e) {
      const {
        trackList,
        track: { trackId },
      } = this;
      const index = trackList.findIndex((t) => t.trackId === trackId);
      const next = which === "next" ? index + 1 : index - 1;
      const track = trackList[next];
      console.log("%s track", which, track);
      if (track) {
        this.startAudioTrack(track, e, true);
      }
    },
    onTimeUpdate() {
      const time = Math.round(this.currentAudio.currentTime);
      if (time !== this.currentTime)
        // console.log("timeupdate: ", this.currentAudio.currentTime);
        this.currentTime = time;
      this.showMediaBufferProgress(); // show media buffer progress
    },
    onAudioError(e) {
      console.warn("Error in playing track:", e);
      this.loading = false;
      this.audio.errored = true;
      this.toast(this.$t("comps.mediaPlayer.jsTrackLoadFailed"), "error");
    },
    onAudioEnd() {
      console.warn("Audio ended");
      this.audio.currentTime = 0;
      const repeat = parseInt(this.repeat || null);
      if (repeat) {
        if (repeat === 1) {
          this.togglePlay();
          return;
        } else {
          // repeat all
          const {
            track: { trackId },
            trackList,
          } = this;
          const index = trackList.findIndex((t) => t.trackId === trackId);
          const next = index === trackList.length - 1 ? 0 : index + 1;
          const track = trackList[next];
          this.startAudioTrack(track, true);
        }
      }
    },
    startAudioTrack(track, userClicked, fromList) {
      this.track = {
        ...track,
        isAudio: true,
      };
      let audioId;
      if (track.audios) {
        const index = track.selected || 0;
        audioId = track.audios[index];
      } else {
        audioId = track.trackId;
      }
      track.audioId = audioId;

      if (userClicked) {
        const PAUSE_CURRENT =
          this.currentId === audioId ? this.audio.playing : true;
        this.togglePlay(PAUSE_CURRENT);

        if (this.currentId === audioId) {
          return;
        }
      }
      const { audioTrack } = this;

      /*setTimeout(() => {
        let $title = $(el).find('.track-title');
        $title.removeClass('marquee');
        var overflowX = $title[0].offsetWidth < $title[0].scrollWidth,
          overflowY = $title[0].offsetHeight < $title[0].scrollHeight;

        const isTitleOverflow = (overflowX || overflowY);
        if (isTitleOverflow) {
          $title.addClass('marquee');
        }
      }, 500);*/

      console.log("Loading track now:", track);
      // ---- add to trackList, for playlist
      if (!fromList) {
        const index = this.trackList.find(
          (item) => item.trackId === track.trackId
        );
        if (index >= 0) {
          this.trackList.splice(index, 1);
        }
        this.trackList = [track, ...this.trackList]; // add new track to the top of the list
      }
      // ----------------

      this.labelKey = "trackTitle";
      if (track.trackType) {
        const key = track.trackType.toLowerCase() + "Title";
        if (track[key]) {
          this.labelKey = key;
        }
      }
      document.title = track[this.labelKey] + " | Playing now";

      this.currentId = audioId;
      this.loading = true;

      this.currentAudio = audioTrack.load(this.track, { userClicked: true });

      this.$nextTick(() => {
        const el = this.$refs.track;
        if (el) {
          const buffers = el.querySelectorAll(".progress-buffer:not(.remove)");
          buffers && buffers.forEach((buf) => buf.remove()); // remove any previous progress bars
        }
      });

      this.currentAudio.ontimeupdate = this.onTimeUpdate;
      this.currentAudio.onloadeddata = () => {
        this.audio.duration = this.currentAudio.duration;
        this.loading = false;
        console.log("LoadedData. Now, canPlayThrough");
        if (userClicked) {
          this.togglePlay();
          // this.showControlCenter();
        }
      };
      this.currentAudio.onerror = this.onAudioError;
      this.currentAudio.onended = this.onAudioEnd;
    },
    togglePlay($event) {
      $event && $event.stopPropagation && $event.stopPropagation();
      const pause = $event === true;
      // TODO: fix this (this legacy code won't work with current implementation)
      if (this.isVideo) {
        // this.vplayer.toggle();
        // this.playing = this.vplayer.playing;
        // $rootScope.$broadcast('$mp.video.toggle', Boolean(this.playing));
      } else {
        if (!this.audio.errored && this.audio.duration) {
          !this.currentAudio.paused || pause
            ? this.currentAudio.pause()
            : this.currentAudio.play();
          this.playing = !this.currentAudio.paused;

          /*$musicControl.update({
            elapsed: this.audio.currentTime,
            isPlaying: !this.audio.paused
          }, x => console.warn('update result: ', x), console.error);//Note: iOS specific
          */
        }
      }
      if (!this.playing) {
        /*Audio.canDisplayAd
          ?Audio.displayAdOnce()
          :$ads.admob.setAdForLater('interstitial', Audio);*/
      } else {
        // Audio.canDisplayAd || $ads.admob.setAdForLater('interstitial', Audio);
      }
    },
    showMediaBufferProgress() {
      const { currentAudio, audio } = this;
      if (currentAudio.bufferComplete) return;
      const bufferedMedia = currentAudio.buffered;
      const mp = this.$refs.mediaPlayer;
      if (mp && bufferedMedia && bufferedMedia.length) {
        for (let index = 0; index < bufferedMedia.length; index++) {
          let $buffer = mp.querySelector(".buffer-" + index);
          const existing = !!$buffer;
          if (!existing) {
            $buffer = mp.querySelector(".progress-buffer.hidden").cloneNode();
            $buffer.classList.remove("hidden");
            $buffer.classList.add("id", "buffer-" + index);
          }
          const x1 = bufferedMedia.start(index);
          const x2 = bufferedMedia.end(index);
          const w = parseInt(((x2 - x1) / audio.duration) * 100); // width percent
          const left = (x1 / audio.duration) * 100;
          if (!existing) {
            $buffer.style.left = left.toFixed(2) + "%";
            mp.querySelector(".progress-bg").appendChild($buffer);
          }
          $buffer.style.width = w.toFixed(2) + "%";
          if (parseInt(w) >= 100) {
            this.currentAudio.bufferComplete = true;
          }
        }
      }
    },
  },
  created() {
    this.$events.on("$mediaPlayer.playAudio", (ev) => {
      if (ev.list) {
        this.trackList = [...ev.list];
        delete ev.list;
      }
      const track = { ...ev };
      console.log("track to be played: ", track);
      this.$emit("play-audio");
      this.startAudioTrack(track, true);
    });

    this.$events.on("$mediaPlayer.playVideo", (ev) => {
      this.track = { ...ev };
      console.log("video to be played: ", this.track);
      // this.startAudioTrack(track, true);
      this.$refs.modal.maximizeWindow(this.$el, ev);
    });
  },
  setup() {
    const audioTrack = AudioTrack();
    const { toast } = usePrompts();

    return {
      TRK: MEDIA_PLAYER,
      audioTrack,
      toast,
    };
  },
};
</script>

<style lang="scss">
.media-player {
  background: var(--player-bg);

  z-index: 10;
  position: fixed;
  bottom: 50px;
  html.plt-pwa & {
    bottom: 84px; // fixes position on device. May look misplaced on browser simulator
  }
  width: 100%;
  height: auto;
  transition: max-height 0.5s ease-out;
  @media (min-width: 576px) {
    bottom: 0 !important;
  }
  p {
    margin: 0;
  }
  ion-col {
    min-width: 52px;
  }

  .track-info {
    -webkit-transition: padding-left 0.3s ease-out;
    -moz-transition: padding-left 0.3s ease-out;
    -ms-transition: padding-left 0.3s ease-out;
    -o-transition: padding-left 0.3s ease-out;
    transition: padding-left 0.3s ease-out;
    ion-row {
      position: relative;
      padding: 4px 0;
      .track-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    > ion-button {
      display: none;
      position: absolute;
      left: -100px;
      top: 0;

      -webkit-transition: left 0.3s ease-out;
      -moz-transition: left 0.3s ease-out;
      -ms-transition: left 0.3s ease-out;
      -o-transition: left 0.3s ease-out;
      transition: left 0.3s ease-out;
    }
  }
  .progress {
    position: relative;
    transition: margin 0.3s ease-out;

    .progress-bg {
      min-height: 3px;
      background: #c9c9c9;
      -webkit-transition: width 1s ease-out;
      -moz-transition: width 1s ease-out;
      -ms-transition: width 1s ease-out;
      -o-transition: width 1s ease-out;
      transition: width 1s ease-out;

      .progress-buffer {
        position: absolute;
        left: 0;
        top: 1px;
        min-height: 3px;
        background: #4e4e4e;
        z-index: 1;
      }
    }

    .range-container {
      position: absolute;
      top: -6px;
      margin: 0;
      padding: 0;
      background: transparent;
      width: 100%;

      ion-range {
        padding: 0;
        --height: 16px;
        --knob-size: 16px;
        --bar-height: 3px;
        &::part(bar) {
          background-color: rgba(0, 0, 0, 0.3);
        }
        &::part(bar-active) {
          background-color: var(--ion-color-danger);
        }
        &::before {
          content: " ";
          position: absolute;
          left: 0;
          top: 6px;
          background: $cherry-red;
          min-height: 3px;
          width: var(--data-val, 0);
          z-index: 1;
        }
      }
    }
  }

  .buttons {
    ion-col {
      padding: 0 4px;
      ion-button {
        ion-icon {
          font-size: var(--fs-20);
        }
      }
    }
    ion-button {
      &.reload {
        position: relative;

        span.badge {
          position: absolute;
          bottom: -2px;
          right: -2px;
          @include fs(9px);
          min-width: 5px;
          min-height: 5px;
          display: table;
          text-align: center;
          vertical-align: middle;
          color: var(--ion-color-danger);
          background: #c9c9c9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          padding: 2px;
        }
      }
      &.play {
        ion-icon {
          @include fs(28px);
        }
      }
    }
  }

  &.isVideo {
    .scroller-base {
      display: none;
    }

    .content {
      overflow-x: hidden;

      .suggestions {
        .item {
          padding-left: 50%;
          min-height: 130px;

          .avatar {
            position: absolute;
            left: 10px;
            top: 10px;
            min-height: 110px;
            max-width: 50%;
            width: 44%;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;

            background-position: center;
            background-repeat: no-repeat;
            -webkit-background-size: 100%;
            background-size: 100%;
          }

          .duration {
            position: absolute;
            top: 15px;
            right: 55%;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 0 5px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            font-size: 13px;
          }
        }
      }
    }
  }

  &.closed {
    top: 101%;
  }

  &.open {
    height: calc(100% - 44px);
    max-height: 100%;

    .media-player {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
    }
  }

  &.minified {
    .track-info {
      padding-left: 48px;
      > ion-button {
        display: unset;
        left: 0;
        top: 0;
      }
    }
    .range-container {
      position: absolute;
      top: 0 !important;
      background: transparent;
      width: 100%;
      ion-range {
        --height: 3px;
        --knob-size: 0;
        &::-webkit-slider-thumb {
          display: none;
        }

        &:before {
          top: 0 !important;
        }
      }
    }
    .buttons {
      display: none !important;
    }
    &.isVideo {
      .media-window {
        padding: 0;
      }

      ion-content {
        position: relative;
        top: auto;
        display: inline-block;
        width: 45%;
        float: left;

        .scroll-bar {
          display: none;
        }

        .plyr.plyr--full-ui {
          min-width: 45%;
        }
      }

      .media-player {
        display: inline-block;
        width: 55%;
        float: right;
      }
    }

    #video-player {
      .plyr__controls,
      button {
        display: none;
      }
    }
  }
}

.media-window {
  background: var(--player-bg);
  width: 100%;
  height: inherit;
  //padding: 5px;
  //padding-top: 0;
  padding: 0;

  div:not(.mini) & {
    position: absolute;
    bottom: 0;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  #video-player {
    position: relative;

    &.waiting:before {
      @include spinner(white, 48px, calc(50% - 24px), 5px);
    }

    .plyr {
      &__control {
        &.plyr__tab-focus {
          background-color: $cherry-red;
          -webkit-box-shadow: 0 0 7px 5px white;
          -moz-box-shadow: 0 0 7px 5px white;
          box-shadow: 0 0 7px 5px white;
        }
      }

      &__control--overlaid {
        background: $cherry-red;
        opacity: 0.8;
      }

      &--full-ui input[type="range"] {
        color: $cherry-red;
      }
    }
  }
}

video::-webkit-media-controls-panel {
  display: none !important;
}
</style>
