<template>
  <bare-modal
    ref="modal"
    :breakpoints="[0, 1]"
    :initial-breakpoint="1"
    class="cookie-banner-modal"
    @closed="hide"
  >
    <template #content>
      <ion-item-group class="js-info-block">
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">
            <h3 class="ion-text-uppercase">
              <strong>{{ $t("banners.cookieBanner.header") }}</strong>
            </h3>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <component :is="'cookie-check-outline-icon'" slot="start" :size="48" />
          <ion-label class="ion-text-wrap">
            <p v-html="$t('banners.cookieBanner.p1', { device })" />
            <p v-html="$t('banners.cookieBanner.p2')" @click="$refs.modal.close()" />
          </ion-label>
        </ion-item>
      </ion-item-group>
    </template>
  </bare-modal>
</template>

<script>
import { isPlatform, IonItemGroup } from "@ionic/vue";
import { isPuppeteer } from "@/utils/fns";
import BareModal from "@/components/BareModal.vue";
import cookieCheckOutlineIcon from "@material-icons/Cookie.vue";
import $storage from "@/utils/storage";

export default {
  components: { BareModal, cookieCheckOutlineIcon, IonItemGroup },
  emits: ["dismissed"],
  computed: {
    device() {
      return isPlatform("desktop") ? "computer" : "mobile";
    },
  },
  mounted() {
    const shownCookieBanner = $storage.get("shown.cookiebanner") || isPuppeteer();
    if (!shownCookieBanner) {
      return this.$refs.modal.openModal();
    }
    this.$emit("dismissed", true);
  },
  methods: {
    hide() {
      $storage.set("shown.cookiebanner", 1);
      this.$emit("dismissed", true);
    },
    show() {
      this.$refs.modal.openModal();
    },
  },
};
</script>

<style lang="scss">
.cookie-banner-modal {
  --width: 100%;
  --height: auto;
  .content {
    background: var(--ion-color-light);
  }
  ion-item {
    --background: transparent;
    align-items: baseline;
    &:first-child {
      padding-top: 24px;
      --min-height: auto;
      ion-label {
        margin: 0;
      }
    }
    .cookie-icon {
      margin-top: 8px;
      margin-inline-end: 16px;
    }
    h3 {
      margin-bottom: 8px;
    }
    p {
      margin-top: 0;
      a {
        // color: var(--ion-color-light);
      }
    }
  }
}
</style>
