import axios from "axios";
import { BASE_URL } from "@/constants/api";
import $storage from "@/utils/storage";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const API_BASE_URL = BASE_URL();
    if (!config.url.match(/^https?/)) {
      config.url = API_BASE_URL + config.url;
    }
    const isAppAPI = config.url.includes(API_BASE_URL);
    if (isAppAPI) {
      const { headers, url } = config;
      if (!headers.token) {
        const token = $storage.get("token");
        if (token) {
          config.headers.token = token;
        }
      }

      if (url.match(/\.json$/) && isAppAPI) {
        const chunks = url.split("/");
        const name = chunks.pop();
        const token = btoa(name + "_" + Date.now());
        headers["x-moamin-resource"] = token; // value hidden under key named with new hash
      }
      headers["app-version"] = "2.0.0"; //TODO: Change this with value from config
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (!response) {
      return Promise.reject({
        code: "NETWORK_ERROR",
        message:
          "There seem to be a network issue with one or more of our services. Please try again in a while.",
        error,
      });
    }
    return Promise.reject(
      response ? response : error.toJSON ? error.toJSON() : error
    );
  }
);
export default axios;
