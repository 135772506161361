import $storage from "@/utils/storage";
export const isProd = process.env.NODE_ENV === "production"; // || true;

const BASE_URL = () => {
  const tunnelId = $storage.get("tunnel");
  const localhost = window.location.origin.match(/localhost/)
    ? "localhost"
    : "192.168.1.124";
  const { protocol } = window.location;
  return isProd
    ? "https://api.moamin.in"
    : protocol + `//${tunnelId ? tunnelId + ".ngrok.io" : localhost + ":3000"}`;
};

const FEED_API_BASE = "/feed";
const FEED_APIS = {
  list: FEED_API_BASE + "/",
  detail: FEED_API_BASE + "/",
  tags: FEED_API_BASE + "/tags",
  audience: FEED_API_BASE + "/audience/",
  deleteItem: FEED_API_BASE + "/",
  reportItem: FEED_API_BASE + "/report",
  comments: FEED_API_BASE + "/comments/", // also used in delete-comment API
};
const TRACKS_APIS_BASE = "/tracks";
const TRACKS_APIS = {
  list: TRACKS_APIS_BASE + "/",
  suggestion: TRACKS_APIS_BASE + "/suggestion/",
  url: TRACKS_APIS_BASE + "/url/",
  stream: TRACKS_APIS_BASE + "/stream/",
  media: TRACKS_APIS_BASE + "/media/",
  duaCategories: TRACKS_APIS_BASE + "/dua/categories",
};
const BOOKS_API = {
  basePath: "/media/books",
  list: "/",
};
const USER_APIS_BASE = "/user";
const USER_APIS = {
  profile: USER_APIS_BASE + "/profile",
  update_single: USER_APIS_BASE + "/update/", // followed by field-name
  location: USER_APIS_BASE + "/location-ip",
  invite: USER_APIS_BASE + "/invite/",
  userId: USER_APIS_BASE + "/id/", //followed by username
  follow: USER_APIS_BASE + "/follow/", //followed by userId
  unfollow: USER_APIS_BASE + "/unfollow/", //followed by userId
};
const FOLLOW_API_BASE = "/follower";
export const FOLLOW_APIS = {
  list: FOLLOW_API_BASE + "/", //followed by userId
  unfollow: FOLLOW_API_BASE + "/unfollow/", //followed by userId
  follow: FOLLOW_API_BASE + "/follow/", //followed by userId
};
const AUTH_BASE_PATH = "/auth";
const AUTH_PATHS = {
  login: AUTH_BASE_PATH + "/login",
  social: "/", //append to this.login
  register: AUTH_BASE_PATH + "/register",
  logout: AUTH_BASE_PATH + "/logout",
  profile: AUTH_BASE_PATH + "/profile/",
  otpSend: AUTH_BASE_PATH + "/otp/",
  otpVerify: AUTH_BASE_PATH + "/otp/verify",
  otpLogin: AUTH_BASE_PATH + "/otp/login",
  guestToken: AUTH_BASE_PATH + "/guest-token",
  resetEmail: AUTH_BASE_PATH + "/reset-link",
  verifyEmail: AUTH_BASE_PATH + "/email/verify",
  verifyShiaSadat: AUTH_BASE_PATH + "/shia/verify",
  verificationLinkEmail: AUTH_BASE_PATH + "/verification-link",
  reloadUser: AUTH_BASE_PATH + "/reload/", // followed by userId
  tokenExternal: AUTH_BASE_PATH + "/token/external",
};
const RELATIVES_API_BASE = "/relative";
const RELATIVES_APIS = {
  list: RELATIVES_API_BASE + "/",
  add: RELATIVES_API_BASE + "/",
  fatiha: RELATIVES_API_BASE + "/fatiha/",
};
const VENDOR_BASE = "/apis";
const VENDOR_PATHS = {
  map: {
    basePath: VENDOR_BASE + "/map",
    places: "/places",
    timezone: "/timezone/",
  },
  mapBox: {
    basePath: "https://api.mapbox.com/geocoding/v5",
    reverseGeocode: "/mapbox.places/",
  },
  google: {
    basePath: VENDOR_BASE + "/google",
    places: "/places",
    city: "/city/",
  },
  alAdhan: {
    basePath: VENDOR_BASE + "/aladhan",
    calendar: "/calendar",
  },
  geoplugin: {
    getGeoDataFromIP: "http://www.geoplugin.net/json.gp",
    // https://app.ipgeolocation.io/
    ipgeolocation:
      "https://api.ipgeolocation.io/ipgeo?apiKey=dfdf0b47ec3b45da92a39dc247b6065c",
  },
  alnajfiya: {
    basePath: VENDOR_BASE + "/alnajafyia",
    requestZiyarat: "/ziyarat",
  },
  payments: {
    basePath: VENDOR_BASE + "/payment",
    newSession: "/session",
  },
};

const QNA_BASE_URL = "/qna";
const QNA_APIS = {
  list: QNA_BASE_URL + "/",
  add: QNA_BASE_URL + "/",
  tags: QNA_BASE_URL + "/tags",
};

const SEARCH_BASE = "/search";
export const SEARCH_APIS = {
  summary: SEARCH_BASE + "/summary/",
  results: SEARCH_BASE + "/results/", // followed by section-name
  users: SEARCH_BASE + "/results/users",
};
const FILES_BASE = "/files";
const FILES_URL = {
  uploadPath: FILES_BASE + "/upload/base64/",
};

export const KHUMS_BASE = "/khums";
export const KHUMS_APIS = {
  list: KHUMS_BASE + "/",
  calculate: KHUMS_BASE + "/calculate",
  createPdf: KHUMS_BASE + "/pdf/", // folloed by calcId
};

export const PUSH_API_BASE = "/push";
export const PUSH_APIS = {
  registerPushToken: PUSH_API_BASE + "/subscribe/device",
  registerBrowser: PUSH_API_BASE + "/subscribe/browser",
};

export const BOT_API_BASE = "/bot";
export const BOT_API = {
  conversation: BOT_API_BASE + "/conversation/", // followed by conversationId
  askQuestion: BOT_API_BASE + "/question",
  price: BOT_API_BASE + "/price",
};
export const MISC_API_BASE = "/other";
export const MISC_APIS = {
  notifications: MISC_API_BASE + "/notifications",
  redirect: MISC_API_BASE + "/redirect/",
};

export {
  BASE_URL,
  FEED_APIS,
  TRACKS_APIS,
  BOOKS_API,
  USER_APIS,
  AUTH_PATHS,
  RELATIVES_APIS,
  VENDOR_PATHS,
  QNA_APIS,
  FILES_URL,
};
