import api from "@/utils/api";
// import $storage from "@/utils/storage";
import { BASE_URL, KHUMS_APIS } from "@/constants/api";
import { getHeaders } from "@/utils/fns";

export default {
  getKhumsList() {
    const URL = BASE_URL() + KHUMS_APIS.list;
    return api
      .get(URL, { headers: getHeaders() })
      .then((response) => response.data);
  },
  getKhumsDetail(calcId) {
    const URL = BASE_URL() + KHUMS_APIS.list + calcId;
    return api
      .get(URL, { headers: getHeaders() })
      .then((response) => response.data);
  },
  deleteKhums(calcId) {
    const URL = BASE_URL() + KHUMS_APIS.list + calcId;
    return api
      .delete(URL, { headers: getHeaders() })
      .then((response) => response.data);
  },
  addKhumsPaymentRecord(params) {
    const { calcId, record, khumsType } = params;
    const URL = BASE_URL() + KHUMS_APIS.list + "record/" + calcId;
    return api
      .post(URL, { record, khumsType }, { headers: getHeaders() })
      .then((response) => response.data);
  },
  deleteKhumsPaymentRecord(params) {
    const { calcId, record, khumsType } = params;
    const URL = BASE_URL() + KHUMS_APIS.list + "record/" + calcId;
    // 'delete' uses 'data' key to send data in request
    return api
      .delete(URL, { data: { record, khumsType } }, { headers: getHeaders() })
      .then((response) => response.data);
  },
  calculateKhums(params) {
    const URL = BASE_URL() + KHUMS_APIS.calculate;
    return api
      .post(URL, params, { headers: getHeaders() })
      .then((response) => response.data);
  },
  generatePdf(calcId) {
    const URL = BASE_URL() + KHUMS_APIS.createPdf + calcId;
    const headers = getHeaders();
    return api.post(URL, null, { headers }).then((response) => response.data);
  },
};
