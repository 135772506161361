<template>
  <bare-modal
    ref="modal"
    :breakpoints="[0, 1]"
    :initial-breakpoint="1"
    :can-dismiss="showPrompt"
    :backdrop-dismiss="false"
    class="finish-setup-prompt"
  >
    <template #content>
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title v-html="$t('comps.finishSetupPrompt.heading')" mode="md" color="light" />
        </ion-toolbar>
      </ion-header>
      <div class="content ion-padding">
        <p style="margin-top: 0" v-html="$t('comps.finishSetupPrompt.desc')" />
        <div class="ion-text-center">
          <ion-button
            @click="gotoPage"
            v-html="$t('comps.finishSetupPrompt.gotoPageCta')"
            v-track-this="{
              event: 'click',
              category: TRK.category,
              label: TRK.label + '-GotoPageCTA',
            }"
          />
        </div>
      </div>
      <ion-item color="light" lines="none">
        <ion-label class="ion-text-wrap">
          <ion-checkbox
            justify="start"
            label-placement="end"
            v-model="stopReminder"
            v-track-this="{
              event: 'change',
              category: TRK.category,
              label: TRK.label + '-dontRemindFlag',
              value: stopReminder ? 1 : 0,
            }"
          >
            {{ $t("comps.finishSetupPrompt.dontRemind") }}
          </ion-checkbox>
        </ion-label>
        <ion-button
          slot="end"
          fill="clear"
          color="dark"
          @click="showPrompt"
          v-html="$t('comps.finishSetupPrompt.dismissCta')"
          v-track-this="{
            event: 'click',
            category: TRK.category,
            label: TRK.label + '-DismissCTA',
          }"
        />
      </ion-item>
    </template>
  </bare-modal>
</template>

<script>
import { inject } from 'vue';
import { IonHeader, IonTitle, IonToolbar, IonCheckbox } from "@ionic/vue";
import { usePrompts } from "@/composables";
import $storage from "@/utils/storage";
import authMixin from "@/mixins/auth";
import BareModal from "@/components/BareModal.vue";

export default {
  components: { BareModal, IonToolbar, IonHeader, IonTitle, IonCheckbox },
  mixins: [authMixin],
  data() {
    return {
      stopReminder: false,
      canProceed: false,
    };
  },
  mounted() {
    this.stopReminder = $storage.get("dont_remind_account_setup", 0);
    console.log("Stop AccountSetup reminder? ", this.stopReminder);
  },
  methods: {
    gotoPage() {
      this.canProceed = true; // to override showPrompt
      this.$refs.modal.closeModal();
      const {loginInfo, basicInfo, locInfo, identityInfo, percent} = this.accountCompleteStat();
      this.$nextTick(() => {
        let action;
        if(!loginInfo) action = 'verify-account';
        else if(!basicInfo || !locInfo) action = 'setup-profile'
        else action = 'setup-preferences';
        this.$router.push({ name: "onboardingPage", params: {action} });
      });
    },
    openModal(ev) {
      if (!this.stopReminder) {
        this.$refs.modal.openModal(ev);
      }
    },
    async showPrompt(data, role) {
      // console.trace('data: ', data, role);
      const {canProceed, $t} = this;
      if (canProceed) return true;
      if (role !== "gesture" && !data) return false;
      const { confirm } = usePrompts();
      const options = {
        header: $t('comps.finishSetupPrompt.jsConfirmSkip.header'),
        buttons: {
          confirm: $t('comps.finishSetupPrompt.jsConfirmSkip.btns.confirm'),
          cancel: $t('comps.finishSetupPrompt.jsConfirmSkip.btns.cancel'),
        },
      };
      try {
        await confirm($t('comps.finishSetupPrompt.jsConfirmSkip.msg'), options);
        if (this.stopReminder) {
          $storage.set("dont_remind_account_setup", 1);
        }
        this.canProceed = true;
        this.$refs.modal.closeModal();
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  setup() {
    const myProfile = inject('myProfile');
    return {
      myProfile,
      TRK: {
        category: "Onboarding",
        label: "FinishAccountSetupPrompt",
      },
    };
  },
};
</script>

<style lang="scss">
</style>
