import $storage from "@/utils/storage";

const GLOBAL = true,
  USER_ACTION = "lang.userAction";

export default {
  userLangSelected: $storage.get(USER_ACTION, "NONE", GLOBAL),
  defaultLang: {
    label: "English",
    code: "en",
  },
  setSelectedLang(langObj, userSelected) {
    const prevLang = this.getSelectedLang();
    if (prevLang !== langObj) {
      $storage.set("lang.previous", prevLang, GLOBAL);
    }
    if (userSelected) {
      this.userLangSelected = true;
      $storage.set(USER_ACTION, 1, GLOBAL);
    }
    $storage.set("lang.selected", langObj, GLOBAL);
  },
  getSelectedLang() {
    return $storage.get("lang.selected", this.defaultLang, GLOBAL);
  },
};
