<template>
  <component :is="element" @click="handleGuestClick" v-bind="$attrs">
    <slot
      :canProceed="!isGuest() && !blockAction"
      :isGuest="isGuest()"
      :close="closeModal"
    >
      {{ $t("comps.authPrompt.defLabel") }}
    </slot>
  </component>
  <bare-modal
    ref="modal"
    class="auth-prompt-modal"
    :breakpoints="[0, 1]"
    :initial-breakpoint="1"
    @closed="$refs.modal.$el.dismiss()"
  >
    <template #content>
      <ion-toolbar color="primary" mode="md">
        <ion-title
          v-html="isGuest() ? $t('comps.authPrompt.title') : 'Action required'"
          color="light"
        />
      </ion-toolbar>
      <div class="ion-padding-horizontal">
        <p
          v-if="isGuest()"
          class="fs-16"
          v-html="infoText || $t('comps.authPrompt.infoText')"
        />
        <slot v-else name="altText">
          <p>
            This feature requires you to Complete your account setup before you
            could use it. It could be because this feature is available/useful
            only for a sect of users.
          </p>
        </slot>
      </div>

      <div class="ion-text-center">
        <template v-if="isGuest()">
          <ion-button fill="outline" @click="closeModal('login')">
            {{ $t("common.login") }}
          </ion-button>
          <ion-button fill="solid" @click="closeModal('signup')">
            {{ $t("common.signup") }}
          </ion-button>
        </template>
        <slot v-else name="altAction">
          <ion-button @click="handleUserClick"> Goto Account Setup </ion-button>
        </slot>
      </div>
    </template>
  </bare-modal>
</template>

<script>
import { inject } from "vue";
import { IonToolbar, IonTitle } from "@ionic/vue";
import { getUser } from "@/composables";
import $storage from "@/utils/storage";
import BareModal from "@/components/BareModal.vue";
import authMixin from "@/mixins/auth";

export default {
  name: "auth-prompt",
  components: { BareModal, IonToolbar, IonTitle },
  props: {
    element: {
      type: String,
      default: "label",
    },
    // to return to the page where auth-prompt was shown, after login/signup
    comeBack: {
      type: Boolean,
      default: false,
    },
    // data to cache, to provide back to the page after login/signup
    state: {
      type: Object,
      default: null,
    },
    infoText: {
      type: String,
      default: null,
    },
    required: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [authMixin],
  computed: {
    blockAction() {
      const {
        required,
        myProfile: { is_shia, is_shia_verified, is_sadat, is_sadat_verified },
      } = this;
      const { loginInfo, basicInfo, locInfo, identityInfo, percent } =
        this.accountCompleteStat();
      const blockForLogin = required.includes("loginInfo") && !loginInfo;
      const blockForBasic = required.includes("basicInfo") && !basicInfo;
      const blockForLocation = required.includes("location") && !locInfo;
      const blockForId = required.includes("identity") && !identityInfo;
      const blockForIdVerification =
        required.includes("identity-verified") &&
        !(is_sadat ? is_sadat_verified : is_shia ? is_shia_verified : true);
      const blockForCompleteness =
        required.includes("completeness") && percent <= 99;
      return (
        blockForLogin ||
        blockForBasic ||
        blockForCompleteness ||
        blockForLocation ||
        blockForId ||
        blockForIdVerification
      );
    },
  },
  methods: {
    closeModal(pageId) {
      this.$refs.modal.closeModal();
      if (this.comeBack) {
        console.log("will come back to path: ", this.$route.path);
        $storage.$session.set("pp_url", this.$route.path); // prev_page_url
        this.state && $storage.$session.set("pp_data", this.state); // prev_page_data
      }
      this.$nextTick(() => {
        this.$router.push({ name: "authPage", params: { page: pageId } });
      });
    },
    handleGuestClick(ev) {
      const { isGuest } = this;
      if (isGuest()) {
        ev.stopImmediatePropagation();
        ev.preventDefault();
        this.$refs.modal.openModal(ev);
        return false;
      } else {
        const { blockAction } = this;
        if (blockAction) {
          ev.stopImmediatePropagation();
          ev.preventDefault();
          this.$refs.modal.openModal(ev);
        }
      }
    },
    handleUserClick() {
      this.$refs.modal.close();
      const {
        required,
        myProfile: { is_sadat, is_sadat_verified, is_shia, is_shia_verified },
      } = this;
      const { loginInfo, basicInfo, locInfo, identityInfo, percent } =
        this.accountCompleteStat();
      let action;

      const blockForLogin = required.includes("loginInfo") && !loginInfo;
      const blockForBasic = required.includes("basicInfo") && !basicInfo;
      const blockForLocation = required.includes("location") && !locInfo;
      const blockForId = required.includes("identity") && !identityInfo;
      const blockForIdVerification =
        required.includes("identity-verified") &&
        !(is_sadat ? is_sadat_verified : is_shia ? is_shia_verified : true);

      const blockForCompleteness =
        required.includes("completeness") && !percent <= 99;
      if (blockForLogin) {
        action = "verify-account";
      } else if (blockForBasic || blockForLocation || blockForIdVerification) {
        action = "setup-profile";
      } else {
        action = "setup-preferences";
      }
      this.$nextTick(() => {
        this.$router.push({
          name: "onboardingPage",
          params: { action },
        });
      });
    },
  },
  setup() {
    const { isGuest } = getUser();
    const myProfile = inject("myProfile");
    return { isGuest, myProfile };
  },
};
</script>

<style lang="scss">
.auth-prompt-modal {
  --height: auto;
  [mode="ios"] & {
    ion-title {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
  .content-only {
    background: var(--ion-color-light);
    padding-bottom: 16px;
    ion-button {
      min-width: 120px;
      &:first-child {
        margin-inline-end: 16px;
      }
    }
  }
}
</style>
