<template>
  <label v-if="previewMode" v-bind="$attrs" class="share-btn">
    <ion-icon :icon="shareSocialOutline" class="fs-22" />
    <span v-if="mode !== 'icon'">
      {{ $t("common.share") }}
    </span>
  </label>
  <template v-else>
    <ion-button
      @click="shareNow"
      v-bind="$attrs"
      class="share-btn"
      v-track-this="trackConfig"
    >
      <slot>
        <ion-icon :icon="shareSocialOutline" class="fs-22" />
        <span v-if="mode !== 'icon'" v-html="ctaLabel || $t('common.share')" />
      </slot>
    </ion-button>
    <bare-modal
      v-if="!isShareSupported"
      class="share-btn-modal"
      ref="modal"
      :breakpoints="[0, 1]"
      :initial-breakpoint="1"
    >
      <template #content>
        <ion-item-divider color="light">
          <ion-label>{{ $t("common.share") }}</ion-label>
        </ion-item-divider>
        <ion-item-divider
          lines="none"
          class="ion-padding"
          style="border: none;"
        >
          <p v-html="$t('comps.shareBtn.subtext')" />
        </ion-item-divider>
        <ion-item lines="none">
          <ion-button
            fill="clear"
            class="whatsApp"
            :href="whatsAppLink"
            target="_blank"
            @click="$refs.modal.closeModal()"
          >
            <ion-icon :icon="icons.whatsApp" />
          </ion-button>
          <ion-button
            fill="clear"
            class="facebook"
            :href="fbLink"
            target="_blank"
            @click="$refs.modal.closeModal()"
          >
            <ion-icon :icon="icons.fb" />
          </ion-button>
          <ion-button
            fill="clear"
            class="twitter twitter-share-button"
            data-size="large"
            :href="twitterLink"
            target="_blank"
            @click="$refs.modal.closeModal()"
          >
            <ion-icon :icon="icons.twitter" />
          </ion-button>
          <ion-button
            fill="clear"
            class="instagram"
            :href="whatsAppLink"
            target="_blank"
            @click="$refs.modal.closeModal()"
          >
            <ion-icon :icon="icons.insta" />
          </ion-button>
          <ion-button fill="clear" color="dark" @click="copyContent">
            <ion-icon :icon="icons.copyOutline" />
          </ion-button>
        </ion-item>
        <div class="ion-padding">
          <ion-item lines="none">
            <ion-label
              position="stacked"
              class="ion-text-wrap"
              v-html="$t('comps.shareBtn.copyText')"
            />
            <ion-textarea
              class="input"
              :value="copyPost"
              :readonly="true"
              :rows="5"
            />
          </ion-item>
        </div>
      </template>
    </bare-modal>
  </template>
</template>

<script>
import { IonItemDivider, IonTextarea } from "@ionic/vue";
import { inject } from "vue";
import {
  shareSocialOutline,
  logoWhatsapp as whatsApp,
  logoFacebook as fb,
  logoInstagram as insta,
  logoTwitter as twitter,
  copyOutline,
} from "ionicons/icons";
import $sharer from "@/utils/social-sharing";
import BareModal from "./BareModal.vue";
import { usePrompts } from "@/composables";
import { shareSupported, openSharer } from "@/utils/fns";

export default {
  components: { BareModal, IonItemDivider, IonTextarea },
  props: {
    previewMode: {
      type: Boolean,
      default: false,
    },
    ctaLabel: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: () => "detail",
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    subject: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    trackConfig: {
      type: Object,
      default: () => ({
        category: "ShareBtn",
        event: "click",
        label: "ShareBtnCTA",
      }),
    },
  },
  data() {
    return {
      shareImage: null,
      shareObj: null,
    };
  },
  computed: {
    isShareSupported() {
      return shareSupported();
    },
    whatsAppLink() {
      const { message, url } = this.shareObj;
      const text = [message, url].filter((i) => !!i).join("\n");

      return "https://wa.me?text=" + encodeURIComponent(text);
    },
    fbLink() {
      const { url } = this.shareObj;
      return (
        `https://www.facebook.com/dialog/share?app_id=786731958125870&display=popup&href=` +
        url
      );
    },
    twitterLink() {
      const { url, message, subject } = this.shareObj;
      const text = [subject, message].filter((i) => !!i).join("\n");
      return (
        "https://twitter.com/intent/tweet?" +
        [
          "url=" + url,
          "text=" + encodeURIComponent(text),
          "via=moamin-app",
        ].join("&")
      );
    },
    copyPost() {
      const { subject, message, url } = this.shareObj;
      return [url, subject, message].filter((i) => !!i).join("\n");
    },
  },
  methods: {
    triggerShare(shareObj) {
      const sharer = openSharer(shareObj);
      if (!sharer) {
        const { subject, message, image, url } = shareObj;
        console.log(
          "Share requested\nsubject:",
          subject,
          "\nmsg:",
          message,
          "\nimg:",
          image,
          "\nurl: ",
          url
        );
        this.shareObj = shareObj;
        this.$refs.modal.openModal();
      }
    },
    shareNow() {
      //eg, for email
      const { config, subject, message, url, image, shareImage } = this;
      const { itemType, item } = config;
      const shareObj = {
        subject:
          subject ||
          config.subject ||
          $sharer.getSharingSubject(itemType, item),
        message:
          message || config.message || $sharer.getSharingDesc(itemType, item),
        image: shareImage || image || $sharer.getSharingImg(itemType, item),
        url: url || config.url || $sharer.getSharingUrl(itemType, item),
      };
      this.triggerShare(shareObj);
    },
    copyContent() {
      const { modal } = this.$refs;
      const content = modal.$el.querySelector("textarea");
      content.focus();
      content.select();

      content.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      modal.$el.dismiss(); //triggers setModalState(false) eventually
      this.toast(this.$t("comps.shareBtn.jsCopyToCB"));
    },
  },
  mounted() {
    const { config, image } = this;
    this.shareImage = config.image || image;
    const remoteImage =
      this.shareImage && this.shareImage.match(/^(https?|\/\/)/);

    if (remoteImage) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
        canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

        canvas.getContext("2d").drawImage(this, 0, 0);
        this.shareImage = canvas.toDataURL();
      };
      img.src = this.shareImage;
    }
    $sharer.routeResolver = this.$router.resolve; //assign resolve fn for later
    $sharer.myProfile = this.myProfile;
  },
  setup() {
    const { toast } = usePrompts();
    const myProfile = inject("myProfile");

    return {
      shareSocialOutline,
      toast,
      myProfile,
      icons: {
        whatsApp,
        fb,
        insta,
        twitter,
        copyOutline,
      },
    };
  },
};
</script>

<style lang="scss">
.share-btn-modal {
  .content-only {
    ion-button {
      @include fs(24px);
      height: auto;
      &.whatsApp {
        color: $whatsApp;
      }
      &.facebook {
        color: $facebook;
      }
      &.twitter {
        color: $twitter;
      }
      &.instagram {
        color: $instagram;
      }
    }
    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
      ion-textarea {
        border: 1px solid var(--ion-color-medium);
        border-radius: 5px;
        background: var(--ion-color-light);
        margin-top: 8px;
        --padding-start: 8px;
        --padding-end: 8px;
      }
    }
  }
}
</style>
