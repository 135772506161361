import $storage from "@/utils/storage";
import { usePrompts } from "./prompts";
import { useColor } from "./color";

const color = useColor();

export function getUser() {
  const currentUser = (defVal) => {
    return $storage.get("user", defVal || {});
  };

  const isGuest = () => {
    const { userId } = currentUser();
    return !userId;
  };

  const promptLogout = () => {
    if (isGuest()) return;
    const { confirm } = usePrompts();
    return confirm("Are you sure you want to logout?", { heading: "Logout" });
  };

  const isThisMyAccount = (profileId) => {
    const { userId } = currentUser();
    return profileId === "me" ? userId : Number(profileId) === userId;
  };

  const isAdmin = () => {
    if (isGuest()) return false;
    return currentUser().roleIs.includes("ADMIN");
  };

  const hasUserReactedWith = (reactionType, reactions) => {
    if (isGuest()) {
      return false;
    }
    const { userId } = currentUser();
    const allReactions = reactions || {};
    const reaction = reactionType.replace(/_by$/i, "") + "_BY";
    const reactionsForKey = allReactions[reaction] || [];
    return reactionsForKey.map((userId) => Number(userId)).includes(userId);
  };

  const getTextAvatar = ({
    userName,
    userId,
    bgColor,
    size,
    length,
    rounded,
  }) => {
    const name = userName
      .replace(/[^a-z ]+/gi, "")
      .split(" ")
      .join("+");
    const imgId = userName.split(" ").join("") + (userId || 0);
    const text = name.split("+").join("").toUpperCase();
    if (userName.toLowerCase() === "you") {
      color[imgId] = "793af7"; //ion-color-primary-tint
    } else {
      color[imgId] = color[imgId] || bgColor;
      if (!color[imgId]) {
        let rgb = color.generate(text);
        rgb = color.brighten(-60, rgb);
        color[imgId] = rgb.replace(/[^a-f0-9]+/i, "").toLowerCase();
      }
    }
    const params = [
      "name=" + name,
      "rounded=" + (rounded === undefined ? 1 : rounded),
      "size=" + (size || 64),
      "color=ffffff",
      "background=" + color[imgId],
    ];
    if (length) {
      params.push("length=" + length);
    }
    if (length >= 3) {
      params.push("font-size=" + 0.38);
    }
    return `https://eu.ui-avatars.com/api/?` + params.join("&");
  };

  return {
    currentUser,
    isGuest,
    isThisMyAccount,
    isAdmin,
    promptLogout,
    hasUserReactedWith,
    getTextAvatar,
  };
}
