<template>
  <ion-menu
    content-id="main-content"
    menu-id="leftMenu"
    side="start"
    :swipe-gesture="false"
    class="main-menu"
  >
    <ion-header>
      <ion-toolbar>
        <ion-img
          src="assets/icons/bare-logo-x48.png"
          slot="start"
          width="48"
          router-link="/"
        />
        <ion-title router-link="/">
          <span class="brand">
            <span class="primary ion-text-capitalize">{{ hostname }}</span
            ><span class="secondary">in</span>
            <ion-badge id="beta-badge" mode="ios">beta</ion-badge>
            <ion-popover
              trigger="beta-badge"
              trigger-action="hover"
              side="bottom"
              alignment="start"
              mode="ios"
              css-class="beta-popover"
            >
              <ion-content class="fs-12">
                <div style="padding: 8px;">
                  You're enjoying beta version, which means, the app may show
                  buggy behaviour sometimes.
                </div>
              </ion-content>
            </ion-popover>
          </span>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding">
      <ion-breadcrumbs
        :max-items="maxBreadcrumbItems"
        :items-after-collapse="2"
        color="primary"
        @ionCollapsedClick="maxBreadcrumbItems = undefined"
      >
        <ion-breadcrumb v-if="$route.fullPath !== '/'" router-link="/">
          {{ $t("common.home") }}
          <ion-icon slot="separator" :icon="icons.arrowForward"></ion-icon>
        </ion-breadcrumb>

        <ion-breadcrumb
          v-if="$route.fullPath.match(/^\/media/)"
          :router-link="{ name: 'mediaTab' }"
        >
          {{ $t("tabs.media.name") }}
          <ion-icon slot="separator" :icon="icons.arrowForward"></ion-icon>
        </ion-breadcrumb>

        <template v-if="$route.fullPath.match(/^\/tools/)">
          <ion-breadcrumb :router-link="{ name: 'toolsTab' }">
            {{ $t("tabs.tools.name") }}
            <ion-icon slot="separator" :icon="icons.arrowForward"></ion-icon>
          </ion-breadcrumb>
          <ion-breadcrumb
            v-if="$route.name.match(/khums/i)"
            :router-link="{ name: 'khumsHome' }"
          >
            Khums Calculator
          </ion-breadcrumb>
        </template>

        <ion-breadcrumb v-if="$route.fullPath.match(/^\/user/)" href="/user">
          {{ $t("tabs.user.name", 1) }}
          <ion-icon slot="separator" :icon="icons.arrowForward"></ion-icon>
        </ion-breadcrumb>
      </ion-breadcrumbs>
      <component v-if="menuComponent" :is="menuComponent" />
    </ion-content>
    <ion-footer>
      <ion-toolbar color="light">
        <a
          v-for="link in urls"
          :key="link.url"
          :href="link.url"
          @click="link.onclick ? link.onclick() : () => true"
          :target="link.target"
        >
          {{ $t(link.label) }}
        </a>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>
</template>
<script>
import {
  IonMenu,
  IonAvatar,
  IonBreadcrumbs,
  IonBreadcrumb,
  IonBadge,
  IonPopover,
  IonTitle,
  IonHeader,
} from "@ionic/vue";
import { chevronForwardOutline as arrowForward } from "ionicons/icons";
import MainMenuHomePage from "./MainMenuHomePage.vue";

export default {
  name: "main-menu",
  components: {
    IonTitle,
    IonHeader,
    IonMenu,
    IonAvatar,
    IonBreadcrumbs,
    IonBreadcrumb,
    IonBadge,
    IonPopover,
  },
  emits: ["show-cookie-policy"],
  data() {
    return {
      maxBreadcrumbItems: 4,
      urls: [
        {
          label: "common.privacy",
          url: this.$router.resolve({
            name: "PrivacyPolicy",
          }).href,
        },
        {
          label: "common.contactUs",
          url: "https://moamin.in/contact-us",
          target: "_blank",
        },
        {
          label: "common.cookiePolicy",
          onclick: () => this.$emit("show-cookie-policy"),
        },
      ],
    };
  },
  computed: {
    menuComponent() {
      const {
        $route: { name },
      } = this;
      const regex = new RegExp(
        ["homeTab", "postDetail", "mediaTab", "toolsTab", "user"].join("|"),
        "i"
      );
      if (name.match(regex)) {
        return MainMenuHomePage;
      }
      return false;
    },
    hostname() {
      const hostname = window?.location?.hostname || "";
      const [subdomain, host] = hostname.split(".");
      if (subdomain.includes("localhost")) {
        return "moamin";
      }
      return host;
    },
  },
  setup() {
    return {
      icons: { arrowForward },
    };
  },
};
</script>
<style lang="scss">
ion-menu.main-menu {
  border: none;
  ion-toolbar {
    --padding-start: 16px;
    --padding-end: 16px;
    ion-avatar {
      width: 40px;
      height: 40px;
    }
    ion-title {
      padding-left: 8px;
    }
    .brand {
      .secondary {
        color: var(--ion-color-medium);
      }
      ion-badge {
        @include fs(11px);
      }
    }
  }
  ion-breadcrumbs {
    margin-bottom: 16px;
  }
  ion-footer {
    ion-toolbar {
      --padding-start: 16px;
      --padding-end: 16px;
      a,
      a:visited,
      a:hover,
      a:active {
        @include fs(12px);
        color: var(--ion-color-medium);
        text-decoration: none;
        margin-right: 16px;
        position: relative;
        cursor: pointer;
        &:not(:last-child)::after {
          position: absolute;
          right: -10px;
          top: 40%;
          content: " ";
          min-width: 3px;
          min-height: 3px;
          background: var(--ion-color-medium);
        }
      }
    }
  }
}
</style>
