import api from "@/utils/api";
import { BASE_URL, SEARCH_APIS } from "@/constants/api";
import { getHeaders } from "@/utils/fns";
import $storage from "@/utils/storage";

const LET_PASS = true;
export default {
  searchMaulana(params) {
    const URL = BASE_URL() + SEARCH_APIS.users + "";
    const options = {
      ...params,
      where: {
        prefix: "maulana",
      },
    };
    return api
      .post(URL, options, { headers: getHeaders() })
      .then((res) => res.data);
  },
  // To be deprecated, if not of use anymore
  getSearchSummary(forTab, refresh) {
    return new Promise((resolve, reject) => {
      const key = "search.summary";
      const summary = $storage.getObject(key, {});

      const URL = BASE_URL() + SEARCH_APIS.summary + (tabName || "");
      return api
        .get(URL, {
          headers: getHeaders(LET_PASS),
        })
        .then(({ data }) => {
          if (data.success) {
            summary[forTab] = data.result;
            data?.result?.length &&
              $storage.setObject("search.summary", summary);
            return resolve(data.result);
          }
          reject(data);
        });
    });
  },
  query(params) {
    const { section } = params;
    const sectionName = section === "people" ? "users" : section;
    const options = { ...params };
    delete options.section;
    const URL = BASE_URL() + SEARCH_APIS.results + sectionName;
    return api
      .post(URL, options, { headers: getHeaders(LET_PASS) })
      .then((json) => json.data);
  },
  getSectionFilters(section, filters) {
    return new Promise((resolve, reject) => {
      const now = Date.now();
      const key = `${section}.global`;
      let stored = $storage.getGlobal(key, {});

      if (section === "tags") {
        const tagsForItem = stored[section];
        if (tagsForItem?.list?.length) {
          if (
            tagsForItem.lastSaved &&
            now - tagsForItem.lastSaved <= 24 * 3600 * 1000
          ) {
            return setTimeout(() => resolve(tagsForItem.list));
          }
        }
      }

      const URL = BASE_URL() + `/${section}/filters`;
      return api
        .get(URL, {
          params: filters,
          headers: getHeaders(LET_PASS),
        })
        .then(({ data }) => {
          const { success, result } = data;
          if (success) {
            stored[section] = {
              lastSaved: now,
              list: result,
            };
            $storage.setGlobal(key, stored);
            return resolve(result);
          }
          reject(data);
        });
    });
  },
};
