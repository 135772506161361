<template>
  <div v-for="(track, index) in tracksList" :key="index">
    <ad-block
      v-if="!skipAds && (index == 5 || (index > 0 && index % 20 === 0))"
      type="google"
      display-type="nauhe-list-ad"
      class="item"
    />
    <ion-item
      lines="full"
      :track-id="track.trackId"
      :class="{ current: track.current }"
    >
      <ion-img
        v-if="
          track.albumThumbRemote || track.albumThumbUrl || track.thumbnail_xs
        "
        :src="
          track.albumThumbRemote || track.albumThumbUrl || track.thumbnail_xs
        "
      />
      <ion-label
        class="play-this label ion-text-wrap"
        @click="onTrackClick(track)"
      >
        <div
          v-track-this="{
            category: trackAs,
            event: 'click',
            label: track.trackTitle,
            value: track.trackId,
          }"
        >
          <h2>{{ track.trackTitle }}</h2>
          <p>
            {{ track.nkName || track.nauhakhwan }}
            <span v-if="track.nkAlias">({{ track.nkAlias }}) </span>
            <span v-if="track.albumYearLabel">
              | {{ track.albumYearLabel }}
            </span>
          </p>
        </div>
      </ion-label>
      <div slot="end">
        <template v-if="config.canFavorite">
          <ion-button fill="clear" @click="toggleFavorites(track)">
            <component
              :is="
                favIds.includes(track.trackId)
                  ? 'heart-icon'
                  : 'heart-outline-icon'
              "
              :size="24"
            /> </ion-button
          ><br />
        </template>
        <share-btn
          mode="icon"
          fill="clear"
          :config="{ item: track, itemType: 'nauhe' }"
        />
      </div>
    </ion-item>
  </div>
  <div
    v-if="!tracksList.length && showFavorites"
    class="ion-padding-horizontal"
  >
    <p>You have not marked any Nauhe as <strong>favorites</strong>.</p>
    <p>
      Add tracks to your favorites by clicking
      <component :is="'heartIcon'" :size="16" /> in tracks list.
    </p>
  </div>
</template>

<script>
import { inject } from "vue";
import { IonImg } from "@ionic/vue";
import { usePrompts } from "@/composables";

import ShareBtn from "@/components/ShareBtn.vue";
import AdBlock from "@/components/Ads/AdBlock.vue";
import $storage from "@/utils/storage";

import heartIcon from "@material-icons/Heart.vue";
import heartOutlineIcon from "@material-icons/HeartOutline.vue";

export default {
  components: { AdBlock, ShareBtn, IonImg, heartIcon, heartOutlineIcon },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    trackAs: {
      type: String,
      default: "",
    },
    showFavorites: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({
        canFavorite: true,
        canPlay: true,
      }),
    },
  },
  emits: ["clicked"],
  data() {
    return {
      favNauhe: $storage.get("fav.nauhe", []),
    };
  },
  computed: {
    tracksList() {
      const { favNauhe, list, showFavorites } = this;
      return showFavorites ? [...favNauhe].filter((i) => !!i) : list;
    },
    favIds() {
      const { favNauhe } = this;
      return favNauhe.map((t) => t.trackId);
    },
  },
  methods: {
    inFavorites(track) {
      return this.favNauhe.findIndex((t) => t.trackId === track.trackId);
    },
    slicedFavorites(excluded) {
      if (!this.showFavorites) return false;
      const { favNauhe } = this;
      return favNauhe.filter((t) => t.trackId !== excluded.trackId);
    },
    onTrackClick(track) {
      const {
        config: { canPlay },
        slicedFavorites,
      } = this;
      if (canPlay) {
        this.$events.emit("$mediaPlayer.playAudio", {
          ...track,
          list: slicedFavorites(track),
        });
      } else {
        this.$emit("clicked", track);
      }
    },
    toggleFavorites(track) {
      const isAdded = this.inFavorites(track);
      if (isAdded >= 0) {
        this.favNauhe.splice(isAdded, 1);
        this.toast("Removed from favorites");
      } else {
        this.favNauhe = [track, ...this.favNauhe];
        this.toast("Added to favorites");
      }
      $storage.set("fav.nauhe", this.favNauhe);
    },
  },
  setup() {
    const skipAds = inject("skipAds");
    const { toast } = usePrompts();
    return { skipAds, toast };
  },
};
</script>

<style lang="scss" scoped></style>
