<template>
  <ion-button
    v-if="!isIframe"
    :disabled="isDisabled"
    :fill="btnFill"
    :color="btnColor"
    @click="openActionSheet"
    :slot="btnPosition"
    :size="size"
    class="more-btn"
  >
    <ion-icon :icon="icon" :slot="iconSlot"></ion-icon>
    <auth-prompt v-if="isGuest()" hidden ref="authPrompt" />
    <UserTransLangsListModalVue
      v-else
      ref="utllModal"
      @lang-selected="$emit('translate-item', $event)"
    />
  </ion-button>
</template>
<script>
import { actionSheetController } from "@ionic/vue";
import {
  ellipsisVertical,
  createOutline,
  warning,
  closeCircleOutline,
  languageOutline,
  documentAttachOutline,
} from "ionicons/icons";

import { useTracker, usePrompts, getUser } from "@/composables";
import { MORE_BTN } from "@/constants/ga-tracking/components";
import { $feed, $relative, $user } from "@/apis";
import $storage from "@/utils/storage";
import AuthPrompt from "@/components/Auth/AuthPrompt.vue";
import UserTransLangsListModalVue from "./UserTransLangsListModal.vue";

export default {
  name: "more-btn",
  components: { AuthPrompt, UserTransLangsListModalVue },
  emits: ["edit-item", "delete-item", "report-item", "translate-item"],
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    disable: {
      type: [Array, String],
      default: "",
    },
    itemType: {
      type: String,
      default: "",
    },
    itemLabel: {
      type: String,
      default: null,
    },
    itemId: {
      type: [Number, String],
    },
    itemParentType: {
      type: String,
      default: "post",
    },
    ownerId: {
      type: Number,
      default: 0,
    },
    editLabel: {
      type: String,
      default: "",
    },
    deleteLabel: {
      type: String,
      default: "",
    },
    reportLabel: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: null,
    },
    promptLabel: {
      type: String,
      default: null,
    },
    canEmbed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isIframe() {
      return window.top !== window.self;
    },
    btnFill() {
      return this.config.fill || "clear";
    },
    btnColor() {
      return this.config.color || "dark";
    },
    btnPosition() {
      return this.config.position || "end";
    },
    iconSlot() {
      return this.config.iconSlot || "icon-only";
    },
    icon() {
      return this.config.icon || ellipsisVertical;
    },
    disabledOptions() {
      const { disable } = this;
      return typeof disable === "string"
        ? disable.split(",").map((d) => d.toLowerCase())
        : disable;
    },
    isDisabled() {
      const { disabledOptions } = this;
      return disabledOptions.includes("all") || disabledOptions.length >= 2; // edit, report, delete
    },
  },
  methods: {
    openActionSheet() {
      const { currentUser, Analytics } = this;

      Analytics("event", {
        category: MORE_BTN.category,
        action: "click",
        label: MORE_BTN.showOptions,
      });
      const buttons = [];
      const disable = this.disabledOptions;

      const {
        ownerId,
        itemType,
        itemId,
        editLabel,
        reportLabel,
        deleteLabel,
        translateLabel,
      } = this;
      // TODO: Remove hardcoded values
      const { userId } = currentUser();
      const itemLabel = (this.itemLabel || itemType).toLowerCase();
      if (!disable.includes("all")) {
        if (!disable.includes("edit") && userId === ownerId) {
          //* EDIT available for only below items
          const canEdit =
            ["FATIHA", "FATIHA:NAMAZ"].includes(itemType) ||
            ["POST", "LIVESTREAM"].includes(itemType);
          if (canEdit) {
            const label = editLabel || "Edit this " + itemLabel;
            buttons.push({
              text: label,
              icon: createOutline,
              className: "fs-16 c-grey1",
              handler: () => {
                console.log("Edit post clicked");
                this.$emit("edit-item", { itemId, itemType });
                return true;
              },
            });
          }
        }
        if (!disable.includes("report") && userId !== ownerId) {
          const label = reportLabel || "Report this " + itemLabel;
          buttons.push({
            text: label,
            icon: warning,
            className: "fs-16",
            handler: () => this.reportItem(),
          });
        }
        if (!disable.includes("delete") && userId === ownerId) {
          const label = deleteLabel || "Delete this " + itemLabel;
          buttons.push({
            text: label,
            icon: closeCircleOutline,
            className: "fs-16 c-red",
            handler: () => this.deleteItem(),
          });
        }
        const canEmbed = this.canEmbed && !this.isGuest();
        if (canEmbed) {
          const label = translateLabel || "Embed this page on your Website";
          buttons.push({
            text: label,
            icon: documentAttachOutline,
            className: "fs-16 c-primary",
            handler: () => this.getEmbedUrl(),
          });
        }
        if (!disable.includes("translate")) {
          const label =
            translateLabel || "Translate tags for this " + itemLabel;
          buttons.push({
            text: label,
            icon: languageOutline,
            className: "fs-16 c-primary",
            handler: () => this.transLangSelector(),
          });
        }
      }
      actionSheetController
        .create({
          header:
            this.promptLabel ||
            "What do you want to do with this " + itemLabel + "?",
          cssClass: "my-custom-class",
          buttons,
        })
        .then((sheet) => sheet.present());
    },
    deleteItem() {
      const {
        itemId,
        itemParentType,
        itemType,
        Analytics,
        toast,
        loader,
        alert,
        confirm,
        isGuest,
        $t,
      } = this;

      if (isGuest()) {
        return this.$refs.authPrompt.handleGuestClick();
      }
      const lItemType = itemType.toLowerCase();
      const itemLabel = this.itemLabel || lItemType.split(":")[0];

      const message = `Are you sure you want to delete this ${itemLabel}? You cannot undo this action!`;

      confirm(message, {
        buttons: {
          cancel: "Cancel",
          confirm: "Yes",
        },
      })
        .then(async () => {
          Analytics("event", {
            category: MORE_BTN.category + ": " + MORE_BTN.deleteBtn,
            action: "click",
            label: lItemType + ":" + itemId,
          });

          const subItemType = lItemType.split(":");
          const loading = await loader("Deleting " + subItemType[0]);

          switch (subItemType[0]) {
            case "fatiha":
              /*$moreOptions.deleteItem({itemId, itemType: itemType[0]})
                .then($toast.hideLoader).catch($toast.hideLoader);*/
              $relative
                .deleteFatiha(itemId)
                .then(async (json) => {
                  const { success } = json || {};
                  if (!success) {
                    await alert(
                      "Could not delete this request at this moment. Please try again later."
                    );
                    return;
                  }
                  toast($t("comps.moreBtn.jsItemDeleted", { item: "Request" }));
                  // $rootScope.$broadcast('$deleted.item', params.itemId);
                })
                .catch((err) => {
                  const { data } = err || { data: err };
                  const msg = data.message || $t("errors.service.general");
                  toast(msg, "error");
                })
                .finally(() => loading.dismiss());
              break;
            case "user": //avatar, status or banner
            case "post":
            case "livestream":
              //TODO: Add more cases here in future when the scope expands
              const canDelete =
                subItemType === "user"
                  ? ["banner", "avatar", "status"].includes(subItemType[1])
                  : true;
              if (canDelete) {
                $feed
                  .deleteItem(itemId)
                  .then(async (json) => {
                    const { success } = json || {};
                    if (!success) {
                      await alert(
                        "Could not delete request. Please try again later."
                      );
                      return;
                    }
                    toast($t("comps.moreBtn.jsItemDeleted", { item: "Post" }));
                    this.$events.emit("$mb.itemDeleted", {
                      itemId,
                      itemType,
                      itemParentType,
                    });
                  })
                  .catch((err) => {
                    const { data } = err || { data: err };
                    console.warn("Error in deleting comment: ", data);
                    const msg =
                      data.message ||
                      `Could not delete this ${subItemType[0]} at the moment. Please try again later.`;
                    toast(msg, "error");
                    Analytics("error", {
                      label: MORE_BTN.apis.deleteItemError + (data.code || msg),
                      isFatal: true,
                    });
                  })
                  .finally(() => loading.dismiss());
              }
              break;
            case "comment":
              $feed.comments
                .deleteCommentReply({
                  commentId: itemId,
                  itemParentType,
                  itemType: "COMMENT",
                })
                .then(async (json) => {
                  const { success } = json || {};
                  if (!success) {
                    await alert(
                      "Could not delete this comment at this moment. Please try again later."
                    );
                    return;
                  }
                  toast($t("comps.moreBtn.jsItemDeleted", { item: "Comment" }));
                  // $rootScope.$broadcast('$deleted.comment', params);
                })
                .catch((err) => {
                  const { data } = err || { data: err };
                  console.warn("Error in deleting comment: ", err);
                  const msg =
                    data.message ||
                    `Could not delete this ${subItemType[0]} at the moment. Please try again later.`;
                  toast(msg, "error");
                })
                .finally(() => loading.dismiss());
              break;
            default:
            // return $toast.error("Error: Cannot process this request at this moment");
          }
          // $toast.showLoader('Deleting..');
        })
        .catch(() => console.log("User bailed out"));
    },
    reportItem() {
      const { itemType, itemId, ownerId, isGuest, Analytics } = this;

      if (isGuest()) {
        return this.$refs.authPrompt.handleGuestClick();
      }
      const lItemType = itemType.toLowerCase();
      const label = lItemType.split(":")[0];
      const { $router, $route } = this;

      Analytics("event", {
        category: MORE_BTN.category + ": " + MORE_BTN.reportBtn,
        action: "click",
        label:
          label + ": " + window.location.origin + $router.resolve($route).href,
      });

      if (lItemType === "page") {
        return;
      }
      let reporters = this.itemReporters || "";
      if (typeof reporters === "string") {
        reporters = reporters.split(",");
      }
      const dataObj = {
        itemId: itemId || null,
        itemType: lItemType || "file",
        itemLabel: label || null,
        itemOwnerId: ownerId,
        itemReporters: reporters,
      };
      this.confirmAndSendReport(dataObj);
    },
    confirmAndSendReport(dataObj) {
      const { itemType, itemLabel, itemId, itemOwnerId } = dataObj;
      const { toast, currentUser, confirm, alert, loader, $t } = this;
      if (!itemId) {
        toast($t("comps.moreBtn.jsErrReporting"), "error");
        return;
      }
      const reporters = dataObj.itemReporters || [];
      const user = currentUser();

      if (reporters.includes(user.userId)) {
        return toast(
          $t("comps.moreBtn.jsAlreadyReported", { item: itemLabel })
        );
        return;
      }
      const msg = $t("comps.moreBtn.jsPromptHeading");
      confirm(msg, { heading: `Report ${itemLabel}?` })
        .then(async () => {
          let item = itemLabel[0].toUpperCase() + itemLabel.substr(1);
          const loading = await loader(
            $t("comps.moreBtn.jsReporting", { itemType })
          );
          const params = {
            itemType: itemType,
            itemId: itemId,
            ownerId: itemOwnerId,
          };
          $feed
            .reportItem(params)
            .then(async (json) => {
              const { success } = json;
              if (!success) {
                await alert($t("comps.moreBtn.jsReportingErr"));
                return;
              }
              toast($t("comps.moreBtn.jsReported", { item }));
            })
            .catch((err) => {
              const { data } = err?.data ? err : { data: err };
              const msg = data.message || $t("comps.moreBtn.jsReportingErr");
              toast(msg, "error");

              Analytics("error", {
                label: MORE_BTN.apis.reportItemError + (data.code || msg),
                isFatal: true,
              });
            })
            .finally(() => loading.dismiss());
        })
        .catch((e) => console.log("User bailed out: ", e));
    },
    transLangSelector() {
      const {
        translate: { languages },
      } = this.currentUser();

      if (!languages) {
        return this.$emit("translate-item");
      }
      const allowed = languages.filter((lang) => lang.status === "GRANTED");
      if (allowed.length <= 1) {
        const [first] = allowed;
        return this.$emit("translate-item", first?.lang_code);
      }
      this.$refs.utllModal.openModal();
    },
    async getExternalShareToken() {
      const { $t, toast } = this;
      const loader = await this.loader($t("common.plzWait"));
      return $user
        .getExternalShareToken()
        .then((json) => {
          const { external_token } = json;
          $storage.set("token.ext_embed", external_token);
          return external_token;
        })
        .catch((e) => {
          toast($t("errors.service.general"));
          console.log("Erorr in getting token: ", e);
        })
        .finally(() => loader.dismiss());
    },
    async getEmbedUrl() {
      let externalEmbedToken = $storage.get("token.ext_embed"); // token for embedding page in external websites
      if (!externalEmbedToken) {
        externalEmbedToken = await this.getExternalShareToken();
        console.log("token: ", externalEmbedToken);
      }
      if (externalEmbedToken) {
        const {
          location: { protocol, host, pathname },
        } = window;
        const newPath = pathname
          .split("/")
          .filter((i) => !!i)
          .map((chunk, index) => {
            if (index > 0) {
              return chunk.replace(/\-/g, "_");
            }
            return chunk;
          })
          .join("-");
        const { userId } = this.currentUser();
        const pageUrl =
          protocol +
          "//" +
          host +
          "/external/" +
          newPath +
          "?token=" +
          externalEmbedToken +
          "&sharer=" +
          userId;
        const url = `<br /><strong style="text-wrap: normal;">${pageUrl}</strong>`;
        const { $t, toast } = this;
        try {
          navigator.clipboard.writeText(pageUrl);
          // toast("URL copied to clipboard!");
          toast("URL Copied: " + pageUrl);
        } catch (e) {
          toast({
            message: "Use this URL in your website: " + url,
            buttons: [{ role: "cancel", text: $t("common.dismiss") }],
          });
        }
      }
    },
  },
  setup() {
    const { Analytics } = useTracker();
    const { toast, loader, alert, confirm } = usePrompts();
    const { isGuest, currentUser } = getUser();

    return {
      Analytics,
      toast,
      loader,
      alert,
      confirm,
      isGuest,
      currentUser,
    };
  },
};
</script>
<style lang="scss">
.more-btn {
  margin-top: 0;
  margin-right: 0;
  --padding-start: 8px;
  --padding-end: 0;
  ion-icon {
    @include fs(20px);
  }
  &:part(native) .button {
    padding: 0;
  }
  &[size="small"] ion-icon {
    @include fs(14px);
  }
}
</style>
