// keys here refer to state-names. May not be same as keys in tags-file
const META = Object.freeze({
  titleBranding: "meta.titleBranding",
  homePage: {
    title: "meta.home.title",
    titleBranding: false,
    description: "meta.home.description",
  },
  homeTab: {
    title: "meta.feed.title",
    description: "meta.feed.description",
  },
  mediaTab: {
    title: "meta.mediaHome.title",
    description: "meta.mediaHome.description",
  },
  toolsTab: {
    title: "meta.toolsHome.title",
    description: "meta.toolsHome.description",
  },
  tasbeehCounter: {
    title: "meta.tasbeehCounter.title",
    titleBranding: false,
    description: "meta.tasbeehCounter.description",
    image: "https://drive.google.com/uc?id=15nFzeSycbyQxI35-n8Dpy5tZYVnvONZr",
  },
  UserAboutPage: {
    title: "meta.accountHome.title",
    description: "meta.accountHome.description",
  },
});
export default META;
