<template>
  <bare-modal
    ref="modal"
    :breakpoints="[0, 1]"
    :initial-breakpoint="1"
    class="langPrompt"
    :title="promptTitle"
    :color="isDarkTheme() ? 'light' : 'primary'"
  >
    <template #content>
      <div class="ion-padding-horizontal">
        <p class="fs-14">{{ $t("comps.langPrompt.desc.p1", { countryName: country.name }) }}</p>
      </div>
      <div>
        <ion-radio-group v-model="langCodeChosen" @ionChange="loadLocaleTranslations()">
          <ion-item lines="full">
            <ion-radio
              mode="md"
              :value="country.langCode"
              justify="space-between"
              label-placement="start"
            >
              <ion-text>
                <component :is="langCodeChosen === country.langCode ? 'strong' : 'p'">
                  {{
                  $t("comps.langPrompt.choices.useLang.0", {
                  langName: countryLangLabel,
                  })
                  }}
                </component>
              </ion-text>
            </ion-radio>
          </ion-item>
          <ion-item lines="full">
            <ion-radio mode="md" value="en" justify="space-between" label-placement="start">
              <ion-text>
                <component :is="langCodeChosen === 'en' ? 'strong' : 'p'">Continue in English</component>
              </ion-text>
            </ion-radio>
          </ion-item>
          <ion-button
            v-if="languages.length"
            size="block"
            fill="clear"
            color="primary"
            shape="normal"
            @click="$refs.langModal.openModal()"
          >See more options</ion-button>
        </ion-radio-group>
      </div>
      <ion-item color="light">
        <ion-button slot="start" fill="clear" color="medium">{{ $t("common.cancel") }}</ion-button>

        <ion-button
          slot="end"
          fill="clear"
          color="primary"
          :disabled="!languages.length"
          @click="saveSelection"
        >{{ $t("common.saveChanges", {}, 1) }}</ion-button>
      </ion-item>
    </template>
  </bare-modal>
  <!--@ready event triggers the load-event for this component -->
  <change-lang-modal
    ref="langModal"
    @ready="onHasLanguages"
    @lang-changed="$refs.modal.closeModal()"
    :country-code="country.code"
    :country-lang="country.langCode"
  />
</template>

<script lang="js">
import { $language, $device } from "@/apis";
import { getFlagUrl } from "@/utils/fns";
import { setI18nLanguage } from "@/i18n";
import { useFormatters, usePrompts } from "@/composables";
import { IonRadioGroup, IonRadio,IonText, } from "@ionic/vue";
import ChangeLangModal from "./ChangeLangModal.vue";
import BareModal from "@/components/BareModal.vue";
import langaugeMixin from "@/mixins/language"

export default {
  mixins: [langaugeMixin],
  components: { BareModal, ChangeLangModal, IonRadioGroup, IonRadio,IonText, },
  emits: ['closed'],
  computed: {
    promptTitle() {
      const {
        langCodeChosen,
        languages,
        $t,
      } = this;
      const langObj = languages.find(
        (lang) => lang.code === langCodeChosen
      );
      return $t("comps.langPrompt.title", { langLabel: langObj && langObj.label });
    },
    countryLangLabel() {
      const { country, languages } = this;
      return languages.find(
        (lang) => lang.code === country.langCode
      ).label;
    }
  },
  data() {
    return {
      langCodeChosen: null,
      country: {
        name: '',
        code: '',
        flag: '',
        langCode: ''
      },
      languages: [],// from child-component
    };
  },
  methods: {
    saveSelection() {
      this.saveLangChoice();//from mixin
      this.$refs.modal.closeModal();
    },
    onModalDismiss(saved) {
      if (saved !== true) {
        const {code: langCode} = $language.getSelectedLang();
        setI18nLanguage(langCode);// revert to previous (or default) selection
      }
      this.$emit('closed');
    },
    onHasLanguages(languages) {
      this.languages = languages;
      console.log('lang-modal ready')
      // if lang was not set by user earlier, prompt him
      const { code } = $language.getSelectedLang();
      const { defaultLang, userLangSelected } = $language;
      const hasNotSelectedLang =
      code === defaultLang.code && userLangSelected === "NONE";
      if (hasNotSelectedLang) {
        console.log('showing modal now')
        $device.getDefaultLocation().then((json) => {
          console.log("location-json: ", json);
          const {
            countryName: name,
            countryCode2,
            languages: langsInCountry,
            countryFlag: flag,
          } = json;
          const countryLangCode = langsInCountry.split(",").shift().split("-").pop().toLowerCase(); // eg. for "nl-NL,fy-NL"
          const isValidLang = languages.find(lang => lang.code === countryLangCode);// if we support this lang
          if (isValidLang) {
            this.langCodeChosen = countryLangCode.toLowerCase();
          }

          this.country = {
            name,
            code: countryCode2.toUpperCase(),
            langCode: this.langCodeChosen,
            flag
          };// setting this enables the lang-modal component
          setTimeout(() => {
            this.loadLocaleTranslations(true)
            this.$refs.modal.openModal();
          }, 100);
        })
      } else { // Lang already selected. No need to show prompt (app-lang will be set already by LangModal)
        this.langCodeChosen = code;
        // setting values mounts the lang-modal and triggers the 'mounted' fn. Wait for emit of 'ready event'
        console.log('setting lang: ', code);
        this.$emit('closed');// when value is already set, inform parent
        this.loadLocaleTranslations(true);
      }
    }
  },
  setup() {
    const { loader } = usePrompts();
    const { isDarkTheme } = useFormatters();

    return {
      getFlagUrl,
      loader,
      isDarkTheme
    };
  },
};
</script>

<style lang="scss">
.lang-selection-prompt {
  ion-content p {
    margin-bottom: 0;
  }
}
</style>
