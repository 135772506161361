
const shiaAdhaans = [
  {
    name: "Beautiful Shia Azaan",
    path: 'https://drive.google.com/uc?id=1m_rgWpA5tjUhU3Dudbo8MIsZL_a_IUGx'
  },
  {
    name: "Shia Azaan from Iran",
    path: 'https://drive.google.com/uc?id=1W3LLg-579IRrnyArtoZK_B8tNrG9-0aU'
  },
  {
    name: "Beautiful Shia Azaan from Iraq",
    path: 'https://drive.google.com/uc?id=1t13oqmoPFEg09FZz8-SgvLq9kdF3ZATB'
  },
  {
    name: "Shia Azaan by Moazenzadeh",
    path: 'https://drive.google.com/uc?id=1XPETxFHHOZKdltdzAkny3iYXxlo1uk5v'
  },
  {
    name: "Shia Azaan by Kid",
    path: 'https://drive.google.com/uc?id=1imUQF7AgqzkpjupnmHJhMYkGjGiteUZq'
  },
  {
    name: "Shia Azaan",
    path: 'https://drive.google.com/uc?id=1NcOY1hSkDAetEFWUjLycHJiU5tCqLHd9'
  },
  {
    name: "Shia Azaan by Tahir Qawwal",
    path: 'https://drive.google.com/uc?id=1eRCvRVM4AfMKE-Qub0I2tkQewZUkHLeU'
  }
];

//note: indexes are the values
const calculationMethods = [
  "Shia Ithna-Ansari",
  "University of Islamic Sciences, Karachi",
  "Islamic Society of North America",
  "Muslim World League",
  "Umm Al-Qura University, Makkah",
  "Egyptian General Authority of Survey",
  "Institute of Geophysics, University of Tehran",
  "Gulf Region",
  "Kuwait",
  "Qatar",
  "Majlis Ugama Islam Singapura, Singapore",
  "Union Organization islamic de France",
  "Diyanet İşleri Başkanlığı, Turkey",
  "Spiritual Administration of Muslims of Russia",
];
const dateLabels = {
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
};

export { shiaAdhaans, calculationMethods, dateLabels };