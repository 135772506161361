const AUTH = Object.freeze({
  regex: {
    EMAIL: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    // EMAIL: '/[a-z0-9._]+@[a-z0-9.]?+[a-z]{,3}/i',
    PASSWORD: '^[a-z0-9!@#$%^&]+$',
    NAME: '([a-z.]{2,}\s?)+',
    MOBILE: '^\\+91-[6-9][0-9]{9}$_VS_^\\+\\d+-[0-9]{6,14}[0-9]$'
  },
  validators: {
    password: 8
  }
});
export { AUTH };