import { createRouter, createWebHistory } from "@ionic/vue-router";
import { useTracker, getUser } from "@/composables";
import { $user } from "@/apis";
import { getJsonFromUrl } from "@/utils/fns";
import Tabs from "@/views/Tabs.vue";

const { sendPageView } = useTracker();

const blockiFrames = () => {
  const isIframe = window.top !== window.self;
  if (isIframe) {
    document.querySelector("body").classList.remove("loading");
    return false;
  }
  return true;
};

const iFrameTokenCheck = async (to, from) => {
  const {
    query: { token, sharer },
    params: { componentName },
  } = to;
  const isIframe = window.top !== window.self;

  if (!token || !componentName || !isIframe) {
    return {
      name: "404",
      params: { errorPage: "404-page-not-found" },
      replace: true,
    };
  }
  try {
    const { success } = await $user.verifyGuestToken("sharer", sharer, token);
    // console.log("validation: ", success);
    return success;
  } catch (e) {
    console.error("validation error: ", e);
    return {
      name: "404",
      params: { errorPage: "404-page-not-found" },
      replace: true,
    };
  }
};

const routes = [
  {
    path: "",
    name: "homePage",
    meta: {
      fullWidth: true,
    },
    component: () => import("@/views/HomePage.vue"),
  },
  {
    path: "/",
    component: Tabs,
    name: "tabs",
    children: [
      {
        name: "homeTab",
        path: "feed",
        component: () => import("@/views/tabs/HomeTab/PostsList.vue"),
      },
      {
        name: "postDetail",
        path:
          "feed/:itemId([0-9]+)/:postType(post|livestream|user-avatar|user-cover|fatiha|fatiha-namaz|ziyarat-request)?",
        component: () => import("@/views/tabs/HomeTab/PostDetail.vue"),
      },
      {
        name: "myHaadiQuestions",
        path: "ask-haadi/:action(new-question)?",
        component: () => import("@/views/tabs/HomeTab/HaadiHome.vue"),
      },
      {
        name: "askHaadi",
        path: "ask-haadi/:conversationId",
        component: () => import("@/views/tabs/HomeTab/AskHaadi.vue"),
        props: true, // to pass msgs via route
      },
      {
        path: "search/:section?",
        name: "searchPage",
        component: () => import("@/views/SearchPage.vue"),
      },
      {
        path: "media",
        name: "mediaTab",
        component: () => import("@/views/tabs/MediaTab/MediaTab.vue"),
      },
      {
        path: "media/:type(nauhe|soaz|munajaat)/:tab(all|favorites)?",
        name: "nauheSoazList",
        component: () => import("@/views/tabs/MediaTab/MediaNauhe.vue"),
      },
      {
        path: "media/majalis/:tabName(audio|video)?",
        name: "majalisList",
        component: () => import("@/views/tabs/MediaTab/MediaMajalis.vue"),
      },
      {
        path: "media/books",
        name: "booksList",
        component: () => import("@/views/tabs/MediaTab/Books/BooksList.vue"),
      },
      {
        path: "media/books/:bookId/:title?",
        name: "bookDetail",
        component: () => import("@/views/tabs/MediaTab/Books/BookDetails.vue"),
      },
      {
        path: "media/duas",
        name: "duaHome",
        component: () => import("@/views/tabs/MediaTab/Duas/DuaHome.vue"),
      },
      {
        path: "media/duas/:month/:tags?",
        name: "duaList",
        component: () => import("@/views/tabs/MediaTab/Duas/DuaList.vue"),
      },
      {
        path: "tools",
        name: "toolsTab",
        component: () => import("@/views/tabs/ToolsTab/ToolsTab.vue"),
      },
      {
        path: "tools/:viewName(alarms|ramadan-sehri-wakeup-call)",
        name: "dailyAlarms",
        component: () => import("@/views/tabs/ToolsTab/Alarms.vue"),
      },
      {
        path: "tools/ques-ans",
        name: "qnaList",
        component: () => import("@/views/tabs/ToolsTab/QnaList.vue"),
      },
      {
        path: "tools/ques-ans/:quesId",
        name: "qnaDetail",
        component: () => import("@/views/tabs/ToolsTab/QnaDetail.vue"),
      },
      {
        path: "tools/my-khums",
        name: "khumsHome",
        component: () => import("@/views/tabs/ToolsTab/Khums/KhumsHome.vue"),
      },
      {
        path: "tools/my-khums/calculator",
        name: "khumsCalculator",
        component: () =>
          import("@/views/tabs/ToolsTab/Khums/KhumsCalculatorPage.vue"),
      },
      {
        path: "tools/my-khums/:calcId([0-9a-zA-Z+=]+)",
        name: "khumsDetails",
        component: () => import("@/views/tabs/ToolsTab/Khums/KhumsDetails.vue"),
        beforeEnter(from, to, next) {
          const { isGuest } = getUser();
          if (isGuest()) {
            next({ name: "404", params: { errorPage: "unauthorized-access" } });
            return;
          }
          next();
        },
      },
      {
        path: ":userId([0-9]+|guest)/",
        name: "userTab",
        alias: "@:username(me|[a-zA-Z0-9_]+)",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/tabs/UserTab/UserTab.vue"
          ),
        props: (route) => ({
          userId:
            route.params?.userId !== "guest"
              ? Number(route.params.userId)
              : "guest",
        }),
        children: [
          {
            path: "",
            name: "UserAboutPage",
            component: () =>
              import(
                /* webpackChunkName: "group-user" */ "@/components/UserProfile/UserAbout.vue"
              ),
          },
          {
            path: "posts",
            name: "UserPostsPage",
            component: () =>
              import(
                /* webpackChunkName: "group-user" */ "@/components/UserProfile/UserPosts.vue"
              ),
          },
          {
            path: "marhumeen",
            name: "UserMarhumeenPage",
            component: () =>
              import(
                /* webpackChunkName: "group-user" */ "@/components/UserProfile/UserMarhumeen.vue"
              ),
          },
          {
            path: "my-khums",
            name: "UserKhumsPage",
            component: () =>
              import(
                /* webpackChunkName: "group-user" */ "@/components/UserProfile/UserKhums.vue"
              ),
          },
          {
            path: "settings",
            name: "UserSettingsPage",
            component: () =>
              import(
                /* webpackChunkName: "group-user" */ "@/components/UserProfile/UserSettings.vue"
              ),
          },
          {
            path: "admin",
            name: "AdminPage",
            component: () =>
              import(
                /* webpackChunkName: "group-user" */ "@/views/tabs/UserTab/AdminPage.vue"
              ),
          },
        ],
      },
      {
        path: ":platform(android|ios)-app",
        name: "downloadPage",
        component: () => import("@/views/DownloadPage.vue"),
      },
    ],
  },
  // tests a (static) url path (saved in DB), (eg, /x2i3j), and redirects to it's expanded version
  {
    path: "/r/:checkPath",
    async beforeEnter(to, from, next) {
      const { checkPath } = to.params;
      console.warn("beforeEnter: ", checkPath);
      try {
        // is a code from shortco.de
        if (checkPath.match(/\.S$/)) {
          try {
            // https://api.shrtco.de/v2/info?code=example
            const data = await getJsonFromUrl(
              `https://api.shrtco.de/v2/info?code=${checkPath
                .split(".")
                .shift()}`
            );
            const { ok, result } = data;
            // console.log("result: ", data);
            if (ok) {
              window.location = result.url; // because it returns absolute url
              return false;
            }
          } catch (e) {}
        }
        const { success, url } = await $user.getRedirectUrl(checkPath);
        if (success) {
          next(url);
        } else {
          next({ name: "404", params: { errorPage: "404-page-not-found" } });
        }
      } catch (e) {
        next({ name: "404", params: { errorPage: "404-page-not-found" } });
      }
    },
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgotPwd",
    meta: {
      // fullWidth: true,
    },
    component: () => import("@/components/Auth/ForgotPwd.vue"),
    // prevents landing on auth-page for logged-in users
    beforeEnter(from, to, next) {
      const { isGuest } = getUser();
      if (!isGuest()) {
        console.log("isGuest", isGuest(), from);
        next("/feed");
        return;
      }
      next();
    },
  },
  {
    path: "/reset-password/:token?",
    name: "resetPwd",
    meta: {
      // fullWidth: true,
    },
    component: () => import("@/components/Auth/ResetPwd.vue"),
    // prevents landing on auth-page for logged-in users
    beforeEnter(from, to, next) {
      const { isGuest } = getUser();
      if (!isGuest()) {
        next("/");
        return;
      }
      next();
    },
  },
  {
    path: "/:page(login|signup)",
    name: "authPage",
    meta: {
      fullWidth: true,
    },
    component: () => import("@/views/auth/LoginSignup.vue"),
    // prevents landing on auth-page for logged-in users
    beforeEnter(from, to, next) {
      const { isGuest } = getUser();
      if (!isGuest()) {
        // console.log("to: ", from, to);
        const {
          query: { back },
        } = from;
        try {
          const gotoUrl = back ? atob(back) : to;
          next(gotoUrl);
        } catch (e) {
          next(to || "/");
        }
        return;
      }
      next();
    },
  },
  {
    path: "/welcome/:action(verify-account|setup-profile|setup-preferences)",
    name: "onboardingPage",
    component: () => import("@/views/auth/UserOnboarding.vue"),
    // prevents landing on page for guest users
    beforeEnter(from, to, next) {
      const { isGuest } = getUser();
      if (isGuest()) {
        next(from || "/");
        return;
      }
      next();
    },
  },
  {
    path: "/notifications",
    name: "notificationsPage",
    component: () => import("@/views/NotificationsPage.vue"),
    // prevents landing on page for guest users
    beforeEnter(from, to, next) {
      const { isGuest } = getUser();
      if (isGuest()) {
        next(from || "/");
        return;
      }
      next();
    },
  },
  {
    path: "/payment/:status(cancelled|success|failed|pending|check)",
    name: "postPayment",
    component: () => import("@/components/payments/PostPayment.vue"),
  },
  {
    path: "/request-ziyarat",
    alias: "/request-ziyarat-haram-imam-husain",
    name: "requestZiyaratPage", // alternative of modal, when user lands directly. Better for SEO
    component: () => import("@/components/RequestZiyaratModal.vue"),
  },
  {
    path: "/external/:componentName", // allows component access directly via URL (for vendors)
    name: "ExternalView",
    component: () => import("@/components/ExternalVendors.vue"),
    beforeEnter: iFrameTokenCheck,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/:errorPage(.*)",
    name: "404",
    meta: {
      fullWidth: true,
    },
    component: () => import("@/views/Error404.vue"),
  },
];

routes.map((route) => {
  route.beforeEnter = route.beforeEnter || blockiFrames;
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  const { path: fromPath } = from;
  const { path: toPath } = to;
  if (!router.stack || ["/login", "/signup"].includes(toPath)) {
    router.stack = [];
  }
  if (fromPath === "/") {
    router.stack.push(toPath);
  } else if (fromPath !== toPath) {
    const [, prevPath] = [...router.stack].reverse();
    // console.log('prev path: ', prevPath);
    if (toPath === prevPath) {
      router.stack.pop();
    } else {
      router.stack.push(toPath);
    }
  }

  //fix for invisible ion-page after navigation
  setTimeout(() => {
    const main = document.getElementById("main-content");
    main
      ?.querySelector(".ion-page.ion-page-invisible")
      ?.classList?.remove("ion-page-invisible");
  }, 3000);

  setTimeout(() => {
    if (!document.querySelector("head").getAttribute("has-title")) {
      document.title = document.querySelector(
        "ion-router-outlet .ion-page ion-header ion-toolbar ion-title"
      )?.innerText;
    }
  }, 100);

  sendPageView({
    title: to.title,
    url: window.location.origin + to.fullPath,
  });
});

export { router, routes };
