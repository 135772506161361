<template>
  <div class="audio-player">
    <!-- timer and track title -->
    <div class="track-info" ref="track">
      <ion-button
        v-show="minified"
        fill="clear"
        size="small"
        :disabled="disablePlay"
        v-track-this="{
          category: TRK.category.audio,
          event: 'click',
          label: TRK.playAudio,
          value: isPlaying ? 0 : 1,
        }"
        @click="$emit('toggle-play')"
      >
        <ion-spinner v-if="isLoading" icon="android" />
        <ion-icon v-else :icon="isPlaying ? icons.pause : icons.play" />
      </ion-button>

      <ion-row @click="canMinify ? toggleMinified() : null">
        <ion-col size="2" v-html="currentTimeLabel" />
        <ion-col size="8" class="ion-text-center">
          <p class="track-title" v-html="trackLabel" />
        </ion-col>
        <ion-col size="2" class="ion-text-right" v-html="durationLabel" />
      </ion-row>
    </div>
    <!-- progress indicator -->
    <div class="progress">
      <div class="progress-bg">
        <div class="progress-buffer hidden"></div>
      </div>
      <div class="range-container">
        <ion-range
          mode="ios"
          color="danger"
          :value="currentTime"
          @ionChange="($event) => $emit('seek-audio', $event.detail.value)"
          :min="0"
          :max="audio.duration || 1"
          :step="1"
          :disabled="!audio.duration || audio.errored"
          style="--data-val: {{((currentTime/audio.duration)*100).toFixed(2)}}%;"
          v-track-this="{
            category: TRK.category.audio,
            event: 'blur',
            label: TRK.seekAudio,
            value: ((currentTime / audio.duration) * 100).toFixed(2),
          }"
        />
      </div>
    </div>

    <!-- action buttons (play, pause, etc.) -->
    <ion-item class="buttons" lines="none">
      <ion-button
        slot="start"
        size="small"
        fill="clear"
        :color="repeat ? 'primary' : 'dark'"
        class="reload"
        v-track-this="{
          category: TRK.category.audio,
          event: 'click',
          label: TRK.repeatAudio,
          value: repeat,
        }"
        @click="setRepeat"
      >
        <ion-icon :icon="icons.reloadOutline" />
        <span v-if="repeat" class="badge">
          {{ repeat == 1 ? "1" : "All" }}
        </span>
      </ion-button>

      <ion-grid>
        <ion-row>
          <ion-col size-md="1">
            <ion-button
              fill="clear"
              color="dark"
              size="small"
              v-track-this="{
                category: TRK.category.audio,
                event: 'click',
                label: TRK.preAudio,
              }"
              @click="($event) => $emit('play-prev', $event)"
              :disabled="disablePrev"
            >
              <ion-icon :icon="icons.playSkipBack" />
            </ion-button>
          </ion-col>
          <ion-col size-md="1">
            <ion-button
              class="play"
              fill="clear"
              color="dark"
              v-track-this="{
                category: TRK.category.audio,
                event: 'click',
                label: TRK.playAudio,
                value: isPlaying ? 0 : 1,
              }"
              @click="
                ($event) => (loading ? null : $emit('toggle-play', $event))
              "
            >
              <ion-spinner v-if="isLoading" icon="android" />
              <ion-icon v-else :icon="isPlaying ? icons.pause : icons.play" />
            </ion-button>
          </ion-col>
          <ion-col size-md="1">
            <ion-button
              fill="clear"
              color="dark"
              size="small"
              v-track-this="{
                category: TRK.category.audio,
                event: 'click',
                label: TRK.nextAudio,
              }"
              @click="($event) => $emit('play-next', $event)"
              :disabled="disableNext"
            >
              <ion-icon :icon="icons.playSkipForward" />
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-button
        v-if="!hasList"
        slot="end"
        fill="clear"
        color="danger"
        @click="$emit('close-player')"
        v-track-this="{
          category: TRK.category.video,
          event: 'click',
          label: TRK.closeBtn + '-lower',
        }"
      >
        <ion-icon :icon="icons.closeOutline" />
      </ion-button>
      <ion-button
        v-else
        fill="clear"
        color="primary"
        @click="$emit('open-modal')"
        v-track-this="{
          category: 'MediaPlayer',
          event: 'click',
          label: 'OpenModal',
        }"
      >
        <ion-icon :icon="icons.listCircleOutline" />
      </ion-button>
    </ion-item>
  </div>
</template>

<script>
import { usePrompts, AudioTrack } from "@/composables";
import { ref } from "vue";
import { getReadableTime } from "@/utils/fns";
import { IonRange, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { MEDIA_PLAYER } from "@/constants/ga-tracking/components";

import {
  chevronDown,
  closeOutline,
  play,
  pause,
  reloadOutline,
  playSkipBack,
  playSkipForward,
  expandOutline,
  listCircleOutline,
} from "ionicons/icons";
import $storage from "@/utils/storage";

export default {
  components: { IonRange, IonGrid, IonRow, IonCol },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    canMinify: {
      type: Boolean,
      default: true,
    },
    disablePlay: {
      type: Boolean,
      default: false,
    },
    disablePrev: {
      type: Boolean,
      defaut: true,
    },
    disableNext: {
      type: Boolean,
      defaut: true,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    trackLabel: {
      type: String,
      default: "",
    },
    audio: {
      type: Object,
      default: null,
    },
    hasList: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "seek-audio",
    "toggle-play",
    "toggle-minify",
    "play-prev",
    "play-next",
    "close-player",
    "open-modal",
  ],
  computed: {
    currentTimeLabel() {
      return getReadableTime(this.currentTime || 0);
    },
    durationLabel() {
      return getReadableTime(this.audio.duration || 0);
    },
  },
  methods: {
    toggleMinified() {
      this.minified = 1 - this.minified; //note: save action in LS
      $storage.set("player.mini", this.minified ? 1 : 0);

      const $tabs = document.querySelector("ion-tabs");
      //NOTE: Add 'larger' class to current view to expand content view
      this.minified
        ? $tabs.classList.add("larger")
        : $tabs.classList.remove("larger");
    },
  },
  setup(props, { emit }) {
    const { toast } = usePrompts();
    const { getRepeatValue, setRepeatValue } = AudioTrack();
    const repeat = ref(getRepeatValue());
    let minified;
    if (props.canMinify) {
      minified = ref($storage.get("player.mini", 0));
      emit("toggle-minify", minified);
    }

    const setRepeat = () => {
      repeat.value = repeat.value + 1;
      if (repeat.value > 2) repeat.value = 0;
      setRepeatValue(repeat.value);
    };

    return {
      toast,
      repeat,
      minified,
      setRepeat,
      TRK: MEDIA_PLAYER,
      icons: {
        chevronDown,
        closeOutline,
        play,
        pause,
        reloadOutline,
        playSkipBack,
        playSkipForward,
        expandOutline,
        listCircleOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
