import { ref } from "vue";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { actionSheetController } from "@ionic/vue";
import { usePrompts } from "@/composables/prompts";
import { image, camera } from "ionicons/icons";
// import { Filesystem, Directory } from "@capacitor/filesystem";
// import { Storage } from "@capacitor/storage";

const photos = ref([]);

export function getPicture() {
  const $prompts = usePrompts();
  const fromGallery = (options = {}) => {
    return Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      quality: 100,
      ...options,
    });
  };

  const fromCamera = (options = {}) => {
    return Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
      ...options,
    });
    /*const fileName = new Date().getTime() + ".jpeg";
    const savedFileImage = {
      filepath: fileName,
      webviewPath: cameraPhoto.webPath,
    };

    photos.value = [savedFileImage, ...photos.value];*/
  };

  const promptForSource = async (
    options = {
      header: "",
      cssClass: "",
      mediaOptions: {},
      append: null,
      prepend: null,
    }
  ) => {
    const { header, cssClass, mediaOptions, append, prepend } = options;
    const buttons = [];
    if (prepend) {
      buttons.push(prepend);
    }
    buttons.push({
      text: "Photos from Gallery",
      role: "gallery",
      icon: image,
      // handler: () => this.addSection("gallery"),
    });
    buttons.push({
      text: "Photos from Camera",
      role: "camera",
      icon: camera,
      // handler: () => this.addSection("camera"),
    });
    if (append) {
      buttons.push(append);
    }
    const actionSheet = await actionSheetController.create({
      header: header || "Add pictures from",
      cssClass: cssClass || "my-custom-class",
      buttons,
    });
    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
    if (role === "camera") {
      return fromCamera(mediaOptions);
    } else if (role === "gallery") {
      return fromGallery(mediaOptions);
    } else {
      //shows toast (returns promise) and returns null, so add check on return object in promise.then block
      return role === "custom"
        ? null
        : $prompts.toast("No image selected").then(() => null);
    }
  };

  return {
    photos,
    promptForSource,
    fromCamera,
    fromGallery,
  };
}
