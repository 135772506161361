<template>
  <ion-header class="modal-navbar" :collapse="collapse">
    <ion-toolbar :color="color">
      <ion-buttons v-if="backIcon" slot="start">
        <ion-button
          :color="altColor"
          fill="clear"
          @click="$emit('navbar-back')"
        >
          <ion-icon
            :icon="
              typeof backIcon === 'boolean'
                ? icons.chevronBackOutline
                : backIcon
            "
          />
        </ion-button>
      </ion-buttons>

      <ion-title
        v-if="title"
        v-html="title"
        :color="showsOnDesktop && color !== 'primary' ? 'primary' : altColor"
        mode="md"
      />

      <ion-buttons slot="end">
        <slot v-if="showCloseBtn" name="closeButton">
          <ion-button
            slot="end"
            fill="clear"
            :color="altColor"
            v-track-this="closeIcon ? {} : trackClose"
            @click="$emit('navbar-close')"
          >
            <ion-icon
              :icon="closeIcon && closeIcon !== true ? closeIcon : icons.close"
            />
          </ion-button>
        </slot>
        <slot v-else-if="$slots.endBtn" name="endBtn"></slot>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar
      v-if="hasDefaultSlot()"
      :color="subHeaderColor || color"
      class="secondary-toolbar"
    >
      <slot></slot>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonTitle, IonHeader, IonButtons, isPlatform } from "@ionic/vue";
import { close, chevronBackOutline } from "ionicons/icons";

export default {
  components: { IonTitle, IonHeader, IonButtons },
  emits: ["navbar-close", "navbar-search", "navbar-back"],
  props: {
    color: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: null,
    },
    // color alternate to background
    altColor: {
      type: String,
      default: "light",
    },
    subHeaderColor: {
      type: String,
      default: null,
    },
    trackClose: {
      type: Object,
      default: null,
    },
    closeIcon: {
      type: [Boolean, String],
      default: null,
    },
    backIcon: {
      type: [Boolean, String],
      default: false,
    },
    //to make header fade away on scroll
    collapse: {
      type: String,
      default: "fade",
    },
  },
  computed: {
    hasCloseBtnSlot() {
      return this.$slots.closeButton;
    },
    showCloseBtn() {
      return this.trackClose || this.closeIcon || this.hasCloseBtnSlot;
    },
    showsOnDesktop() {
      return !isPlatform("mobileweb");
    },
  },
  setup(props, { slots }) {
    const hasDefaultSlot = () => {
      const defaultSlot = slots.default;
      const [slot] = defaultSlot ? defaultSlot() : [null];
      return slot && slot.props;
    };

    return {
      isPlatform,
      hasDefaultSlot,
      icons: {
        close,
        chevronBackOutline,
      },
    };
  },
};
</script>

<style lang="scss">
.modal-navbar {
  ion-toolbar {
    ion-buttons[slot="start"] {
      height: 48px;
      & + ion-search-bar {
        --padding-start: 0;
      }
    }
    ion-back-button {
      min-width: 28px;
      &::part(native) {
        --padding-start: 0px;
        --padding-end: 8px;
      }
    }
    // left-padding when backicon is there
    ion-buttons + ion-title {
      padding-left: 0;
    }
    &:first-child {
      ion-searchbar {
        --box-shadow: none;
        padding-bottom: 0;
        input {
          // padding-inline-start: 16px !important;// was causing bug in search-bar for searchpage
        }
      }
    }
    ion-searchbar:not(.custom) {
      --border-radius: 4px;
      input:not(:has(+ ion-icon)) {
        padding-left: 8px !important;
      }
      // skip for searchbars where color was specified
      &:not(.ion-color) {
        @media (prefers-color-scheme: dark) {
          color: var(--ion-color-light) !important;
        }
      }
    }
    &.ion-color-light {
      @media (prefers-color-scheme: dark) {
        --background: var(--ion-color-light-shade) !important;
        --ion-color-base: var(--ion-color-light-shade) !important;
      }
    }
    .bell-icon {
      --color: var(--ion-color-light) !important;
      margin: 0;
      @media (prefers-color-scheme: dark) {
        --color: var(--ion-color-medium) !important;
      }
    }
    ion-title {
      @media (prefers-color-scheme: dark) {
        color: var(--ion-color-dark-shade);
      }
    }
  }
  [mode="ios"] & {
    // padding fix for ios
    ion-buttons + ion-title {
      // padding-left: 90px;// don't know why it was done in first place
    }
  }
}
ion-modal .navbar {
  ion-toolbar:first-child,
  ion-toolbar:first-child {
    display: flex !important;
  }
}
</style>
