<template>
  <lang-modal
    ref="langModal"
    :search-lang="searchLang"
    :selected-lang="langSelected"
    :country-code="countryCode"
    :country-lang="countryLang"
    @dismiss="onClose"
    @ready="$emit('ready', $event)"
  >
    <template #header>
      <modal-nav-bar
        :title="$t('comps.userSettingsModal.title.setLang')"
        :close-icon="true"
        @navbar-close="$refs.langModal.closeModal()"
      >
        <ion-searchbar
          :placeholder="$t('comps.userSettingsModal.changeLang.searchYourLang')"
          inputmode="search"
          type="search"
          v-model="searchLang"
          autocomplete="language"
          class="custom"
          color="light"
        />
      </modal-nav-bar>
    </template>
    <template #intro>
      <p
        class="ion-padding-horizontal fs-14"
        v-html="$t('comps.userSettingsModal.changeLang.desc')"
      />
    </template>
    <template #footer>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            slot="start"
            fill="clear"
            color="danger"
            @click="$refs.langModal.closeModal()"
          >
            {{ $t("common.cancel") }}
          </ion-button>
          <ion-button
            slot="end"
            fill="clear"
            color="primary"
            @click="$refs.langModal.saveSelection()"
            v-track-this="{
              event: TRK.saveBtn.event,
              category: TRK.category,
              label: TRK.saveBtn.label,
            }"
          >
            {{ $t("common.saveChanges") }}
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </template>
  </lang-modal>
</template>

<script lang="js">
import { IonSearchbar } from '@ionic/vue';
import { USER_SETTINGS as TRK } from "@/constants/ga-tracking/components";
import { $language } from '@/apis';
import LangModal from './LangModal.vue';

export default {
  name: 'ChangeLangModal',
  components: {LangModal, IonSearchbar},
  emits: ['ready', 'lang-changed'],
  props: {
    countryCode: {
      type: String,
      default: ''
    },
    countryLang: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchLang: '',
      langSelected: $language.getSelectedLang().code,
      TRK
    }
  },
  methods: {
    openModal() {
      const {$refs: {langModal}} = this;
      langModal.openModal();
    },
    onClose(saved) {
      if(saved === true) {
        this.$emit('lang-changed', $language.getSelectedLang());
      }
    }
  },
}
</script>
