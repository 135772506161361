<template>
  <div>
    <ion-accordion-group :value="accordionGroupValue">
      <ion-accordion value="media">
        <ion-item slot="header" color="light">
          <ion-icon :icon="icons.filmOutline" class="mr-8" />
          <ion-label>{{ $t("tabs.media.name") }}</ion-label>
        </ion-item>

        <ion-list slot="content">
          <ion-item>
            <router-link
              :to="{ name: 'nauheSoazList', params: { type: 'nauhe' } }"
            >
              <ion-label>{{ $t("tabs.media.boxLabels.nauhe") }}</ion-label>
            </router-link>
          </ion-item>

          <ion-item>
            <router-link :to="{ name: 'majalisList' }">
              <ion-label>{{ $t("tabs.media.boxLabels.majalis") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link :to="{ name: 'duaHome' }">
              <ion-label>{{ $t("tabs.media.boxLabels.dua") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link :to="{ name: 'booksList' }">
              <ion-label>{{ $t("tabs.media.boxLabels.books") }}</ion-label>
            </router-link>
          </ion-item>
        </ion-list>
      </ion-accordion>

      <ion-accordion value="tools">
        <ion-item slot="header" color="light">
          <ion-icon :icon="icons.briefcaseOutline" class="mr-8" />
          <ion-label>{{ $t("tabs.tools.name") }}</ion-label>
        </ion-item>

        <ion-list slot="content">
          <ion-item>
            <router-link
              :to="{ name: 'dailyAlarms', params: { viewName: 'alarms' } }"
            >
              <ion-label>{{
                $t("tabs.tools.alarms.salatAlarms", 1)
              }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link
              :to="{
                name: 'dailyAlarms',
                params: { viewName: 'ramadan-sehri-wakeup-call' },
              }"
            >
              <ion-label>{{ $t("tabs.tools.alarms.sehriAlarms") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <!-- <router-link> -->
            <ion-label>{{ $t("tabs.tools.tasbeehCounter.title") }}</ion-label>
            <!-- </router-link> -->
          </ion-item>
          <ion-item>
            <router-link :to="{ name: 'qnaList' }">
              <ion-label>{{ $t("tabs.tools.qna.title") }}</ion-label>
            </router-link>
          </ion-item>
        </ion-list>
      </ion-accordion>

      <ion-accordion value="user">
        <ion-item slot="header" color="light">
          <ion-icon :icon="icons.personCircleOutline" class="mr-8" />
          <ion-label>{{ $t("tabs.user.name", 1) }}</ion-label>
        </ion-item>

        <ion-list v-if="isGuest()" slot="content">
          <ion-item>
            <router-link :to="{ name: 'authPage', params: { page: 'login' } }">
              <ion-label>{{ $t("sidemenu.left.accountLogin") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link :to="{ name: 'authPage', params: { page: 'signup' } }">
              <ion-label>{{ $t("sidemenu.left.createAccount") }}</ion-label>
            </router-link>
          </ion-item>
        </ion-list>
        <ion-list v-else slot="content">
          <ion-item>
            <router-link :to="{ name: 'userTab', params: { userId } }">
              <ion-label>{{ $t("tabs.user.about.title") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link :to="{ name: 'UserPostsPage', params: { userId } }">
              <ion-label>{{ $t("tabs.user.posts.title") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link
              :to="{ name: 'UserMarhumeenPage', params: { userId } }"
            >
              <ion-label>{{ $t("tabs.user.marhumeen.title") }}</ion-label>
            </router-link>
          </ion-item>
          <ion-item>
            <router-link :to="{ name: 'UserSettingsPage', params: { userId } }">
              <ion-label>{{ $t("common.settings") }}</ion-label>
            </router-link>
          </ion-item>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>
  </div>
</template>

<script>
import { IonAccordionGroup, IonAccordion, IonList } from "@ionic/vue";
import {
  homeOutline,
  filmOutline,
  briefcaseOutline,
  personCircleOutline,
} from "ionicons/icons";
import { getUser } from "@/composables";

export default {
  name: "mainmenu-homepage",
  components: { IonAccordionGroup, IonAccordion, IonList },
  computed: {
    accordionGroupValue() {
      const {
        $route: { name },
      } = this;
      if (name.match(/^user/i)) {
        return "user";
      }
      return name.replace(/Tab$/, "");
    },
  },
  setup() {
    const { isGuest, currentUser } = getUser();
    const { userId } = currentUser();
    return {
      isGuest,
      userId,
      icons: {
        homeOutline,
        filmOutline,
        briefcaseOutline,
        personCircleOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
ion-accordion {
  background: transparent;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &.accordion-expanded {
    > ion-item {
      --background: var(--ion-color-primary);
    }
  }

  ion-list {
    background: transparent;
    ion-item:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  ion-item {
    --border-radius: 50px;
    --border-width: 1px !important;
    a {
      text-decoration: none;
    }
  }
}
</style>
