export const STRIPE = Object.freeze({
  keys: {
    production:
      "pk_live_51NGoJxJXPyA0ZL0O6k7E2xG5tIJardhUIrF29GjmGKP4A0fy93Utwe0HI5LGT5XBusUYWBKTeJZdH6So9ZFkbYin00CMepeDy8",
    development:
      "pk_test_51NGoJxJXPyA0ZL0Ot7jpMnobfujyzdQfMpBiuAmRei2VIHIjn3d4swVNWBe8K564Mf2BQj7z3AHERHebPWUOBKUv00XESs85kK",
  },
  products: {
    buyCredits: {
      production: {
        usd: "price_1NAuP7DKFaqW3haxsLIVsBFm",
        eur: "price_1NAuP7DKFaqW3haxo6qOGOKt",
        inr: "price_1NAuP7DKFaqW3haxoUqj0Vje",
      },
      development: "price_1NAxjwDKFaqW3haxfpMSgLEP",
    },
  },
});
